import { useEffect, useRef } from "react";
import { Field, FieldRenderProps } from "react-final-form";

import {
  DetailsList,
  IDetailsListProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IObjectWithKey,
  IRenderFunction,
  ISelectionZoneProps,
  mergeStyleSets,
  SelectionMode
} from "@bps/fluent-ui";
import { ExposedFieldProps } from "@ui-components/form/FinalForm.tsx";
import {
  FormItemField,
  FormItemFieldProps
} from "@ui-components/form/FormItemField.tsx";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";

type HtmlElementType = HTMLElement;

export type DetailsListFieldProps = Pick<
  FormItemFieldProps,
  "label" | "required" | "onRenderLabel" | "styles"
> &
  ExposedFieldProps<IDetailsListProps["items"], HtmlElementType> &
  IDetailsListProps & {
    extraRowStyles?: Partial<IDetailsRowStyles>;
  } & Pick<ISelectionZoneProps, "disableAutoSelectOnInputElements"> & {
    fullRowSelection?: boolean;
    canSelectItem?:
      | ((item: IObjectWithKey, index?: number | undefined) => boolean)
      | undefined;
  };

type DetailsListAdapterProps = FieldRenderProps<
  IDetailsListProps["items"],
  HtmlElementType
> &
  DetailsListFieldProps;

const DetailsListAdapter: React.FC<DetailsListAdapterProps> = ({
  required,
  label,
  onRenderLabel,
  fieldItemStyles,
  input,
  selectionMode,
  name,
  extraRowStyles,
  disableAutoSelectOnInputElements,
  fullRowSelection,
  canSelectItem,
  ...detailsListProps
}) => {
  const selection = useRef(
    new Selection({
      canSelectItem,
      onSelectionChanged: async () => {
        const items = selection.current.getSelection();
        const keys = items.map(i => i.key);
        if (selectionMode === SelectionMode.single) {
          input.onChange(keys[0]);
        } else {
          input.onChange(keys);
        }
      }
    })
  );

  useEffect(() => {
    const key = input.value.toString();
    //only update when the key is updated outside of the component
    if (key && !selection.current.isKeySelected(key)) {
      if (selectionMode === SelectionMode.single) {
        selection.current.setAllSelected(false);
      }
      selection.current.setKeySelected(key, true, true);
    }
  }, [input.value, selectionMode]);

  const onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props || !defaultRender) {
      return null;
    }

    const styles = mergeStyleSets(
      { checkCell: { display: "flex", alignItems: "center" } },
      extraRowStyles
    );

    if (fullRowSelection) {
      return (
        <div
          data-selection-disabled={true}
          onClick={e => {
            e.preventDefault();

            //current item is not selected
            if (
              selection.current
                .getSelectedIndices()
                .every(x => x !== props.itemIndex)
            ) {
              //clear all selections
              if (selectionMode === SelectionMode.single) {
                selection.current
                  .getSelectedIndices()
                  .forEach(x =>
                    selection.current.setIndexSelected(x, false, true)
                  );
              }
              //select current item
              selection.current.setIndexSelected(props.itemIndex, true, true);
            }
            //clicking on the same item should deselect it.
            else {
              selection.current.setIndexSelected(props.itemIndex, false, true);
            }
          }}
        >
          {defaultRender({
            ...props,
            styles
          })}
        </div>
      );
    }

    return defaultRender({
      ...props,
      styles
    });
  };

  return (
    <FormItemField
      name={input.name}
      required={required}
      label={label}
      onRenderLabel={onRenderLabel}
      styles={fieldItemStyles}
    >
      <DetailsList
        {...detailsListProps}
        selection={selection.current}
        selectionMode={selectionMode}
        onRenderRow={detailsListProps.onRenderRow || onRenderRow}
        selectionZoneProps={{
          selection: selection.current,
          disableAutoSelectOnInputElements:
            disableAutoSelectOnInputElements ?? false
        }}
        onShouldVirtualize={() => false}
      />
    </FormItemField>
  );
};

export const DetailsListField: React.FC<DetailsListFieldProps> = props => (
  <Field {...props} component={DetailsListAdapter} />
);
