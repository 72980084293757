import { FormApi, getIn } from "final-form";
import arrayMutators from "final-form-arrays";
import { createElement } from "react";
import {
  FieldMetaState,
  FieldProps,
  FieldRenderProps,
  FormProps as FinalFormBaseProps,
  Form as FinalFormInner,
  FormRenderProps,
  FormSpy,
  RenderableProps
} from "react-final-form";

import {
  ILabelStyleProps,
  ILabelStyles,
  IStyleFunctionOrObject,
  metaToLabelColor
} from "@bps/fluent-ui";
import { isDefined } from "@bps/utils";
import { Location } from "@stores/router/RouterStore.ts";

import { customMutators } from "./customMutators.ts";
import { Prompt } from "./prompt/Prompt.tsx";

type Meta = Pick<
  FieldMetaState<any>,
  "error" | "submitError" | "modified" | "touched" | "submitFailed"
>;

/**
 * For each dirty field from the form state, if the field is missing from the values object, it gets
 * added with the undefined value.
 *
 * This is to workaround the fact that Final Form, on a field change, will remove from values any field whose
 * value is undefined.
 * The reason we want the field to still be present as undefined in the values object is so that applying values
 * as second argument to Object.assign(target, values) will effectively reset the fields of target to undefined
 * instead of ignoring them.
 *
 * @param form the final form API
 * @param values the submitted values
 */
const addMissingDirtyFields = <FormValues extends object>(
  values: FormValues,
  form: FormApi<FormValues>
) => {
  Object.keys(form.getState().dirtyFields)
    .filter(key => getIn(values, key) === undefined)
    .forEach(dirtyFieldKey => {
      let obj = values;
      let field = dirtyFieldKey;

      // the field keys from final form are paths from the values root object
      // we want to get access to the object that is missing the field
      const indexOfLastDot = dirtyFieldKey.indexOf(".");

      if (indexOfLastDot > -1) {
        obj = getIn(values, dirtyFieldKey.substr(0, indexOfLastDot));
        field = dirtyFieldKey.substr(indexOfLastDot + 1);
      }

      const value = getIn(obj, field);
      if (value === undefined && obj && !obj.hasOwnProperty(field)) {
        obj[field] = undefined;
      }
    });
};

/***
 * shared logic between components that use either render prop,
 * children render function, or component prop
 * Taken directly from React final form
 * https://github.com/final-form/react-final-form/blob/master/src/renderComponent.js
 */
export function renderComponent<T extends {}>(
  props: RenderableProps<T> & T,
  lazyProps: Partial<T>
): React.ReactNode {
  const { render, children, component, ...rest } = props;
  if (component) {
    return createElement(
      component,
      Object.assign(lazyProps, rest, {
        children,
        render
      }) as any
    );
  }
  if (render) {
    return render(
      children === undefined
        ? Object.assign(lazyProps, rest)
        : // inject children back in
          Object.assign(lazyProps, rest, { children } as any)
    );
  }
  if (typeof children !== "function") {
    throw new Error(
      "Must specify either a render prop, a render function as children, or a component prop."
    );
  }
  return children(Object.assign(lazyProps, rest) as any);
}

export const FORM_PROMPT_DEFAULT_MESSAGE = "Discard your changes?";

export const FORM_SUBMIT_PROMPT_DEFAULT_MESSAGE =
  "Are you sure you wish to submit these changes?";

export interface FinalFormProps<FormValues = object>
  extends Pick<
    FinalFormBaseProps<FormValues>,
    | "initialValues"
    | "subscription"
    | "onSubmit"
    | "validate"
    | "component"
    | "children"
    | "render"
    | "mutators"
    | "decorators"
    | "keepDirtyOnReinitialize"
    | "validateOnBlur"
  > {
  disableRoutePrompt?: boolean;
  extraPromptCondition?: (
    nextLocation: Location | undefined
  ) => boolean | string;
}

export const FinalForm = <FormValues extends object = object>(
  props: FinalFormProps<FormValues>
) => {
  const {
    component,
    render,
    children,
    disableRoutePrompt,
    extraPromptCondition,
    onSubmit: initialOnSubmit,
    ...formProps
  } = props;

  const promptPredicate = extraPromptCondition
    ? (nextLocation: Location) => {
        const result = extraPromptCondition(nextLocation);
        if (result === true) {
          return FORM_PROMPT_DEFAULT_MESSAGE;
        }
        return result;
      }
    : () => FORM_PROMPT_DEFAULT_MESSAGE;

  // add missing dirty fields in the values object before calling onSubmit
  const onSubmit = (
    values: FormValues,
    form: FormApi<FormValues>,
    callback?: (errors?: object) => void
  ) => {
    if (initialOnSubmit) {
      addMissingDirtyFields(values, form);
      return initialOnSubmit(values, form, callback);
    }
  };

  return (
    <FinalFormInner<FormValues>
      mutators={{ ...arrayMutators, ...customMutators }}
      {...formProps}
      onSubmit={onSubmit}
    >
      {(formRenderProps: FormRenderProps<FormValues>) => (
        <>
          {renderComponent(
            { children, render, component, ...formRenderProps },
            {
              form: {
                ...formRenderProps.form
              },
              handleSubmit: formRenderProps.handleSubmit
            }
          )}
          {!disableRoutePrompt && (
            <FormSpy
              subscription={{
                submitting: true,
                submitSucceeded: true,
                dirty: true,
                dirtySinceLastSubmit: true
              }}
            >
              {({ submitting, submitSucceeded, dirty }) => {
                const shouldPrompt = dirty && !submitSucceeded && !submitting;
                return (
                  shouldPrompt && <Prompt promptPredicate={promptPredicate} />
                );
              }}
            </FormSpy>
          )}
        </>
      )}
    </FinalFormInner>
  );
};

/**
 * Returns the error message given field meta.
 * Until a field is modified or form submission failed, no error will be returned.
 * @param meta - FieldMetaState. Note, it can be an array for merged fields.
 * @param validateOnInitialize - runs on-fly validation
 */
export function getFieldErrorMessage(
  meta: Meta | Meta[],
  validateOnInitialize?: boolean
): string | undefined {
  const getMessage = (meta: Meta) => {
    const error = meta.error || meta.submitError;
    return typeof error === "string" &&
      ((meta.modified && meta.touched) ||
        meta.submitFailed ||
        validateOnInitialize)
      ? error
      : undefined;
  };

  if (Array.isArray(meta)) {
    return meta.map(getMessage).filter(isDefined).join(", ");
  }
  return getMessage(meta);
}

/**
 * Function used to style input labels consistently based on field meta information
 * @param meta
 */
export const getLabelStyles =
  (
    meta: FieldMetaState<any>
  ): IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles> =>
  props => {
    return {
      root: {
        color: metaToLabelColor(
          {
            active: meta.active,
            hasErrorMessage: !!getFieldErrorMessage(meta)
          },
          props.theme
        )
      }
    };
  };

export function changeFormValues<FormValues>(
  form: FormApi<FormValues>,
  values: Partial<FormValues>
) {
  form.batch(() => {
    Object.keys(values).forEach(fieldName => {
      form.change(fieldName as keyof FormValues, values[fieldName]);
    });
  });
}

export interface ExposedFieldProps<FieldValue, T extends HTMLElement>
  extends Pick<
    FieldProps<FieldValue, FieldRenderProps<FieldValue, T>, T>,
    | "name"
    | "allowNull"
    | "format"
    | "formatOnBlur"
    | "parse"
    | "isEqual"
    | "subscription"
    | "validate"
    | "data"
  > {}
