import {
  DateTimeFramePickerStyles,
  DateTimeFramePickerStylesProps
} from "./DateTimeFramePicker.types.ts";

export const getDateTimeFrameStyles = ({
  theme
}: DateTimeFramePickerStylesProps): DateTimeFramePickerStyles => {
  return {
    field: {
      display: "flex",
      minHeight: 32,
      color: theme.palette.neutralSecondary,
      paddingLeft: 8,
      paddingRight: 8,
      alignItems: "center",
      textAlign: "center",
      cursor: "pointer",
      borderRadius: 2,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.semanticColors.inputBorder
    }
  };
};
