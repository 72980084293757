import { confirm } from "@bps/fluent-ui";
import { FORM_PROMPT_DEFAULT_MESSAGE } from "@ui-components/form/FinalForm.tsx";
import { defaultButtonsText } from "@ui-components/form/prompt/Prompt.tsx";

export const defaultConfirm = async (
  dirty: boolean,
  onConfirm: () => void,
  onClean: () => void
) => {
  if (dirty) {
    const isConfirmed = await confirm({
      dialogContentProps: {
        subText: FORM_PROMPT_DEFAULT_MESSAGE
      },
      confirmButtonProps: {
        text: defaultButtonsText.confirm
      },
      cancelButtonProps: {
        text: defaultButtonsText.cancel
      }
    });
    if (isConfirmed) {
      onConfirm();
    }
  } else {
    onClean();
  }
};
