import { FunctionComponent, useRef } from "react";
import { Field, FieldRenderProps } from "react-final-form";

import { FieldItemStyles, getId, IInputProps, useTheme } from "@bps/fluent-ui";
import { identity } from "@libs/utils/utils.ts";
import {
  ExposedFieldProps,
  getFieldErrorMessage
} from "@ui-components/form/FinalForm.tsx";
import {
  FormItemField,
  FormItemFieldProps
} from "@ui-components/form/FormItemField.tsx";
import { getNewPickerStyles } from "@ui-components/form/utils.ts";
import { UserPicker } from "@ui-components/pickers/user-picker/UserPicker.tsx";
import { UserPickerProps } from "@ui-components/pickers/user-picker/UserPicker.types.tsx";

type HtmlElementType = any;

export type UserPickerAdapterProps = Omit<
  UserPickerProps,
  "defaultSelectedItems"
> &
  Pick<
    FormItemFieldProps,
    "required" | "label" | "actionButton" | "onRenderLabel"
  > &
  Pick<IInputProps, "placeholder"> & {
    as?: React.ComponentType<UserPickerProps>;
    fieldItemStyle?: Partial<Pick<FieldItemStyles, "item" | "root">>;
  };

type InternalUserPickerAdapterProps = FieldRenderProps<
  UserPickerProps["selectedKey"],
  HtmlElementType
> &
  UserPickerAdapterProps;

export type UserPickerFieldProps = ExposedFieldProps<
  UserPickerProps["selectedKey"],
  HtmlElementType
> &
  UserPickerAdapterProps & {
    autoFocus?: boolean;
    currentUserOnTop?: boolean;
  };

const UserPickerAdapter: FunctionComponent<InternalUserPickerAdapterProps> = ({
  as,
  meta,
  input,
  label,
  styles,
  required,
  disabled,
  autoFocus,
  placeholder,
  actionButton,
  onRenderLabel,
  fieldItemStyle,
  currentUserOnTop,
  ...pickerProps
}) => {
  const theme = useTheme();
  const _id = useRef<string>(getId(input.name));

  const hasErrorMessage = !!getFieldErrorMessage(meta);

  const handleChange = (id: string | undefined) => {
    input.onChange(id);
    if (!id) {
      // input re-obtains the focus on change when removing an item but onFocus is not triggered
      // https://github.com/OfficeDev/@fluentui/react/blob/b982a68f449a61b16429d1a025cd8a949177d954/packages/@fluentui/react/src/components/pickers/BasePicker.tsx#L875
      input.onFocus();
    }
  };

  const UserPickerComponent = as || UserPicker;
  const _disabled = disabled || meta?.data?.disabled;
  return (
    <FormItemField
      name={input.name}
      required={required}
      label={label}
      disabled={_disabled}
      styles={fieldItemStyle}
      htmlFor={_id.current}
      actionButton={actionButton}
      onRenderLabel={onRenderLabel}
    >
      <UserPickerComponent
        {...pickerProps}
        onChange={handleChange}
        currentUserOnTop={currentUserOnTop}
        selectedKey={input.value}
        disabled={_disabled}
        inputProps={{
          onBlur: input.onBlur,
          onFocus: input.onFocus,
          autoFocus,
          placeholder,
          id: _id.current,
          name: input.name
        }}
        styles={getNewPickerStyles(
          theme,
          { hasErrorMessage, disabled: _disabled },
          styles
        )}
      />
    </FormItemField>
  );
};

/**
 * Use UserPickerField to use UserPicker with final-form, or one of its specialized
 * components such as PersonPickerField or PatientPickerField.
 * It also wraps the picker in a FormItemField to support label and error message.
 * @param props
 */
export const UserPickerField: React.FunctionComponent<
  UserPickerFieldProps
> = props => (
  <Field
    format={identity}
    parse={identity}
    {...props}
    component={UserPickerAdapter}
  />
);
