import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontWeights,
  IPersonaProps,
  IPersonaStyleProps,
  IPersonaStyles,
  IStyleFunction,
  ITheme,
  mergeStyles,
  mergeStyleSets,
  PeoplePickerItemSuggestion,
  useTheme
} from "@bps/fluent-ui";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactStatus } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { User } from "@stores/core/models/User.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { getContactPickerItemStyles } from "./ContactPicker.styles.ts";

const getTagInUseStyles = (theme: ITheme) => ({
  taggedItem: {
    backgroundColor: theme.semanticColors.bodyBackground,
    selectors: {
      "&:hover": {
        backgroundColor: theme.semanticColors.bodyBackground,
        cursor: "default"
      }
    }
  },
  inUseItem: {
    textAlign: "end"
  },
  details: {
    flexDirection: "column",
    justifyContent: "space-between",
    fontStyle: "italic"
  },
  primaryText: {
    paddingRight: 1,
    color: theme.semanticColors.buttonTextDisabled,
    selectors: {
      ":hover": {
        color: theme.semanticColors.buttonTextDisabled
      }
    }
  },
  secondaryText: {
    overflow: "inherit",
    width: "100%",
    paddingRight: 1,
    display: "inline"
  },
  root: {
    height: 24
  }
});

interface ContactPickerSuggestionItemProps {
  personaProps?: IPersonaProps;
  contact: Contact | User;
  isSelected?: boolean;
  isTagged?: boolean;
}

export const ContactPickerSuggestionItem: FunctionComponent<
  ContactPickerSuggestionItemProps
> = ({ personaProps, contact, isSelected, isTagged }) => {
  const theme = useTheme();
  const { taggedItem, ...personaStyles } = getTagInUseStyles(theme);

  const personaStylesOverrides: IStyleFunction<
    IPersonaStyleProps,
    IPersonaStyles
  > = styleProps => {
    const processedPersonaStyles =
      personaProps?.styles && typeof personaProps.styles === "function"
        ? personaProps.styles(styleProps)
        : personaProps?.styles;
    return mergeStyleSets(
      {
        primaryText: isSelected && {
          fontWeight: FontWeights.semibold
        }
      },
      processedPersonaStyles,
      isTagged ? personaStyles : undefined
    );
  };

  const getBadgeClass = () => {
    const { inactive, deceased, pending } = getContactPickerItemStyles(theme);
    // render inactive badge if a contact has inactive status
    if (contact.status === ContactStatus.Inactive) {
      return mergeStyles("inactive-item", inactive);
    } else if (contact.status === ContactStatus.Deceased) {
      return mergeStyles("deceased-item", deceased);
      // render pending badge if a user has pending status
    } else if (contact.status === UserStatus.Pending) {
      return mergeStyles("pending-item", pending);
    }
    return undefined;
  };

  return (
    <div {...dataAttribute(DataAttributes.Data, contact.id)}>
      <PeoplePickerItemSuggestion
        suggestionsProps={{
          suggestionsItemClassName: isTagged
            ? mergeStyles("tagged-item", taggedItem)
            : undefined
        }}
        className={getBadgeClass()}
        styles={({ theme }) => ({
          root: isSelected && {
            width: "100%",
            backgroundColor: theme.semanticColors.menuItemBackgroundChecked
          }
        })}
        personaProps={{
          ...personaProps,
          styles: personaStylesOverrides
        }}
        compact={!isTagged}
      />
    </div>
  );
};
