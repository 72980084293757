import { FunctionComponent, useRef } from "react";
import { Field, FieldRenderProps, useField } from "react-final-form";

import { getId, useTheme } from "@bps/fluent-ui";
import { identity } from "@libs/utils/utils.ts";
import { User } from "@stores/core/models/User.ts";
import {
  ExposedFieldProps,
  getFieldErrorMessage
} from "@ui-components/form/FinalForm.tsx";
import {
  FormItemField,
  FormItemFieldProps
} from "@ui-components/form/FormItemField.tsx";
import { getNewPickerStyles } from "@ui-components/form/utils.ts";
import {
  BookableUserPicker,
  BookableUserPickerProps
} from "@ui-components/pickers/user-picker/BookableUserPicker.tsx";

type HtmlElementType = any;

type ProviderPickerAdapterProps = Omit<
  BookableUserPickerProps,
  "defaultSelectedItems"
> &
  Pick<FormItemFieldProps, "required" | "label" | "onRenderLabel"> & {
    as?: React.ComponentType<BookableUserPickerProps>;
  };

type InternalProviderPickerAdapterProps = FieldRenderProps<
  BookableUserPickerProps["selectedKey"],
  HtmlElementType
> &
  ProviderPickerAdapterProps;

type ProviderPickerFieldProps = ExposedFieldProps<
  BookableUserPickerProps["selectedKey"],
  HtmlElementType
> &
  ProviderPickerAdapterProps;

const ProviderPickerAdapter: FunctionComponent<
  InternalProviderPickerAdapterProps
> = ({
  as,
  meta,
  input,
  label,
  styles,
  disabled,
  required,
  inputProps,
  onRenderLabel,
  ...pickerProps
}) => {
  const theme = useTheme();

  const id = useRef(getId(input.name));

  const hasErrorMessage = !!getFieldErrorMessage(meta);

  const handleChange = (id: string | undefined, item: User | undefined) => {
    input.onChange(id);
    if (!id) {
      // input re-obtains the focus on change when removing an item but onFocus is not triggered
      // https://github.com/OfficeDev/@fluentui/react/blob/b982a68f449a61b16429d1a025cd8a949177d954/packages/@fluentui/react/src/components/pickers/BasePicker.tsx#L875
      input.onFocus();
    }

    pickerProps.onChange && pickerProps.onChange(id, item);
  };

  const ProviderPickerComponent = as || BookableUserPicker;

  const { meta: formMeta } = useField(input.name);

  const _disabled = disabled || formMeta?.data?.disabled;

  return (
    <FormItemField
      name={input.name}
      required={required}
      label={label}
      onRenderLabel={onRenderLabel}
      htmlFor={id.current}
      disabled={_disabled}
    >
      <ProviderPickerComponent
        {...pickerProps}
        onChange={handleChange}
        selectedKey={input.value}
        disabled={_disabled}
        inputProps={{
          ...inputProps,
          onBlur: input.onBlur,
          onFocus: input.onFocus,
          id: id.current,
          name: input.name
        }}
        styles={getNewPickerStyles(
          theme,
          { hasErrorMessage, disabled: _disabled },
          styles
        )}
      />
    </FormItemField>
  );
};

/**
 * Use BookableUserPickerField to use BookableUserPicker with final-form, or one of its specialized
 * components such as PersonPickerField or PatientPickerField.
 * It also wraps the picker in a FormItemField to support label and error message.
 * @param props
 */
export const BookableUserPickerField: React.FC<
  ProviderPickerFieldProps
> = props => (
  <Field
    format={identity}
    parse={identity}
    {...props}
    component={ProviderPickerAdapter}
  />
);
