import { FieldMetaState } from "react-final-form";

import {
  GenericSelectStyles,
  getPrimaryFocusStyles,
  ITheme
} from "@bps/fluent-ui";

import { getFieldErrorMessage } from "../FinalForm.tsx";

export const getFieldBorderColors = (
  meta: FieldMetaState<string | string[] | undefined>,
  theme: ITheme
): Partial<GenericSelectStyles> => {
  const hasError = getFieldErrorMessage(meta);
  return {
    field: {
      "::after": hasError
        ? getPrimaryFocusStyles(theme, true, !!meta.active)
        : undefined
    }
  };
};
