/* eslint-disable no-restricted-imports */
import {
  UseFieldArrayConfig,
  useFieldArray as useFinalFormFieldArray
} from "react-final-form-arrays";

import { createArrayEqual } from "../../FieldArray.tsx";

/**
 * Wraps the useFieldArray hook from react-final-form to use a default JSON.stringify
 * equality function. The individual element equality function can still be overridden by passing in
 * the Equal prop.
 * @param name
 * @param config
 */
export const useFieldArray = <FieldValue extends any = any>(
  name: string,
  config: UseFieldArrayConfig<FieldValue> = {}
) => {
  const { isEqual, ...restConfig } = config;
  const configWithIsEqual = {
    isEqual: createArrayEqual(isEqual),
    ...restConfig
  };
  return useFinalFormFieldArray(name, configWithIsEqual);
};
