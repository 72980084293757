import { Observer } from "mobx-react-lite";
import { FunctionComponent, useMemo } from "react";

import {
  DtoDetailsRow,
  IDetailsRowProps,
  IDetailsRowStyleProps,
  IDetailsRowStyles,
  IStyleFunctionOrObject,
  mergeStyleSets,
  Overlay
} from "@bps/fluent-ui";
import { maybePromiseObservable } from "@libs/utils/promise-observable/promise-observable.utils.ts";

export interface DetailsRowHelper {
  setPromise: (promise: PromiseLike<any>) => void;
  isPending: boolean;
  error: Error | undefined;
}

/**
 * DetailsRowWrapper is a helper component for DetailsList and ShimmeredDetailList. It allows us to pass a helper object for each row/column.
 * As a helper we use maybePromiseObservable return properties (set, error, and isPending).
 * Columns controls may use these properties for rows updating  details and handling pending, error, and fulfilled states in UI.
 * By default, Overlay will be rendered in a row what will stop a user to be interacted with the row element until the promise us being resolved.
 * @param props - IDetailsRowProps | undefined
 * @example
 *  const columns: IColumn = [
 *    {
 *      key: "1",
 *      name: "Name",
 *      onRender: (item: {data: Dto, helper: DetailsRowHelper}) => {
 *        return (<Toggle value={value} onClick={() => item.helper.set(promise)} />)
 *      }
 *    }
 *  ];
 *
 *  return (
 *    <ShimmeredDetailsList
 *      items={items}
 *      onRenderRow={props => <DetailsRowWrapper props={props} />}
 *      columns={columns}
 *    />
 *   )
 */

export const DetailsRowWrapper: FunctionComponent<{
  props: IDetailsRowProps | undefined;
  detailsRowStyles?: IStyleFunctionOrObject<
    IDetailsRowStyleProps,
    IDetailsRowStyles
  >;
}> = ({ props, detailsRowStyles }) => {
  const promise = useMemo(() => maybePromiseObservable(), []);

  if (!props) return null;
  return (
    <div style={{ position: "relative" }}>
      <Observer>
        {() => (
          <Overlay
            hidden={!promise.pending}
            styles={{ root: { zIndex: 200 } }}
          />
        )}
      </Observer>
      <DtoDetailsRow
        {...props}
        styles={mergeStyleSets(
          {
            fields: {
              zIndex: 0
            }
          },
          detailsRowStyles && detailsRowStyles
        )}
        item={{
          id: props.item.id,
          data: props.item,
          helper: {
            setPromise: promise.set,
            isPending: promise.pending,
            error: promise.error
          } as DetailsRowHelper
        }}
      />
    </div>
  );
};
