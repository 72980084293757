import { useEffect, useRef, useState } from "react";
import {
  DropEvent,
  DropzoneOptions,
  ErrorCode,
  FileError,
  FileRejection,
  useDropzone
} from "react-dropzone";

import {
  classNamesFunction,
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  FontWeights,
  Heading,
  IProcessedStyleSet,
  Link,
  mergeStyles,
  PrimaryButton,
  Spinner,
  Stack,
  styled,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { formatBytesToMB } from "@ui-components/form/FileUploaderField/utils.ts";

import { getStyles } from "./dropzoneSection.styles.ts";
import {
  DropzoneSectionProps,
  DropzoneSectionStyleProps,
  DropzoneSectionStyles
} from "./DropzoneSection.types.ts";

export const DropzoneSectionBase: React.FC<DropzoneSectionProps> = ({
  isButton,
  isMultiple,
  isLink,
  linkText,
  onDropAccepted,
  onDropRejected,
  description = "Drag and drop here",
  maxFileText = "No more files can be attached",
  fileTooLargeText,
  incorrectFileTypeText,
  hasError,
  className,
  styles,
  showSpinner,
  customFileRejections,
  ...dropzoneOptions
}) => {
  const isMounted = useRef(true);
  const theme = useTheme();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDropAccepted: DropzoneOptions["onDropAccepted"] = async (
    files: File[],
    event: DropEvent
  ) => {
    if (onDropAccepted) {
      showSpinner && setSpinner(true);
      try {
        await onDropAccepted(files, event);
      } catch {
        setSpinner(false);
      }
      if (showSpinner && isMounted.current) {
        setSpinner(false);
      }
    }
  };

  const { getRootProps, getInputProps, open, isDragAccept, fileRejections } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      //the below can be overridden
      multiple: !!isMultiple,
      onDropRejected,
      ...dropzoneOptions,
      onDropAccepted: handleDropAccepted
    });

  const [spinner, setSpinner] = useState<boolean>(false);

  if (isButton) {
    return (
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <PrimaryButton id="inbox-upload-button" onClick={open}>
          Upload
        </PrimaryButton>
      </div>
    );
  }

  if (isLink) {
    return (
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <Link id="inbox-upload-link" onClick={open}>
          {linkText ?? "Upload"}
        </Link>
      </div>
    );
  }

  if (spinner) {
    return <Spinner />;
  }

  const getClassNames = classNamesFunction<
    DropzoneSectionStyleProps,
    DropzoneSectionStyles
  >();

  const getMaxFilesReachedJSX = (
    classNames: IProcessedStyleSet<DropzoneSectionStyles>
  ) => (
    <Stack tokens={{ childrenGap: 8 }} className={classNames.maxReached}>
      <Stack horizontal verticalAlign="center">
        <FontIcon
          iconName="blocked"
          className={mergeStyles({
            fontWeight: FontWeights.bold,
            fontSize: FontSizes.size18,
            marginRight: 8
          })}
        />
        Maximum reached
      </Stack>
      <Text>{maxFileText}</Text>
    </Stack>
  );

  const getErrorText = (error: FileError, file: FileRejection) => {
    switch (error.code) {
      case ErrorCode.FileTooLarge:
        return fileTooLargeText
          ? ` ${fileTooLargeText} (${formatBytesToMB(file.file.size)})`
          : error.message;
      case ErrorCode.FileInvalidType:
        return incorrectFileTypeText
          ? ` ${incorrectFileTypeText}`
          : error.message;
      default:
        return error.message;
    }
  };

  const getDefaultContentJSX = () => (
    <>
      <Text>{description}</Text>
      <Text>or</Text>
      <Link>Browse files</Link>
    </>
  );

  const getErrorContentJSX = (fileRejections: FileRejection[]) => {
    const tooManyFilesUploaded = fileRejections.find(rejectedFile =>
      rejectedFile.errors.find(e => e.code === ErrorCode.TooManyFiles)
    );
    return (
      <Stack tokens={{ childrenGap: 8 }} horizontalAlign="center">
        <Heading styles={{ root: { color: theme?.semanticColors.errorText } }}>
          <Stack horizontal verticalAlign="center">
            <FontIcon
              iconName="blocked"
              className={mergeStyles({
                fontWeight: FontWeights.bold,
                fontSize: FontSizes.size18,
                marginRight: 8
              })}
            />
            Cannot upload
          </Stack>
        </Heading>
        {fileRejections && (
          <>
            {!tooManyFilesUploaded
              ? fileRejections.map((file, index) => (
                  <Text key={file.file.name}>
                    {`${file.file.name} ${file.errors
                      .map(error => getErrorText(error, file))
                      .join(" and")}`}
                    {fileRejections.length > index + 1 && " and"}
                  </Text>
                ))
              : renderTooManyFilesError(tooManyFilesUploaded.errors[0].message)}
          </>
        )}
        <Link>Drag & drop or upload another file</Link>
      </Stack>
    );
  };

  const renderTooManyFilesError = (errorMessage: string) => {
    const fileNames = fileRejections.map(
      rejectedFile => rejectedFile.file.name
    );

    return (
      <>
        <Text>{fileNames.join(", ")}</Text>
        <Text>{errorMessage}</Text>
      </>
    );
  };

  const classNames = getClassNames(styles, {
    theme: theme!,
    className,
    hasErrorMessage: hasError,
    isDragAccept,
    fileRejections
  });

  return (
    <div
      className={classNames.root}
      {...dataAttribute(DataAttributes.Element, "dropzone-wrapper")}
    >
      {dropzoneOptions.maxFiles === 0 ? (
        getMaxFilesReachedJSX(classNames)
      ) : (
        <Stack
          {...getRootProps({
            refKey: "innerRef",
            tokens: { childrenGap: 2 },
            className: classNames.dropzone,
            role: "button"
          })}
          onClick={open}
        >
          <input {...getInputProps()} />
          {!!fileRejections.length || !!customFileRejections?.length
            ? getErrorContentJSX(customFileRejections ?? fileRejections)
            : getDefaultContentJSX()}
        </Stack>
      )}
    </div>
  );
};

export const DropzoneSection: React.FC<DropzoneSectionProps> = styled<
  DropzoneSectionProps,
  DropzoneSectionStyleProps,
  DropzoneSectionStyles
>(DropzoneSectionBase, getStyles);
