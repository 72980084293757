import React from "react";

import {
  dataAttribute,
  DataAttributes,
  TextBadge,
  TextBadgeColor
} from "@bps/fluent-ui";

export interface FormStatusIndicatorBadgeProps {
  valid: boolean;
  completedLabel?: string;
  incompletedLabel?: string;
}

export const FormStatusIndicatorBadge: React.FC<
  FormStatusIndicatorBadgeProps
> = ({ valid, completedLabel, incompletedLabel }) => {
  return (
    <TextBadge
      {...dataAttribute(DataAttributes.Element, "status-button")}
      badgeColor={valid ? TextBadgeColor.green : TextBadgeColor.yellow}
    >
      {valid ? completedLabel || "Ready" : incompletedLabel || "Incomplete"}
    </TextBadge>
  );
};
