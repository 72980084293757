import { useRef } from "react";

/*A hook that stops the helper from being recreated each render and avoids calling the constructor each render
 *https://react.dev/reference/react/useRef#avoiding-recreating-the-ref-contents
 */
export const usePersistedHelper = <T extends any>(createHelper: () => T) => {
  const helper = useRef<T | null>(null);

  if (helper.current !== null) {
    return helper.current;
  }

  const helperCurrent = createHelper();
  helper.current = helperCurrent;
  return helperCurrent;
};
