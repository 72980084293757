import { useCallback, useEffect, useRef } from "react";

import { Jump } from "@bps/fluent-ui";

export const useScrollToViewById = (
  scrollToViewOption?: ScrollOptions,
  interval?: number
) => {
  const timeout = useRef<any>();

  const isScrollingSupported =
    !!document && "scrollBehavior" in document.documentElement.style;

  useEffect(() => {
    return () => {
      clearInterval(timeout.current);
    };
  }, []);

  const behavior = scrollToViewOption?.behavior;

  return useCallback(
    (id: string | undefined | null) => {
      if (isScrollingSupported && id) {
        if (timeout.current) {
          clearInterval(timeout.current);
        }

        timeout.current = setInterval(() => {
          const el = document.getElementById(id);
          if (el) {
            el.scrollIntoView({ behavior } ?? Jump);
            clearInterval(timeout.current);
          }
        }, interval ?? 500);
      }
    },
    [behavior, interval, isScrollingSupported]
  );
};
