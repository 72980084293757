import React, { ReactNode, useContext } from "react";

import { IStackStyles, mergeStyleSets, Stack } from "@bps/fluent-ui";

import { PrintContext } from "./Print.tsx";

interface PrintContentWrapperProps {
  children: ReactNode;
  hidePrintContent?: boolean;
  contentStyle?: IStackStyles;
}

export const PrintContentWrapper: React.FC<PrintContentWrapperProps> = ({
  children,
  hidePrintContent = true,
  contentStyle
}) => {
  const print = useContext(PrintContext);

  return !!hidePrintContent ? (
    <div style={{ display: "none" }}>
      <div ref={print.ref}>{children}</div>
    </div>
  ) : (
    <Stack
      styles={mergeStyleSets(
        { root: { margin: "0 auto", height: "inherit" } },
        contentStyle
      )}
    >
      <div ref={print.ref}>{children}</div>
    </Stack>
  );
};
