import { DateTime } from "@bps/utils";

const getDefaultDateString = (date: Date) =>
  DateTime.fromJSDate(date).toDayDefaultFormat();

export const getDateRangeText = (
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  if (startDate && endDate) {
    const _startDate = getDefaultDateString(startDate);
    const _endDate = getDefaultDateString(endDate);

    return _startDate !== _endDate ? `${_startDate} - ${_endDate}` : _startDate;
  }
  return "";
};
