import { FC } from "react";

import {
  DefaultButton,
  IModalProps,
  mergeStyleSets,
  Modal,
  Stack
} from "@bps/fluent-ui";

export interface IFrameDialogProps extends IModalProps {
  onClose: () => void;
  url: string;
  title: React.ReactElement;
  minWidth?: string | number;
  maxWidth?: string | number;
}
export const IFrameDialog: FC<IFrameDialogProps> = ({
  url,
  onClose,
  title,
  styles: suppliedStyles,
  maxWidth,
  minWidth,
  ...rest
}) => {
  const BottomButtonPanel = () => {
    return (
      <Stack horizontal horizontalAlign="end">
        <DefaultButton onClick={onClose}>Close</DefaultButton>
      </Stack>
    );
  };

  const styles = mergeStyleSets({ root: { height: "100%" } }, suppliedStyles);
  return (
    <Modal
      onDismiss={onClose}
      styles={{ ...styles, main: { padding: "24px", maxWidth, minWidth } }}
      isOpen={true}
      {...rest}
    >
      {title}
      <iframe title="IFRAME" src={url} />
      <BottomButtonPanel />
    </Modal>
  );
};
