import { encode } from "base64-arraybuffer";
import "pdfjs-dist/build/pdf.worker.entry.js";
import * as pdfjsLib from "pdfjs-dist/es5/build/pdf.js";
// eslint-disable-next-line import/extensions
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist/types/display/api";

interface GetDocumentPagesOptions {
  canvasId: string;
  canvasTextLayerId: string;
  pdf: PDFDocumentProxy;
  scale?: number;
  pageNumber?: number;
}

export const getDocumentPdfPage = async ({
  pageNumber = 1,
  scale = 100,
  canvasTextLayerId,
  canvasId,
  pdf
}: GetDocumentPagesOptions): Promise<void> => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = (window as any).pdfjsWorker;

  const page: PDFPageProxy = await pdf.getPage(pageNumber);

  const viewport = page.getViewport({ scale: scale / 100 });
  const { width, height } = viewport;
  const canvas = document.getElementById(canvasId) as HTMLCanvasElement;

  if (!canvas) return;
  canvas.width = width;
  canvas.height = height;

  const canvasContext = canvas?.getContext("2d") as CanvasRenderingContext2D;

  await page.render({
    canvasContext,
    viewport
  }).promise;

  const textLayer = document.getElementById(canvasTextLayerId);
  if (!textLayer) return;
  // remove all children if scale has been changed
  textLayer.innerHTML = "";
  // set textLayer styles
  textLayer.style.width = `${width}px`;
  textLayer.style.height = `${height}px`;
  textLayer.style.left = `${canvas?.offsetLeft}px`;
  textLayer.style.top = `${canvas?.offsetTop}px`;

  const textContent = await page.getTextContent();
  // Render text layer over canvas. It allows a user to select and copy pdf text.
  pdfjsLib.renderTextLayer({
    textContent,
    container: textLayer,
    viewport,
    textDivs: []
  });
};

export const getDocumentPdfPages = async (
  url: string | Uint8Array
): Promise<PDFDocumentProxy> => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = (window as any).pdfjsWorker;

  const loadingTask = pdfjsLib.getDocument(url);
  return await loadingTask.promise;
};

export const pdfUrlToString = (url: string | Uint8Array) =>
  typeof url === "string" ? url : encode(url);

export const mapPdfPages = (pdf: PDFDocumentProxy) =>
  Array(pdf.numPages).fill(null);

export const getCanvasKey = (pdf: PDFDocumentProxy, idx: number) =>
  `${pdf._pdfInfo.fingerprint}-${idx.toString()}`;
