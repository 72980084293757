import {
  ButtonsGroupSingleChoiceField,
  ButtonsGroupSingleChoiceFieldProps
} from "./ButtonsGroupSingleChoiceField.tsx";

type YesNoButtonGroupFieldProps = Omit<
  ButtonsGroupSingleChoiceFieldProps<boolean>,
  "options"
> &
  PartialBy<ButtonsGroupSingleChoiceFieldProps<boolean>, "options">;

/***
 * Use BoolButtonGroupField displaying a list of options that map to a boolean field.
 * By default, it will render Yes / No buttons but this can be overriden with the options prop.
 */
export const BoolButtonGroupField = (props: YesNoButtonGroupFieldProps) => (
  <ButtonsGroupSingleChoiceField<boolean>
    options={[
      { key: true, text: "Yes" },
      { key: false, text: "No" }
    ]}
    {...props}
    format={Boolean}
    parse={Boolean}
  />
);
