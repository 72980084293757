import { FieldSpy } from "./FieldSpy.tsx";

type FieldsSpyProps<T> = {
  onChange: (fieldName: keyof T, value: T, values: T | undefined) => void;
  fieldNames: (keyof T)[];
};

export const FieldsSpy = <T extends object>({
  fieldNames,
  onChange
}: FieldsSpyProps<T>) => {
  return (
    <>
      {fieldNames.map(fieldName => (
        <FieldSpy
          key={fieldName as string}
          name={fieldName}
          onChange={(value, values) => {
            onChange(fieldName, value, values);
          }}
        />
      ))}
    </>
  );
};
