import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import {
  DocumentEditor,
  DocumentEditorKeyDownEventArgs
} from "@syncfusion/ej2-react-documenteditor";

import { EditorAutofillBase } from "./editor-autofill-base.ts";
import { AutofillContext, IEditorAutofill } from "./editor-autofill.types.ts";

export class SyncfusionEditorAutofill
  extends EditorAutofillBase<DocumentEditor>
  implements IEditorAutofill
{
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    root: IRootStore,
    private autofillContext: AutofillContext
  ) {
    super(root);
  }

  private readonly spaceChar = "Space";

  private getWordInfosBeforeCursorIndex(
    searchWord: string,
    cursorIndex: string
  ) {
    const hierarchicalIndexArray = cursorIndex.split(";");

    const rowIndex = hierarchicalIndexArray[2] ?? 0;
    const lineNumber = hierarchicalIndexArray[1] ?? 0;

    const startSelectIndex = Number(rowIndex) - searchWord.length;

    this.documentEditor.selection.selectByHierarchicalIndex(
      `0;${lineNumber};${startSelectIndex}`,
      cursorIndex
    );

    const selectedWord = this.documentEditor.selection.text;
    //RESET the cursor's selection
    this.documentEditor.selection.selectByHierarchicalIndex(
      cursorIndex,
      cursorIndex
    );

    return {
      selectedWord,
      lineNumber,
      wordStartIndex: startSelectIndex
    };
  }

  private checkWordBeforeCursorWithAutofills() {
    const autofills = this.root.document.autofills;
    for (let i = 0; i < autofills.length; i++) {
      const autofill = autofills[i];
      const currentCursorIndex = this.documentEditor.selection.startOffset;

      const searchWord = autofill.shortcut;
      const selectedWordProps = this.getWordInfosBeforeCursorIndex(
        searchWord,
        currentCursorIndex
      );

      const { selectedWord, lineNumber, wordStartIndex } = selectedWordProps;

      if (selectedWord === searchWord) {
        return {
          autofillId: autofill.id,
          lineNumber,
          wordStartIndex,
          currentCursorIndex
        };
      }
    }
    return;
  }

  public replace() {
    if (this.documentEditor) {
      this.documentEditor.keyDown = async (
        args: DocumentEditorKeyDownEventArgs
      ) => {
        if (args.event.code === this.spaceChar) {
          const foundMatchedAutofill =
            this.checkWordBeforeCursorWithAutofills();
          if (foundMatchedAutofill) {
            const {
              autofillId,
              lineNumber,
              wordStartIndex,
              currentCursorIndex
            } = foundMatchedAutofill;

            const text = await this.root.document.renderTemplate(autofillId, {
              context: this.autofillContext
            });
            //RE-SELECT area to input shortcut's content
            this.documentEditor.selection.selectByHierarchicalIndex(
              `0;${lineNumber};${wordStartIndex}`,
              currentCursorIndex
            );

            this.documentEditor.editor.paste(text.content);
          }
        }
      };
    }
  }

  public jumpLinksHandler = () => {
    if (this.documentEditor) {
      this.documentEditor.keyDown = (args: DocumentEditorKeyDownEventArgs) => {
        const keyCode: number = args.event.which || args.event.keyCode;
        const isShiftKey: boolean = args.event.shiftKey || args.event.metaKey;
        const isCtrlKey: boolean = args.event.ctrlKey;

        if (isShiftKey && isCtrlKey && keyCode === this.f6KeyCode) {
          args.event.preventDefault();
          args.isHandled = true;
          this.documentEditor.searchModule.findAll(this.jumpLinkCharacter);
        }
      };
    }
  };
}
