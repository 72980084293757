import { observer } from "mobx-react-lite";
import { useRef } from "react";

import {
  DefaultButton,
  IContextualMenuItem,
  IContextualMenuProps,
  MessageBar,
  MessageBarType,
  Spinner,
  SplitButton,
  SplitButtonProps,
  Stack
} from "@bps/fluent-ui";
import { maybePromiseObservable } from "@libs/utils/promise-observable/promise-observable.utils.ts";

type PromiseObservableMenuItemOnClick =
  | IContextualMenuItem["onClick"]
  | ((
      ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
      item?: IContextualMenuItem
    ) => Promise<void>);

export interface PromiseObservableContextualMenuItem
  extends Omit<IContextualMenuItem, "onClick"> {
  onClick: PromiseObservableMenuItemOnClick;
}

export interface PromiseObservableButtonProps
  extends Omit<SplitButtonProps, "items"> {
  items?: PromiseObservableContextualMenuItem[];
}

/**
 * A button that will display a spinner and error message when an async action is clicked
 */

export const PromiseObservableButton: React.FC<PromiseObservableButtonProps> =
  observer(
    ({ onClick, items, disabled, disableContextMenuButton, ...props }) => {
      const { current: promise } = useRef(maybePromiseObservable());

      const getMenuItems = (): IContextualMenuProps["items"] => {
        return items
          ? items.map(menuItem => ({
              ...menuItem,
              key: menuItem.key,
              onClick: (e, item) =>
                menuItem.onClick
                  ? promise.set(Promise.resolve(menuItem.onClick(e, item)))
                  : undefined
            }))
          : [];
      };

      return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          {promise.pending && <Spinner />}
          {promise.error && (
            <MessageBar
              styles={{ root: { width: "fit-content" } }}
              messageBarType={MessageBarType.error}
              children={promise.error.message}
            />
          )}
          {items ? (
            <SplitButton
              {...props}
              items={getMenuItems()}
              disabled={disabled || promise.pending}
              disableContextMenuButton={
                disableContextMenuButton || promise.pending
              }
              onClick={e => {
                onClick && promise.set(Promise.resolve(onClick(e)));
              }}
            />
          ) : (
            <DefaultButton
              {...props}
              disabled={disabled || promise.pending}
              onClick={e => {
                onClick && promise.set(Promise.resolve(onClick(e)));
              }}
            />
          )}
        </Stack>
      );
    }
  );
