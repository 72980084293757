import { FunctionComponent } from "react";

import {
  FontIcon,
  GenericSelectStyles,
  getTheme,
  mergeFuncStyles,
  Option,
  OptionsSelect,
  OptionsSelectProps,
  Stack
} from "@bps/fluent-ui";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { LocationOption } from "@ui-components/selects/LocationOption.tsx";

export interface LocationsSelectProps
  extends Omit<OptionsSelectProps, "options"> {
  onFilter?: (options: Option<PracOrgUnit>[]) => Option<PracOrgUnit>[];
  showIcon?: boolean;
  showInactiveLocation?: boolean;
  disableInactiveLocation?: boolean;
  showDefaultLocationBadge?: boolean;
}
export const LocationsSelect: FunctionComponent<LocationsSelectProps> = ({
  onFilter,
  placeholder,
  showIcon,
  showInactiveLocation,
  disableInactiveLocation,
  showDefaultLocationBadge,
  styles,
  ...rest
}) => {
  const theme = getTheme();
  const {
    core: { getLocationName, hasInactiveFlag }
  } = useStores();

  const getLocations = async (root: IRootStore) => {
    return await root.practice.getAllOrgUnitsLocationData();
  };

  const sortLocationsByInactiveFlagAndName = (
    a: { isInactive: boolean | undefined; text: string },
    b: { isInactive: boolean | undefined; text: string }
  ) => {
    if (a.isInactive && !b.isInactive) return 1;
    if (!a.isInactive && b.isInactive) return -1;
    return a.text.localeCompare(b.text);
  };

  const getOptions = (locations: PracOrgUnit[]): Option<PracOrgUnit>[] => {
    const options = locations
      .map(location => ({
        key: location.id,
        text: getLocationName(location.id) ?? "",
        data: location,
        isInactive: hasInactiveFlag(location.id),
        disabled: disableInactiveLocation && hasInactiveFlag(location.id)
      }))
      .sort(sortLocationsByInactiveFlagAndName)
      .filter(location => {
        if (!showInactiveLocation) {
          return !location.isInactive;
        }
        return true;
      });

    return onFilter ? onFilter(options) : options;
  };

  const onRenderFieldContentSingle = (options: Option<PracOrgUnit>[]) => {
    if (options.length > 0) {
      const selectedConditions = options.map(opt => onRenderOption(opt));
      return (
        <Stack
          wrap
          horizontal
          tokens={{
            childrenGap: 10
          }}
        >
          {selectedConditions}
        </Stack>
      );
    } else {
      return placeholder;
    }
  };

  const onRenderFieldContentMultiple = (options: Option<PracOrgUnit>[]) => {
    if (options.length > 0) {
      const label =
        options.length === 1
          ? options[0].data?.orgUnitLocationData?.nickName
          : `${options.length} locations`;
      return (
        <Stack
          horizontal
          tokens={{ childrenGap: 8 }}
          verticalAlign="center"
          horizontalAlign="center"
        >
          <FontIcon
            iconName="BpOrgItem"
            styles={{ root: { color: theme.palette.themePrimary } }}
          />
          <Stack.Item>{label}</Stack.Item>
        </Stack>
      );
    } else {
      return placeholder;
    }
  };

  const onRenderOption = (option: Option<PracOrgUnit>) => {
    const defaultColor = theme.palette.neutralTertiary;
    const color =
      option.data?.orgUnitLocationData?.appointmentBookMarkerCode ??
      defaultColor;
    return (
      <LocationOption
        key={option.key}
        color={color}
        text={option.text}
        showIcon={showIcon}
        showInactiveBadge={hasInactiveFlag(option?.key)}
        showDefaultLocationBadge={
          showDefaultLocationBadge && option.data?.isDefault
        }
      />
    );
  };

  const calloutWidth = showInactiveLocation ? 435 : undefined;

  return (
    <DataFetcher fetch={getLocations} noExceptionsHandlers>
      {(locations = [], loading, error) => {
        const options: Option<PracOrgUnit>[] = getOptions(locations);
        return (
          <OptionsSelect
            {...rest}
            calloutWidth={calloutWidth}
            options={options}
            errorMessage={error?.message ?? rest.errorMessage}
            loading={loading || rest.loading}
            onRenderOption={onRenderOption}
            styles={mergeFuncStyles(
              (): Partial<GenericSelectStyles> => ({
                fieldContent: { span: { width: "100%" } }
              }),
              styles
            )}
            onRenderFieldContent={
              rest.multiSelect
                ? onRenderFieldContentMultiple
                : onRenderFieldContentSingle
            }
          />
        );
      }}
    </DataFetcher>
  );
};
