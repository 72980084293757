import {
  FunctionComponent,
  HTMLAttributes,
  useEffect,
  useRef,
  useState
} from "react";

import {
  GenericSelect,
  GenericSelectProps,
  GenericSelectTypeSwitcher,
  Option,
  PersonaSize
} from "@bps/fluent-ui";
import { isDefined } from "@bps/utils";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UsersFilter } from "@shared-types/core/users-filter.interface.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";

interface InternalUsersSelectProps
  extends Pick<
      GenericSelectProps,
      | "showCountCoin"
      | "selectAllButtonText"
      | "hideSelectAllButton"
      | "showAllSelected"
      | "styles"
      | "label"
      | "required"
      | "calloutWidth"
      | "hideCountCoinWhenAllSelected"
      | "isAllSelected"
      | "onAllSelected"
      | "disabled"
      | "onBlur"
      | "onFocus"
    >,
    Pick<HTMLAttributes<HTMLElement>, "id" | "datatype"> {
  usersFilter?: UsersFilter;
  placeholder?: string;
  className?: string;
  filterByStatus?: boolean;
  showInactiveBadge?: boolean;
  onFilter?: (users: User[]) => Promise<User[]>;
}

export type UsersSelectProps =
  GenericSelectTypeSwitcher<InternalUsersSelectProps>;

export const UsersSelect: FunctionComponent<UsersSelectProps> = ({
  usersFilter,
  filterByStatus,
  showInactiveBadge,
  placeholder,
  onFilter,
  ...rest
}) => {
  const { core } = useStores();
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const isMounted = useRef<boolean>(true);

  const predicateSelected = (user: User) =>
    rest.selectedKeys ? !rest.selectedKeys.includes(user.id) : true;

  // get users if component has been unmount and mount again with some keys
  useEffect(() => {
    const ids = (
      Array.isArray(rest.selectedKeys) ? rest.selectedKeys : [rest.selectedKeys]
    ).filter(isDefined); // excluded all empty strings

    if (!!ids.length && users.length === 0) {
      Promise.all(
        ids.map(id => core.getUser(id, { showOnCalendar: true }))
      ).then(result => {
        isMounted.current && setUsers(result);
      });
    }
  }, [core, rest.selectedKeys, users.length]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [users]);

  const sortUsers = (userA: User, userB: User) => {
    if (filterByStatus) {
      // Sort by status first ("ACTIVE" before "INACTIVE")
      if (
        userA.status === UserStatus.Active &&
        userB.status === UserStatus.Inactive
      ) {
        return -1; // "ACTIVE" comes before "INACTIVE"
      } else if (
        userA.status === UserStatus.Inactive &&
        userB.status === UserStatus.Active
      ) {
        return 1; // "INACTIVE" comes after "ACTIVE"
      }
    }
    return userA.lastName.localeCompare(userB.lastName);
  };

  const usersDataOptions: Option[] = users
    .filter(u => {
      if (usersFilter?.showOnCalendar) {
        return !!u.userSetting?.showOnCalendar;
      }
      return true;
    })
    .slice()
    .sort(sortUsers)
    .map(data => ({
      key: data.id,
      text: data.name,
      data
    }));

  const pickedOptions = users.filter(
    c => rest.selectedKeys && rest.selectedKeys.includes(c.id)
  );

  // wrapped the onSearch into useCallback to prevent onListOpened changes
  const onSearch = async (value: string | undefined) => {
    setLoading(true);
    try {
      const result = await core.fetchUsers({
        ...usersFilter,
        search: value
      });

      if (onFilter && result.length > 0) {
        onFilter(result.map(u => u)).then(
          value =>
            // set values
            isMounted.current &&
            setUsers([...pickedOptions, ...value.filter(predicateSelected)])
        );
      } else {
        // set values
        isMounted.current &&
          setUsers([...pickedOptions, ...result.filter(predicateSelected)]);
      }
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  return (
    <GenericSelect
      searchBoxProps={{
        onSearch
      }}
      placeholder={placeholder}
      options={usersDataOptions}
      errorMessage={error}
      loading={loading}
      onListOpened={value => {
        if (value) {
          return onSearch(undefined);
        }
        return loading && setLoading(false);
      }}
      onRenderOption={(option: Option<User>) => (
        <Persona
          id={option.data!.id}
          size={PersonaSize.size24}
          text={option.data!.name}
          imageInitials={option.data!.initials}
          status={showInactiveBadge ? option.data?.status : undefined}
        />
      )}
      {...rest}
    />
  );
};
