import { memo } from "react";

import { Spinner } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { catchNotFoundError } from "@libs/utils/utils.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  DataFetcherProps
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { LastUpdatedRequiredProps } from "./LastUpdatedText.tsx";

type LastUpdatedProps = LastUpdatedRequiredProps & {
  dateFormat?: string;
  children: DataFetcherProps<{ date: string; userName: string }>["children"];
};
export const LastUpdatedDataFetcher: React.FunctionComponent<LastUpdatedProps> =
  memo(({ changeLog, lastUpdatedUserId, children, dateFormat }) => {
    const { core } = useStores();
    let userName: string;
    let date: string;

    if (changeLog) {
      date = DateTime.fromISO(
        changeLog.updatedDate ?? changeLog.createdDate
      ).toFormat(dateFormat || DATE_FORMATS.DAY_DEFAULT_FORMAT);

      userName = changeLog.updatedBy ?? changeLog.createdBy;
    }

    // Note the following is transitional code while the new schedule.updateByUserId
    // field is being backfilled. This code should be amended post data backfilling.
    const fetch = async () => {
      try {
        return lastUpdatedUserId
          ? await core.getUser(lastUpdatedUserId)
          : await core.getUserByUserName(userName);
      } catch (error) {
        return catchNotFoundError(error);
      }
    };

    return (
      <DataFetcher<User | undefined>
        refetchId={
          lastUpdatedUserId ?? changeLog?.updatedBy ?? changeLog?.createdBy
        }
        fetch={fetch}
        fallback={<Spinner />}
      >
        {user => children({ userName: user?.fullName || userName, date })}
      </DataFetcher>
    );
  });
