import { FunctionComponent } from "react";

import {
  CommandButton,
  IButtonProps,
  ProgressIndicator,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { formatBytesToMB } from "./utils.ts";

interface FileUploadProgressProps {
  loadedBytes: number;
  totalBytes: number;
  fileName: string;
  uploadError?: string;
  onCancelUpload: IButtonProps["onClick"];
}
export const FileUploadProgress: FunctionComponent<FileUploadProgressProps> = ({
  loadedBytes,
  totalBytes,
  onCancelUpload,
  fileName,
  uploadError
}) => {
  const { semanticColors } = useTheme();
  const description = uploadError ? (
    <Text styles={{ root: { color: semanticColors.errorText } }}>
      {uploadError}
    </Text>
  ) : (
    `${formatBytesToMB(loadedBytes)} /  ${formatBytesToMB(totalBytes)}`
  );

  return (
    <ProgressIndicator
      styles={{ root: { flexGrow: 1 } }}
      label={
        <Stack
          tokens={{
            childrenGap: 16
          }}
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          {`${fileName} ${
            uploadError ? "upload has failed" : "is being uploaded"
          }`}
          <CommandButton
            text="Cancel"
            styles={{ root: { flexShrink: 0, height: 20 } }}
            iconProps={{ iconName: "Cancel" }}
            onClick={onCancelUpload}
          />
        </Stack>
      }
      description={description}
      value={loadedBytes}
      max={totalBytes}
      barHeight={2}
    />
  );
};
