import { isEqual } from "lodash";

/* eslint-disable no-restricted-imports */
import {
  FieldArrayProps,
  FieldArray as FinalFieldArray
} from "react-final-form-arrays";

import { memoizeFunction } from "@bps/fluent-ui";

type IsEqualFn<T extends any = any> = (x: T, y: T) => boolean;

/**
 * Returns a new isEqual function that checks equality of two arrays
 * by comparing both their length and strict equality of their elements or any given element equal function.
 * @param isElementEqual
 */
export const createArrayEqual = memoizeFunction(
  <T extends any = any>(
    isElementEqual: IsEqualFn<T> = isEqual
  ): IsEqualFn<T[]> => {
    return (a: T[], b: T[]) => {
      if (a === b || (a == null && b == null)) {
        return true;
      }

      if (a == null || b == null) {
        return false;
      }

      if (a.length !== b.length) {
        return false;
      }

      for (let i = 0; i < a.length; i++) {
        if (!isElementEqual(a[i], b[i])) {
          return false;
        }
      }

      return true;
    };
  }
);

/**
 * Wraps the FieldArray component from react-final-form to use a default JSON.stringify
 * equality function. The individual element equality function can still be overridden by passing in
 * the Equal prop.
 * @param props
 */
export const FieldArray = <
  FieldValue extends any = any,
  T extends HTMLElement = HTMLElement
>(
  props: FieldArrayProps<FieldValue, T>
) => {
  const { isEqual: isEqualProp, ...otherProps } = props;
  const isEqual = createArrayEqual(isEqualProp);
  return <FinalFieldArray<FieldValue, T> isEqual={isEqual} {...otherProps} />;
};
