import { extendToSinglePersonaPicker } from "@ui-components/pickers/extendToSinglePersonaPicker.tsx";

import { MultiUserPicker } from "./MultiUserPicker.tsx";
import { UserPickerProps } from "./UserPicker.types.tsx";

/**
 * User Picker is a component used to pick a single user from a list.
 */

export const UserPicker = extendToSinglePersonaPicker(
  MultiUserPicker
) as React.ComponentType<UserPickerProps>;
