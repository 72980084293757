import {
  applyOpacityToHexColor,
  IButtonStyles,
  ITheme,
  mergeStyleSets
} from "@bps/fluent-ui";

export const getNavBarStylesSet = (theme: ITheme) => {
  const hoverColor = applyOpacityToHexColor(theme.palette.neutralLight, 25);

  const hoverPressedColor = applyOpacityToHexColor(
    theme.palette.neutralLight,
    40
  );

  const color = theme.palette.neutralLighterAlt;

  const iconHover = { color, backgroundColor: "transparent" };

  const getDefaultNavButtonStyles = (): IButtonStyles => ({
    root: {
      height: 38,
      color,
      backgroundColor: theme.semanticColors.mainHeaderBackground,
      borderRadius: 2
    },
    icon: {
      color
    },
    rootPressed: {
      color,
      background: hoverPressedColor
    },
    iconPressed: { color },
    rootHovered: {
      color,
      background: hoverColor
    },
    menuIcon: iconHover,
    menuIconHovered: iconHover,
    menuIconPressed: iconHover,
    menuIconExpanded: iconHover,
    menuIconExpandedHovered: iconHover,
    iconHovered: iconHover
  });

  const getCommandBarButtonStyle = (): IButtonStyles =>
    mergeStyleSets(getDefaultNavButtonStyles(), {
      rootExpanded: {
        color,
        background: hoverPressedColor,
        ".ms-Button-menuIcon, .ms-Button-icon": {
          color
        }
      },
      rootExpandedHovered: {
        color,
        background: hoverPressedColor
      }
    });

  return {
    getDefaultNavButtonStyles,
    getCommandBarButtonStyle,
    hoverColor,
    hoverPressedColor
  };
};
