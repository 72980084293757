import { useRef } from "react";
import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  GenericPicker,
  GenericPickerProps,
  getId,
  useTheme
} from "@bps/fluent-ui";
import { identity } from "@libs/utils/utils.ts";

import { ExposedFieldProps, getFieldErrorMessage } from "./FinalForm.tsx";
import { FormItemField, FormItemFieldProps } from "./FormItemField.tsx";
import { ReadOnlyTextField } from "./read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";
import { getNewPickerStyles } from "./utils.ts";

type HtmlElementType = any;

export type GenericPickerAdapterProps<TItem> = Omit<
  GenericPickerProps<TItem>,
  "defaultSelectedItems"
> &
  Pick<
    FormItemFieldProps,
    "required" | "label" | "validateOnInitialize" | "hint"
  > & {
    fieldItemStyles?: FormItemFieldProps["styles"];
  };

export type GenericPickerFieldProps<TItem> = ExposedFieldProps<
  GenericPickerProps<TItem>["selectedKey"],
  HtmlElementType
> &
  GenericPickerAdapterProps<TItem>;

type InternalGenericPickerAdapterProps<TItem> = FieldRenderProps<
  GenericPickerProps<TItem>["selectedKey"],
  HtmlElementType
> &
  GenericPickerAdapterProps<TItem>;

const GenericPickerAdapter = <TItem extends { text: string; key: string }>(
  props: InternalGenericPickerAdapterProps<TItem>
) => {
  const idRef = useRef(getId(props.input.name));

  const {
    hint,
    meta,
    label,
    input,
    styles,
    readOnly,
    required,
    disabled,
    inputProps,
    fieldItemStyles,
    validateOnInitialize,
    ...genericPickerProps
  } = props;

  const theme = useTheme();
  const { meta: formMeta } = useField(input.name);

  if (readOnly || formMeta?.data?.readOnly) {
    const getValueText = async () => {
      const option = await genericPickerProps.onFetchItem(String(input.value));
      return option?.text;
    };

    return (
      <ReadOnlyTextField
        value={input.value}
        getValueText={getValueText}
        label={label}
      />
    );
  }

  const hasErrorMessage = !!getFieldErrorMessage(meta, validateOnInitialize);

  const onChange = (value: string | undefined, item: TItem | undefined) => {
    input.onChange(value);
    props.onChange && props.onChange(value, item);
    // input re-obtains the focus on change but onFocus is not triggered
    // https://github.com/OfficeDev/@fluentui/react/blob/b982a68f449a61b16429d1a025cd8a949177d954/packages/@fluentui/react/src/components/GenericPickers/BaseGenericPicker.tsx#L875
    input.onFocus();
  };

  const _disabled = disabled || formMeta?.data?.disabled;

  return (
    <FormItemField
      hint={hint}
      name={input.name}
      required={required}
      disabled={_disabled}
      label={label}
      styles={fieldItemStyles}
      htmlFor={idRef.current}
      validateOnInitialize={validateOnInitialize}
    >
      <GenericPicker<TItem>
        {...genericPickerProps}
        onChange={onChange}
        selectedKey={input.value}
        disabled={_disabled}
        inputProps={{
          onBlur: input.onBlur,
          onFocus: input.onFocus,
          id: idRef.current,
          placeholder: inputProps?.placeholder
        }}
        styles={getNewPickerStyles(
          theme,
          { hasErrorMessage, disabled: _disabled },
          styles
        )}
      />
    </FormItemField>
  );
};

/**
 * Use GenericPickerField to use GenericPicker with final-form.
 * It also wraps the GenericPicker in a FormItemField to support label and error message.
 * @param props
 */
export const GenericPickerField = <TItem extends any>(
  props: GenericPickerFieldProps<TItem>
) => (
  <Field
    format={identity}
    parse={identity}
    {...props}
    component={GenericPickerAdapter}
  />
);
