// eslint-disable-next-line import/extensions
import get from "lodash/get";
import { PropsWithChildren } from "react";
import { FormSpy } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  FieldItemError,
  Stack
} from "@bps/fluent-ui";
import { isDeepEmptyUtils } from "@libs/utils/isDeepEmpty.utils.ts";

export interface ErrorWrapperProps
  extends PropsWithChildren<{
    name: string;
    fields: string[];
    errorMessage: string;
    submitFailedOverride?: boolean;
    messageOverride?: {
      message: string;
      field: string;
      submitFailedOverride?: boolean;
    };
  }> {}

export const ErrorWrapper: React.FC<ErrorWrapperProps> = ({
  name,
  fields,
  errorMessage,
  messageOverride,
  submitFailedOverride,
  children
}) => {
  return (
    <FormSpy
      subscription={{
        errors: true,
        submitFailed: true
      }}
    >
      {({ errors, submitFailed }) => {
        const isFieldPresent = fields.find(
          field =>
            errors &&
            get(errors, field) &&
            !isDeepEmptyUtils(get(errors, field))
        );

        let isOverrideFieldPresent = false;
        if (isFieldPresent && messageOverride) {
          isOverrideFieldPresent =
            get(errors, messageOverride.field) &&
            !isDeepEmptyUtils(get(errors, messageOverride.field));
        }

        const showError =
          isFieldPresent &&
          (submitFailed ||
            submitFailedOverride ||
            (isOverrideFieldPresent && messageOverride?.submitFailedOverride));

        return (
          <Stack>
            <Stack
              styles={(prop, theme) => ({
                root: {
                  border: showError
                    ? `1px solid ${theme.semanticColors.errorText}`
                    : undefined,
                  borderRadius: showError ? 2 : undefined
                }
              })}
            >
              {children}
            </Stack>
            {!!showError && (
              <div {...dataAttribute(DataAttributes.Element, "errorMessage")}>
                <FieldItemError
                  name={name}
                  errorMessage={
                    isOverrideFieldPresent
                      ? messageOverride!.message
                      : errorMessage
                  }
                />
              </div>
            )}
          </Stack>
        );
      }}
    </FormSpy>
  );
};
