import { mergeStyleSets, Text, TextProps, useTheme } from "@bps/fluent-ui";

export const ItalicCenterText: React.FC<TextProps> = ({ styles, ...rest }) => {
  const theme = useTheme();
  const textStyles = mergeStyleSets(styles, {
    root: {
      fontSize: theme.fonts.medium.fontSize,
      fontStyle: "italic",
      textAlign: "center",
      color: theme.palette.neutralTertiary
    }
  });

  return <Text styles={textStyles} block={true} {...rest} />;
};
