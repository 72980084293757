import { ComponentType, ReactNode, useMemo } from "react";

import { mergeStyleSets } from "@bps/fluent-ui";

import { SubmissionForm } from "../submission-form/SubmissionForm.tsx";
import type { SubmissionFormStyles } from "../submission-form/SubmissionForm.types.ts";
import {
  DialogWithSubmitButtons,
  DialogWithSubmitButtonsProps
} from "./DialogWithSubmitButtons.tsx";
import { SubmissionFormDialogProps } from "./SubmissionFormDialog.types.ts";

const layoutWrapper = <FormValues extends object = object>(
  Component: ComponentType<DialogWithSubmitButtonsProps<FormValues>>,
  props: DialogWithSubmitButtonsProps<FormValues>
) => {
  return ({ children }: { children: ReactNode }) => (
    <Component {...props} children={children} />
  );
};

export const SubmissionFormDialog = <FormValues extends object = object>({
  dialogProps,
  promptDialogProps,
  dialogName,
  hideButtons,
  buttonsProps: submitButtonProps,
  loadingData,
  dataLoadingError,
  Fallback,
  disablePrompt,
  lockAnyRouting,
  ...submissionFormProps
}: SubmissionFormDialogProps<FormValues>) => {
  const submissionFormsStyles: Partial<SubmissionFormStyles> = {
    fields: { padding: "0 24px" },
    root: { minHeight: 0 }
  };

  // preventing DialogWithSubmitButtons re-rendering as a side effect of external states mutations
  const LayoutWrapper = useMemo(
    () =>
      layoutWrapper<FormValues>(DialogWithSubmitButtons, {
        dialogName,
        dialogProps,
        promptDialogProps,
        hideButtons,
        submitButtonProps,
        loadingData,
        dataLoadingError,
        Fallback,
        disablePrompt,
        lockAnyRouting
      }),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      loadingData,
      dataLoadingError,
      dialogName,
      dialogProps.dialogContentProps?.title
    ]
  );

  return (
    <SubmissionForm<FormValues>
      {...submissionFormProps}
      styles={mergeStyleSets(submissionFormsStyles, submissionFormProps.styles)}
      disableRoutePrompt
      hideButtons
      LayoutWrapper={LayoutWrapper}
      formName={`dialog-${dialogName}`}
    />
  );
};
