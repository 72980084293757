import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  concatStyleSetsWithProps,
  dataAttribute,
  DataAttributes,
  ExtendedPickerProps,
  getPersonaInitialsColor,
  IBasePickerStyleProps,
  IPersonaProps,
  mergeStyles,
  useTheme
} from "@bps/fluent-ui";
import {
  BusinessRoleClasses,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  compactPeoplePickerOf,
  horizontalItem
} from "@ui-components/pickers/contact-picker/compactPeoplePickerOf.tsx";
import { getContactPickerItemStyles } from "@ui-components/pickers/contact-picker/ContactPicker.styles.ts";
import { PickerIcon } from "@ui-components/pickers/contact-picker/PickerIcon.tsx";

import { MultiUserPickerFallback } from "./MultiUserPickerFallback.tsx";
import { MultiUserPickerProps } from "./UserPicker.types.tsx";

const UserCompactPeoplePicker = compactPeoplePickerOf<User>();

export const sortUsers = (a: User, b: User, currentUser: User | undefined) => {
  const isCurrentUserProvider = currentUser?.businessRoleClasses.includes(
    BusinessRoleClasses.Provider
  );

  if (isCurrentUserProvider) {
    if (a === currentUser) {
      return -1; // Move currentUser to the very top.
    }
    if (b === currentUser) {
      return 1; // Move currentUser to the very top.
    }
  }

  if (a.status === UserStatus.Active && b.status === UserStatus.Active) {
    return a.lastName.localeCompare(b.lastName);
  } else if (a.status === UserStatus.Active) {
    return -1;
  } else if (b.status === UserStatus.Active) {
    return 1;
  } else if (
    a.status === UserStatus.Pending &&
    b.status === UserStatus.Pending
  ) {
    return a.lastName.localeCompare(b.lastName);
  } else if (a.status === UserStatus.Pending) {
    return -1;
  } else if (b.status === UserStatus.Pending) {
    return 1;
  } else {
    return a.lastName.localeCompare(b.lastName);
  }
};
/**
 * User Picker is a component used to pick a one to many users from a list.
 * It is the base component of UserPicker.
 *
 * The underlying component is the CompactPeoplePicker.
 */

export const MultiUserPicker: FunctionComponent<MultiUserPickerProps> =
  observer(
    ({
      selectedKeys,
      iconName,
      styles: basicStyles,
      filter = {},
      onResolveSuggestionItem,
      onSearchItems,
      onEmptySearchItems,
      loading,
      currentUserOnTop,
      error,
      withAvailabilities,
      ...basePickerProps
    }) => {
      const theme = useTheme();
      const { core, booking } = useStores();

      const _onEmptySearchUsers = async (): Promise<User[]> => {
        const users = await core.fetchUsers({
          ...filter
        });

        return users
          .slice()
          .sort((a, b) =>
            sortUsers(a, b, currentUserOnTop ? core.user : undefined)
          );
      };

      const _onSearchItems = async (
        search: string
      ): Promise<QueryResult<User>> => {
        const users = await core.fetchUsers({
          ...filter,
          search
        });

        if (withAvailabilities) {
          await booking.getUserAvailabilities(users.map(user => user.id));
        }

        return new Promise(resolve =>
          resolve({
            results: users
              .slice()
              .sort((a, b) =>
                sortUsers(a, b, currentUserOnTop ? core.user : undefined)
              ),
            skip: 0,
            take: users.length,
            total: users.length
          })
        );
      };

      const _onResolveSuggestionItem: ExtendedPickerProps<
        IPersonaProps,
        User
      >["onResolveSuggestionItem"] = (item, context) => {
        const getBadgeClass = () => {
          if (item.status === UserStatus.Inactive) {
            const { inactive } = getContactPickerItemStyles(theme!);

            return mergeStyles("item-inactive", [inactive, horizontalItem]);
          }

          return undefined;
        };

        const suggestionItem: IPersonaProps = {
          text: item.name,
          initialsColor: getPersonaInitialsColor({ text: item.id }),
          imageInitials: item.initials,
          className: context.role === "selection" ? getBadgeClass() : undefined,
          ...(onResolveSuggestionItem
            ? onResolveSuggestionItem(item, context)
            : undefined)
        };

        if (!suggestionItem.secondaryText) {
          suggestionItem.styles = () => ({
            secondaryText: { display: "none" }
          });
        }
        return suggestionItem;
      };

      const isItemSelected = !!selectedKeys?.length;

      const styles = iconName
        ? (props: IBasePickerStyleProps) =>
            concatStyleSetsWithProps(
              props,
              {
                text: !props.isFocused &&
                  !isItemSelected && {
                    minWidth: "auto",
                    paddingLeft: "32px"
                  },
                root: (props.isFocused || isItemSelected) && {
                  selectors: {
                    "&+.picker-icon": {
                      display: "none"
                    }
                  }
                }
              },
              basicStyles
            )
        : basicStyles;

      return (
        <div
          style={{ position: "relative" }}
          {...dataAttribute(DataAttributes.Element, "user-picker")}
        >
          <UserCompactPeoplePicker
            {...basePickerProps}
            selectedKeys={selectedKeys}
            keyAccessor={x => x.id}
            onSearchItems={onSearchItems ?? _onSearchItems}
            onEmptySearchItems={onEmptySearchItems ?? _onEmptySearchUsers}
            onResolveSuggestionItem={_onResolveSuggestionItem}
            onFetchItems={ids => Promise.all(ids.map(id => core.getUser(id)))}
            styles={styles}
          />
          <MultiUserPickerFallback loading={loading} error={error} />
          {!loading && !error && !!iconName && (
            <PickerIcon iconName={iconName} />
          )}
        </div>
      );
    }
  );
