// eslint-disable-next-line import/extensions
import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";
import { FunctionComponent, useEffect, useState } from "react";

import { getDocumentPdfPage } from "./pdf-utils.tsx";

interface PdfCanvasProps {
  pdf: PDFDocumentProxy;
  pageNumber: number;
  scale?: number;
}

export const PdfCanvas: FunctionComponent<PdfCanvasProps> = ({
  pdf,
  pageNumber,
  scale
}) => {
  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>();
  const canvasId = `pdf-canvas-${pageNumber}`;
  const canvasWrapperId = `pdf-canvas-wrapper-${pageNumber}`;
  const canvasTextLayerId = `canvas-text-layer-${pageNumber}`;
  useEffect(() => {
    if (canvasRef) {
      getDocumentPdfPage({
        canvasId,
        canvasTextLayerId,
        pdf,
        pageNumber: pageNumber + 1,
        scale
      });
    }
  }, [canvasId, canvasRef, canvasTextLayerId, pageNumber, pdf, scale]);

  return (
    <div
      id={canvasWrapperId}
      style={{
        position: "relative",
        margin: "0 auto",
        paddingBottom: 16,
        paddingTop: pageNumber === 0 ? 16 : 0
      }}
    >
      <canvas
        id={canvasId}
        ref={async r => {
          if (r && !canvasRef) {
            setCanvasRef(r);
          }
        }}
      />
      <div id={canvasTextLayerId} className="canvas-text-layer" />
    </div>
  );
};
