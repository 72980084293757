import { Field } from "react-final-form";

import { CommunicationKind } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { formatCommunicationValue } from "@stores/core/models/Communication.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  TextFieldAdapter,
  TextFieldAdapterProps,
  TextInputFieldProps
} from "@ui-components/form/TextInputField.tsx";

// This component is necessary as using formatOnBlur on the field causes a value of empty string to switch to undefined
// which causes problems when passed into a text input
// see https://github.com/final-form/react-final-form/issues/529
export const PhoneTextFieldAdapter = ({
  value,
  ...props
}: TextFieldAdapterProps) => {
  return (
    <TextFieldAdapter type="tel" {...props} value={props.input.value || ""} />
  );
};

export const PhoneTextField = ({ ...props }: TextInputFieldProps) => {
  const { core } = useStores();

  const country = core.tenantDetails && core.tenantDetails.country;

  const format = (value: string) =>
    formatCommunicationValue(value, CommunicationKind.Phone, country);

  return (
    <Field
      styles={{ fieldGroup: { maxWidth: 200 } }}
      format={format}
      formatOnBlur={true}
      {...props}
      component={PhoneTextFieldAdapter}
    />
  );
};
