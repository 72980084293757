import { useCallback, useEffect, useRef, useState } from "react";

import { useDebounce } from "@bps/utils";
import { RunQueryOptions } from "@libs/utils/promise-observable/promise-observable.types.ts";
import {
  IMaybePromiseObservable,
  isPending,
  maybePromiseObservable,
  QueryResult,
  runQuery
} from "@libs/utils/promise-observable/promise-observable.utils.ts";

import {
  InfiniteScrollListSortProps,
  InfiniteScrollProps
} from "./InfiniteScrollList.types.ts";

// delay before refreshing (in case multiple changes come through at once)
const REFRESH_DELAY = 500;

export const DEFAULT_PAGE_SIZE = 25;

export const useInfiniteScroll = <Item extends { id: string }>(
  props: InfiniteScrollProps<Item>
) => {
  const { getItems, initialSort, onGetItems, onSort, refreshKey, sort } = props;

  const isFirstLoad = useRef<boolean>(true);

  const searchResults = useRef<IMaybePromiseObservable<QueryResult<Item>>>(
    maybePromiseObservable()
  );

  const nextResults = useRef<IMaybePromiseObservable<QueryResult<Item>>>(
    maybePromiseObservable()
  );

  const [_sortProps, _setSortProps] = useState<
    InfiniteScrollListSortProps | undefined
  >(initialSort);

  const sortProps = sort || _sortProps;
  const setSortProps = onSort || _setSortProps;

  const timeout = useRef<number>(0);

  const refresh = useCallback(() => {
    window.clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      const promise = getItems({
        ...sortProps,
        total: true,
        take: (searchResults.current.value?.skip || 0) + DEFAULT_PAGE_SIZE
      });

      promise.then(result => {
        const resultsAreDifferent =
          !searchResults.current.value?.results ||
          searchResults.current.value.results.length !==
            result.results.length ||
          result.results.some((item, index) => {
            return item.id !== searchResults.current.value?.results[index].id;
          });

        // searchResults ref isn't updated until after promise is resolved to minimise unexpected UI changes
        // If the list is still the same, don't update the promise because it will cause an unecessary flicker
        if (resultsAreDifferent) {
          searchResults.current.set(promise);
        }
      });
    }, REFRESH_DELAY);
  }, [getItems, sortProps]);

  // refresh list if an item was added or updated
  useEffect(() => {
    if (searchResults.current.fulfilled && refreshKey) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  const { value } = searchResults.current;

  const isLoading = isPending(searchResults.current);
  const isLoadingMore = isPending(nextResults.current);

  const search = useCallback(
    (options?: RunQueryOptions) => {
      const resultPromise = runQuery({
        query: sortProps ?? {},
        lastResult: searchResults.current.value,
        action: query =>
          getItems({
            ...query,
            total: true,
            take: DEFAULT_PAGE_SIZE
          }),
        options
      });

      if (options?.fetchNextResults) {
        nextResults.current.set(
          resultPromise.then(result => {
            searchResults.current.set(resultPromise);
            return result;
          })
        );
      } else {
        searchResults.current.set(resultPromise);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getItems, sortProps]
  );

  // search is debounced in case there are multiple changes in a row
  const debouncedSearch = useDebounce(search, 20);

  useEffect(() => {
    if (isFirstLoad.current) {
      // don't debounce if this is the first load
      isFirstLoad.current = false;
      search();
    } else {
      debouncedSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (onGetItems && value) {
      onGetItems(value.results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.results, value?.results?.length]);

  const handleScrolledToBottom = () => {
    if (!isLoading && !isLoadingMore) {
      search({ fetchNextResults: true });
    }
  };

  return {
    handleScrolledToBottom,
    isLoading,
    isLoadingMore,
    searchResults: searchResults.current,
    setSortProps,
    sortProps
  };
};
