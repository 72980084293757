// eslint-disable-next-line import/extensions
import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";
import { useEffect } from "react";

import { Image, Spinner } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { PRINT_SCALE } from "@ui-components/document-viewer/PDFViewer.types.ts";

import {
  getCanvasKey,
  getDocumentPdfPages,
  mapPdfPages,
  pdfUrlToString
} from "./pdf-utils.tsx";
import { PdfCanvas } from "./PdfCanvas.tsx";

interface DocumentPrintViewProps {
  documentUrl: string | Uint8Array;
  onImagesRendered?: () => void;
  isImage?: boolean;
}
let notifyTimeout: NodeJS.Timeout;
export const DocumentPrintView: React.FC<DocumentPrintViewProps> = ({
  documentUrl,
  onImagesRendered,
  isImage
}) => {
  useEffect(() => {
    return () => {
      clearTimeout(notifyTimeout);
    };
  }, []);

  return (
    <DataFetcher<PDFDocumentProxy | undefined>
      refetchId={typeof documentUrl !== "string" ? newGuid() : documentUrl}
      fetch={async () => {
        if (isImage) {
          return undefined;
        } else return await getDocumentPdfPages(documentUrl);
      }}
      fallback={<Spinner />}
    >
      {pdf => {
        // setTimeout to notify print lib if documents are ready
        if (onImagesRendered) {
          notifyTimeout = setTimeout(() => {
            onImagesRendered();
          }, 500);
        }

        if (isImage) {
          return (
            <Image
              alt="pdf document preview"
              src={pdfUrlToString(documentUrl)}
              maximizeFrame={true}
              styles={{
                root: {
                  overflowY: "visible",
                  overflowX: "visible",
                  alignContent: "center"
                },
                image: { display: "block" }
              }}
            />
          );
        } else {
          if (pdf) {
            return mapPdfPages(pdf).map((_, idx: number) => {
              return (
                <PdfCanvas
                  pdf={pdf}
                  key={getCanvasKey(pdf, idx)}
                  pageNumber={idx}
                  scale={Number(PRINT_SCALE)}
                />
              );
            });
          }
          return null;
        }
      }}
    </DataFetcher>
  );
};
