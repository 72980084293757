import { getFocusStyle } from "@bps/fluent-ui";

import {
  DropzoneSectionStyleProps,
  DropzoneSectionStyles
} from "./DropzoneSection.types.ts";

export const getStyles = (
  props: DropzoneSectionStyleProps
): DropzoneSectionStyles => {
  const { className, theme, hasErrorMessage, isDragAccept, fileRejections } =
    props;

  const { semanticColors, palette } = theme;
  return {
    root: ["bp-FilePicker", className],
    dropzone: [
      {
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        padding: "24px",
        color: theme.palette.neutralTertiary,
        backgroundColor: theme.palette.neutralLighterAlt,
        border:
          hasErrorMessage || !!fileRejections.length
            ? `1px solid ${semanticColors.errorText}`
            : `2px dashed ${theme.palette.neutralQuaternary}`,
        selectors: {
          "&:hover": {
            cursor: "pointer",
            backgroundColor: semanticColors.buttonBackgroundHovered
          }
        }
      },
      isDragAccept && { backgroundColor: palette.neutralLight },
      getFocusStyle(theme, {
        borderColor: `1px solid ${semanticColors.buttonText}`,
        inset: 1
      })
    ],
    maxReached: [
      {
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        padding: "24px",
        color: theme.palette.neutralTertiary,
        backgroundColor: theme.palette.neutralLighterAlt
      }
    ]
  };
};
