import { FunctionComponent, useMemo } from "react";

import {
  // eslint-disable-next-line no-restricted-imports
  FilterBar as BaseFilterBar,
  classNamesFunction,
  CustomOptionType,
  styled,
  useTheme
} from "@bps/fluent-ui";
import { BookableUsersSelectFilterItem } from "@ui-components/filter-bar/extra-options/BookableUsersSelectFilterItem.tsx";
import { ContactPickerFilterItem } from "@ui-components/filter-bar/extra-options/ContactPickerFilterItem.tsx";
import { ContactsSelectFilterItem } from "@ui-components/filter-bar/extra-options/ContactsSelectFilterItem.tsx";
import { LocationsSelectFilterItem } from "@ui-components/filter-bar/extra-options/LocationsSelectFilterItem.tsx";
import { PatientsSelectFilterItem } from "@ui-components/filter-bar/extra-options/PatientsSelectFilterItem.tsx";
import { UserPickerFilterItem } from "@ui-components/filter-bar/extra-options/UserPickerFilterItem.tsx";
import { UsersSelectFilterItem } from "@ui-components/filter-bar/extra-options/UsersSelectFilterItem.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";
import { withDatesRangePickerFieldAnalytics } from "@ui-components/form/DatesRangePickerField/withDatesRangePickerFieldAnalytics.tsx";

import { DateTimeFramePickerFilterItem } from "./extra-options/DateTimeFrameFilterItem.tsx";
import { getFilterBarStyles } from "./FilterBar.styles.ts";
import { FilterBarStyles, FilterBarStylesProps } from "./FilterBar.types.ts";

const getClassNames = classNamesFunction<
  FilterBarStylesProps,
  FilterBarStyles
>();

export const FilterBarBase: FunctionComponent<
  FilterBarProps<object>
> = props => {
  const theme = useTheme();
  const classNames = getClassNames(props.styles, {
    theme
  });

  const customOptions: CustomOptionType[] = useMemo(() => {
    return [
      {
        type: "bookableUsersSelect",
        Component: BookableUsersSelectFilterItem,
        className: classNames.select
      },
      {
        type: "contactsSelect",
        Component: ContactsSelectFilterItem,
        className: classNames.select
      },
      {
        type: "patientsSelect",
        Component: PatientsSelectFilterItem,
        className: classNames.select
      },
      {
        type: "usersSelect",
        Component: UsersSelectFilterItem,
        className: classNames.select
      },
      {
        type: "userPicker",
        Component: UserPickerFilterItem,
        className: classNames.peoplePicker
      },
      {
        type: "contactPicker",
        Component: ContactPickerFilterItem,
        className: classNames.peoplePicker
      },
      {
        type: "locationsSelect",
        Component: LocationsSelectFilterItem,
        className: classNames.select
      },
      {
        type: "dateTimeFramePicker",
        Component: DateTimeFramePickerFilterItem,
        className: classNames.select
      }
    ];
  }, [classNames.peoplePicker, classNames.select]);

  const getItems = () =>
    props.items.map(i => {
      // for datesRangePicker we are passing withAnalytics HOC
      // to track some analytics in AppInsights
      if (i.type === "datesRangePicker") {
        return {
          ...i,
          props: {
            ...i.props,
            withAnalytics: withDatesRangePickerFieldAnalytics
          }
        };
      }
      return i;
    }) as any; // ⚠️ any is required here since we want to override base items by adding custom options. Ilya S.

  return (
    <BaseFilterBar
      {...props}
      items={getItems()}
      customOptions={customOptions}
    />
  );
};

export const FilterBar = <FormValues extends object = object>(
  props: FilterBarProps<FormValues>
) => <UntypedFilterBar {...props} />;

const UntypedFilterBar = styled<
  FilterBarProps,
  FilterBarStylesProps,
  FilterBarStyles
>(FilterBarBase, getFilterBarStyles);
