// eslint-disable-next-line import/extensions
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import { useBlocker } from "react-router-dom";

import { ConfirmDialog } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { Location } from "@stores/router/RouterStore.ts";

import { useMultiFormPromptsContext } from "../hooks/useMultiFormPromptsContext.ts";

export const defaultButtonsText = {
  confirm: "Discard",
  cancel: "Keep editing"
};

export type PromptProps = {
  /**
   * When falsy is returned, the navigation is accepted
   * Otherwise, the returned message is displayed to confirm the navigation.
   */
  promptPredicate: (nextLocation: Location) => false | string;
  onConfirmed?: () => void;
  hideConfirmation?: boolean;
};

export const SUB_TEXT_ID: string = "final-form-prompt";

export const PromptBase: React.FC<PromptProps> = ({
  promptPredicate,
  onConfirmed,
  hideConfirmation
}) => {
  const messageRef = useRef<string>();
  const willUnmount = useRef<boolean>(false);

  const messageFn = useCallback(
    (nextLocation: Location, currentLocation: Location) => {
      const promptMessage = promptPredicate(nextLocation);
      if (
        !!promptMessage &&
        currentLocation.pathname !== nextLocation.pathname
      ) {
        messageRef.current = promptMessage;
        return true;
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const blocker = useBlocker(props =>
    messageFn(props.nextLocation, props.currentLocation)
  );

  useEffect(() => {
    return () => {
      willUnmount.current = true;
    };
  }, []);

  if (blocker.state === "blocked") {
    // Prompt will unmount, meaning we shouldn't prevent navigation
    if (willUnmount.current) {
      blocker.proceed();
    } else {
      return (
        <ConfirmDialog
          hidden={false}
          modalProps={{
            styles: {
              root: { display: hideConfirmation ? "none" : "flex" }
            }
          }}
          dialogContentProps={{
            subText: messageRef.current && messageRef.current,
            subTextId: SUB_TEXT_ID
          }}
          confirmButtonProps={{
            text: defaultButtonsText.confirm
          }}
          cancelButtonProps={{
            text: defaultButtonsText.cancel
          }}
          onConfirm={() => {
            onConfirmed && onConfirmed();
            blocker.proceed();
          }}
          onCancel={() => blocker.reset()}
        />
      );
    }
  }
  return null;
};

// This component is used to ensure only one Prompt is ever mounted at one time
export const Prompt: React.FC<PromptProps> = observer(props => {
  const [key] = useState<string>(newGuid());
  const helper = useMultiFormPromptsContext();
  useEffect(() => {
    helper.onMountPrompt?.(key);
    return () => {
      helper.onUnmountPrompt?.(key);
    };
  }, [key, helper]);

  if (isEmpty(helper) || helper.activePrompt === key) {
    return <PromptBase {...props} />;
  }
  return null;
});
