import { When, type WithPermissionProps } from "@ui-components/withPerm.tsx";

import { Navigate, NavigateProps } from "./Navigate.tsx";

export type WhenNavigateProps = NavigateProps &
  Pick<WithPermissionProps, "permission" | "permissionOperator"> & {
    children: JSX.Element | string | null | undefined;
  };

export const WhenNavigate = (props: WhenNavigateProps) => {
  const { children, permission, permissionOperator, ...navigateProps } = props;

  return (
    <When
      permission={permission}
      permissionOperator={permissionOperator}
      else={<>{children}</>}
    >
      <Navigate {...navigateProps}>{children}</Navigate>
    </When>
  );
};
