import {
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from "react";
import { useField } from "react-final-form";

import { ITextFieldProps, mergeStyleSets, TextField } from "@bps/fluent-ui";

import { TextInputFieldProps } from "./TextInputField.tsx";

const FULL_HOURLY_MATCH = /^[0-9]*[:|.][0-5][0-9]$/;
const HOURLY_MATCH_HOURS_ONLY = /^[0-9]*$/;
const HOURLY_MATCH_NO_MINUTES = /^[0-9]*[:|.]$/;
const HOURLY_MATCH_NO_HOURS = /^:[0-5][0-9]$/;
const ALL_CHARS_BUT_NUMS_AND_PERIOD = /[^\d.]/g;

const MINUTES = 60;
const MAX_MINUTES_DIGITS = 2;
const MAX_NUMERIC_DECIMAL_PLACES = 2;

type UnitType = "PERUNIT" | "KM" | "HOURLY";
/**
 * formatString
 * formats a string provided by the input, stripping out or replacing unwanted characters
 */
export const formatString = (value: string, unitType: UnitType): string => {
  if (unitType === "HOURLY") {
    const chars = value.split("");
    let dividerPosition: number = -1;

    const filteredChars = chars.filter((char, index) => {
      // limit minutes to 2 digits
      if (
        dividerPosition !== -1 &&
        index > dividerPosition + MAX_MINUTES_DIGITS
      ) {
        return false;
      }

      if ((char === ":" || char === ".") && dividerPosition === -1) {
        // allow one colon or period character
        dividerPosition = index;
        return true;
      }

      // filter out all non-numeric characters
      return /^[0-9]$/.test(char);
    });

    return filteredChars.join("");
  }

  const str = value.replace(ALL_CHARS_BUT_NUMS_AND_PERIOD, "");
  const maxDigits =
    str.indexOf(".") !== -1
      ? str.indexOf(".") + MAX_NUMERIC_DECIMAL_PLACES + 1
      : str.length;
  return str.slice(0, maxDigits);
};

/**
 * convertString
 * converts the internal string value into a numeric value or empty string
 */
export const convertString = (
  value: string,
  unitType: UnitType
): number | "" => {
  if (!value) {
    return 0;
  }

  if (unitType === "HOURLY") {
    // hourly values are stored as a number of minutes - eg "1:15" is stored as 75

    // allowed formats are "2", "2:", ":34", "2:34", "12:34",
    if (
      HOURLY_MATCH_HOURS_ONLY.test(value) ||
      HOURLY_MATCH_NO_MINUTES.test(value) ||
      HOURLY_MATCH_NO_HOURS.test(value) ||
      FULL_HOURLY_MATCH.test(value)
    ) {
      const divider = value.indexOf(":") === -1 ? "." : ":";
      const [hours, minutes] = value.split(divider);
      return Number(hours || 0) * MINUTES + Number(minutes || 0);
    }
  } else {
    if (Number(value)) {
      return Number(value);
    }
  }

  return "";
};

/**
 * convertString
 * converts a numeric value to a string to display in the input
 */
export const convertNumber = (
  value: number | "",
  unitType: UnitType
): string => {
  if (value === "") {
    return value;
  }

  if (unitType === "HOURLY") {
    // hourly values are stored as a number of minutes - eg "1:15" is stored as 75

    const hours = Math.floor(value / MINUTES);
    const minutes = String(value % MINUTES).padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  return String(value);
};

interface UnitInputFieldProps extends Omit<TextInputFieldProps, "prefix"> {
  unitType: UnitType;
}

/**
 * Instance of TextInputField component. It handle input of such unions like:
 * hourly, km or just number.
 * Note: suffix can be overwritten by props to a custom one.
 * @param unitType - UnitType
 * @param props - Omit<TextInputFieldProps, "prefix">
 */
export const UnitInputField: FunctionComponent<UnitInputFieldProps> = ({
  data,
  ...props
}) => {
  const { input } = useField(props.name);

  return <UnitInput {...props} value={input.value} onChange={input.onChange} />;
};

interface UnitInputProps extends Omit<ITextFieldProps, "value" | "onChange"> {
  unitType: UnitType;
  value: number | "";
  onChange: (value: number | "") => void;
}

export const UnitInput: React.FC<UnitInputProps> = ({
  unitType,
  onChange: parentOnChange,
  onBlur: parentOnBlur,
  value,
  styles,
  ...props
}) => {
  const [valueString, setValueString] = useState<string>(
    convertNumber(value, unitType)
  );

  const previousValue = useRef<number | "">(value);

  useEffect(() => {
    // if value has been changed by parent, update string
    if (value !== previousValue.current) {
      previousValue.current = value;
      setValueString(convertNumber(value, unitType));
    }
  }, [unitType, value]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const str = formatString(event.target.value, unitType);

    setValueString(str);
    const newValue = convertString(str, unitType);
    if (newValue !== "") {
      // if new value is valid, call parent onChange
      previousValue.current = newValue;
      parentOnChange(newValue);
    }
  };

  const onBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = convertString(valueString, unitType);
    // updates input string if required - eg "3:" becomes "3:00"
    setValueString(convertNumber(newValue, unitType));

    // if current value is not valid, call parent onChange
    // as otherwise, form will still have the previous valid value
    if (newValue === "") {
      previousValue.current = newValue;
      parentOnChange(newValue);
    }
    if (parentOnBlur) {
      parentOnBlur(event);
    }
  };

  let suffix = "each";
  if (unitType === "HOURLY") suffix = "hh:mm";
  if (unitType === "KM") suffix = "km";

  return (
    <TextField
      styles={mergeStyleSets(
        {
          suffix: { width: 70, justifyContent: "center" }
        },
        styles
      )}
      suffix={suffix}
      {...props}
      value={valueString}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
