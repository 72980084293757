import { PropsWithChildren } from "react";

import { Link } from "@bps/fluent-ui";
import { downloadFile } from "@libs/utils/file.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

export interface DocumentDownloadLinkProps
  extends PropsWithChildren<{
    patientId: string;
    documentId: string;
  }> {}

export const DocumentDownloadLink: React.FC<DocumentDownloadLinkProps> = ({
  patientId,
  documentId,
  children
}) => {
  const { correspondence } = useStores();

  const getDownloadUri = async (): Promise<string> => {
    const documentUrlResponse = await correspondence.getDocumentUrl({
      patientId,
      documentId
    });
    return documentUrlResponse.url;
  };

  return (
    <DataFetcher<string> fetch={() => getDownloadUri()}>
      {downloadLink => (
        <Link onClick={() => downloadFile(downloadLink)}>{children}</Link>
      )}
    </DataFetcher>
  );
};
