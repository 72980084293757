import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  ButtonsGroupChoice,
  Heading,
  SpinNumberInput,
  Stack
} from "@bps/fluent-ui";
import { Duration } from "@bps/utils";

import { DatePickerField, DatePickerFieldProps } from "./DatePickerField.tsx";

export interface DatePickerOnInFieldProps extends DatePickerFieldProps {
  controlProps: ControlProps;
}

export enum DateRange {
  days = "Days",
  weeks = "Weeks",
  months = "Months",
  years = "Years"
}

export const useDatePickerOnInField = (
  onControlUpdated: (duration: Duration) => void
) => {
  const [datePickerButton, setDatePickerButtonValue] = useState<string>();
  const [datePickerIncrement, setDatePickerIncrementValue] = useState<string>();

  const onDateIncrementPressed = (value: string) => {
    setDatePickerButtonValue(value);
    updateDateFromControl(value, datePickerIncrement);
  };

  const onDateIncrementUpdated = (value: string) => {
    setDatePickerIncrementValue(value);
    updateDateFromControl(datePickerButton, value);
  };

  const updateDateFromControl = (
    dateIncrement?: string,
    dateNumberValue?: string
  ) => {
    if (dateIncrement && dateNumberValue !== undefined) {
      // Parse the values
      const datePickerIncrementValue = Number.parseInt(dateNumberValue);

      switch (dateIncrement) {
        case DateRange.days:
          onControlUpdated({ days: datePickerIncrementValue });
          break;
        case DateRange.weeks:
          onControlUpdated({ weeks: datePickerIncrementValue });
          break;
        case DateRange.months:
          onControlUpdated({ months: datePickerIncrementValue });
          break;
        case DateRange.years:
          onControlUpdated({ years: datePickerIncrementValue });
          break;
      }
    }
  };

  return {
    onDateIncrementUpdated,
    onDateIncrementPressed,
    datePickerButton,
    datePickerIncrement,
    setDatePickerButtonValue,
    setDatePickerIncrementValue,
    updateDateFromControl
  };
};

interface ControlProps {
  onDateIncrementUpdated: (value: string) => void;
  datePickerIncrement: string | undefined;
  datePickerButton: string | undefined;
  onDateIncrementPressed: (value: string) => void;
}

export const DatePickerOnInField: React.FC<DatePickerOnInFieldProps> = observer(
  ({ name, controlProps }) => {
    const {
      onDateIncrementUpdated,
      datePickerIncrement,
      datePickerButton,
      onDateIncrementPressed
    } = controlProps;

    return (
      <DatePickerField
        name={name}
        label="Date"
        onRenderHeader={() => {
          return (
            <Stack
              styles={{
                root: {
                  marginLeft: 8,
                  marginTop: 16,
                  width: "100%"
                }
              }}
            >
              <Heading>On</Heading>
            </Stack>
          );
        }}
        onRenderFooter={() => {
          return (
            <Stack
              styles={{
                root: {
                  margin: 16,
                  marginTop: 0,
                  width: "100%"
                }
              }}
            >
              <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
                <SpinNumberInput
                  label="In"
                  min={1}
                  max={99}
                  styles={{ root: { maxWidth: 90 } }}
                  onChange={(value: string) => {
                    onDateIncrementUpdated(value);
                  }}
                  value={datePickerIncrement}
                  disabled={datePickerButton === undefined}
                />

                <ButtonsGroupChoice
                  onChange={(value: string) => {
                    onDateIncrementPressed(value);
                  }}
                  options={[
                    { key: DateRange.days, text: DateRange.days },
                    { key: DateRange.weeks, text: DateRange.weeks },
                    {
                      key: DateRange.months,
                      text: DateRange.months
                    },
                    { key: DateRange.years, text: DateRange.years }
                  ]}
                  value={datePickerButton}
                />
              </Stack>
            </Stack>
          );
        }}
      />
    );
  }
);
