import {
  Breadcrumb,
  IBreadcrumbItem,
  IBreadcrumbStyles,
  mergeStyleSets,
  Stack
} from "@bps/fluent-ui";

import { Navigate } from "./Navigate.tsx";

export type BreadcrumbProps = {
  routes: IBreadcrumbItem[];
  styles?: Partial<IBreadcrumbStyles>;
};

/**
 * BreadCrumb represents an instance of Fluent UI Breadcrumb.
 * Can navigate via Link (to as props), if not specified, through onClick function
 */

export const BreadCrumb: React.FunctionComponent<BreadcrumbProps> = ({
  routes,
  styles
}) => {
  const getCustomDivider = () => (
    <span className="breadcrumb-divider" style={{ padding: "0 5px" }}>
      /
    </span>
  );

  const renderLink = (item: IBreadcrumbItem | undefined) => {
    if (!item) return null;

    const { isCurrentItem, text, ...linkProps } = item;

    return isCurrentItem ? (
      <Stack className="breadcrumb-current">{text}</Stack>
    ) : (
      <Navigate
        {...linkProps}
        onClick={() => {
          !item.href && item.onClick && item.onClick();
        }}
      >
        {text}
      </Navigate>
    );
  };
  return (
    <Breadcrumb
      items={routes}
      onRenderItem={renderLink}
      dividerAs={getCustomDivider}
      styles={({ theme }) =>
        mergeStyleSets(
          {
            itemLink: {
              fontSize: theme.fonts.medium.fontSize,
              padding: 0
            }
          },
          styles
        )
      }
    />
  );
};
