import { action, observable } from "mobx";

export class CardFormLayoutHelper {
  @observable
  selectedMenuItemId: string | undefined;

  @action
  setSelectedMenuItemId = (id: string | undefined) => {
    this.selectedMenuItemId = id;
  };
}
