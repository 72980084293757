import { FunctionComponent } from "react";

import { useFieldContext, useFieldRegister } from "@bps/fluent-ui";

import { BookableUsersSelect } from "../../selects/BookableUsersSelect.tsx";
import { UsersSelectFilterItemProps } from "./UsersSelectFilterItem.tsx";

export const BookableUsersSelectFilterItem: FunctionComponent<
  UsersSelectFilterItemProps
> = ({ name, ...usersSelectProps }) => {
  useFieldRegister(name);
  const { value, setFieldValue, focusField, blurField } = useFieldContext<
    string | undefined | string[]
  >(name);

  return (
    <BookableUsersSelect
      {...usersSelectProps}
      onFocus={focusField}
      onBlur={blurField}
      selectedKeys={value as any}
      onChangeSelectedKeys={setFieldValue}
    />
  );
};
