import { email } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { EmailConfirmationModalValues } from "./EmailConfirmationModal.types.ts";

export class EmailConfirmationModalValidator extends Validator<EmailConfirmationModalValues> {
  constructor() {
    super();
    this.forField("email", [email()]);
  }
}
