import { observer, Observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { useStores } from "@stores/hooks/useStores.ts";

const RenderChildrenComponent: FunctionComponent<{
  children: React.ReactNode;
}> = props => <>{props.children}</>;

export interface WithPermissionProps {
  permission?: string[] | string;
  businessRoleClass?: string[] | string;
  permissionOperator?: "and" | "or";
  businessRoleClassOperator?: "and" | "or";
  else?: React.ReactNode;
}

/**
 * Higher order component that extends the component with a permission prop.
 * When the user doesn't have this permission, it will not render the component.
 * Note that there is currently a problem when trying to use this on Route
 * For some reason yet unclear, it will cause React to unmount and remount the component every time.
 * @param Component
 */
export const withPermission = <P extends object>(
  Component: React.ComponentType<P>
) => {
  type InnerProps = P & WithPermissionProps;

  const WithPermission = ({
    permission,
    businessRoleClass,
    permissionOperator = "and",
    businessRoleClassOperator = "and",
    ...props
  }: InnerProps) => {
    const { core } = useStores();
    return (
      <Observer>
        {() => {
          if (
            (!permission ||
              core.hasPermissions(permission, permissionOperator)) &&
            (!businessRoleClass ||
              core.hasBusinessRoleClasses(
                businessRoleClass,
                businessRoleClassOperator
              ))
          ) {
            return <Component {...(props as P)} />;
          }

          if (props.else) {
            return <>{props.else}</>;
          }

          return null;
        }}
      </Observer>
    );
  };
  return WithPermission;
};

export const When = withPermission(RenderChildrenComponent);

/***
 * Creates an higher order component that only renders
 * the given component if the user has the given set or permissions
 */
export const whenPermission = <P extends object>(
  permissions: string[] | string,
  Component: React.ComponentType<P>
) => {
  return observer((props: P) => {
    const { core } = useStores();
    const { ...otherProps } = props;
    if (core.hasPermissions(permissions)) {
      return <Component {...(otherProps as any)} />;
    }
    return null;
  });
};
