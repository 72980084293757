import {
  OutboundCommChannel,
  OutboundCommType
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import {
  CommunicationType,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { Contact } from "@stores/practice/models/Contact.ts";

import { EmailConfirmationModalValues } from "../EmailConfirmationModal.types.ts";

export class EmailConfirmationModalHelper {
  constructor(
    private root: IRootStore,
    private accountContactId: string
  ) {}

  get practice() {
    return this.root.practice;
  }

  get comms() {
    return this.root.comms;
  }

  onSubmit = async (values: EmailConfirmationModalValues) => {
    const contact = await this.practice.getContact(this.accountContactId);

    if (contact.isOrganisation) return;

    // If email is inputted freeform via the SingleChoiceComboBoxField
    const emailExists = contact.communications.some(
      comm =>
        comm.type === CommunicationType.Email && comm.value === values.email
    );

    if (!emailExists) {
      const communications = [
        ...contact.communications,
        {
          type: CommunicationType.Email,
          value: values.email
        }
      ];

      const patchRequest = {
        id: contact.id,
        communications
      };

      if (contact.type === ContactType.Patient) {
        await this.practice.updatePatient(patchRequest);
      } else if (contact.type === ContactType.Individual) {
        await this.practice.updateIndividual(patchRequest);
      }
    }

    if (values.isPreferredEmail) {
      const contactPreferences = contact.contactPreferences;

      const updatedPreferences = [
        ...(contactPreferences?.commTypePreferences ?? []),
        {
          commTypeCode: OutboundCommType.Invoice, // Note: Receipt emails are also sent to the preferred Invoice email
          preferredCommChannelTypeCode: OutboundCommChannel.Email,
          preferredCommAddressValue: values.email
        }
      ];

      if (contactPreferences) {
        await this.comms.patchContactPreferences({
          commTypePreferences: updatedPreferences,
          id: contact.id,
          eTag: contactPreferences.eTag
        });
      } else {
        await this.comms.addContactPreferences({
          commTypePreferences: updatedPreferences,
          id: contact.id
        });
      }
    }

    return;
  };

  static shouldShowModal = (
    accountContact: Contact,
    commsPreferences?: ContactPreferences
  ): boolean => {
    const hasInvoiceEmailPreference =
      commsPreferences?.commTypePreferences?.find(
        preference =>
          preference.commTypeCode === OutboundCommType.Invoice &&
          preference.preferredCommChannelTypeCode === OutboundCommChannel.Email
      );

    if (accountContact.isPerson && !hasInvoiceEmailPreference) return true;

    if (accountContact.type === ContactType.Organisation) {
      const emails = accountContact.communications.filter(
        comm => comm.type === CommunicationType.Email
      );
      // If only one email available, don't prompt and use it to send the email
      if (emails.length !== 1) return true;
    }

    return false;
  };
}
