import { FieldItemError } from "@bps/fluent-ui";
import { DropzoneSection } from "@ui-components/drop-zone-section/DropzoneSection.tsx";

import { FieldArray } from "../FieldArray.tsx";
import { getFieldErrorMessage, renderComponent } from "../FinalForm.tsx";
import {
  FileFieldValue,
  FilePickerFieldProps
} from "./FilePickerField.types.ts";

const isFileEqual = (a: any, b: any) => a.name === b.name;

export const FilePickerField = <T extends FileFieldValue>(
  props: FilePickerFieldProps<T>
) => {
  const {
    name,
    getFileFields,
    onDropAccepted: onDropAcceptedProps,
    component,
    children,
    render,
    maxFiles,
    ...dropzoneSectionProps
  } = props;

  return (
    <FieldArray<T, HTMLElement> isEqual={isFileEqual} name={name}>
      {renderProps => {
        const errorMessage = getFieldErrorMessage(renderProps.meta);
        const handleDropAccepted = async (files: []) => {
          const newValues: T[] = getFileFields(files);

          const index = renderProps.fields.length || 0;

          for (const value of newValues) {
            renderProps.fields.push(value);
          }

          if (onDropAcceptedProps) {
            onDropAcceptedProps(files, index);
          }
        };

        let showDropZone = true;
        if (maxFiles && renderProps.fields.length) {
          showDropZone = renderProps.fields.length < maxFiles;
        }

        return (
          <>
            {showDropZone && (
              <DropzoneSection
                hasError={!!errorMessage}
                onDropAccepted={handleDropAccepted}
                {...dropzoneSectionProps}
              />
            )}

            {renderComponent(
              { render, children, component, ...renderProps },
              {}
            )}
            {errorMessage && (
              <FieldItemError name="file-picker" errorMessage={errorMessage} />
            )}
          </>
        );
      }}
    </FieldArray>
  );
};
