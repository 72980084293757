import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  DatePickerProps,
  ICalendarProps,
  ITextFieldProps
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { DatePicker } from "@ui-components/pickers/DatePicker.tsx";

import { ExposedFieldProps, getFieldErrorMessage } from "./FinalForm.tsx";
import { ReadOnlyTextField } from "./read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

type HtmlElementType = HTMLElement;

type DatePickerInternalProps = Pick<
  ITextFieldProps,
  | "required"
  | "label"
  | "styles"
  | "disabled"
  | "autoFocus"
  | "placeholder"
  | "id"
> &
  Omit<
    ICalendarProps,
    | "defaultValue"
    | "name"
    | "onChange"
    | "value"
    | "strings"
    | "onSelectDate"
    | "styles"
  > &
  Pick<
    DatePickerProps,
    | "allowClear"
    | "adjustWidthAsPlaceholder"
    | "readOnlyInFormat"
    | "onRenderFooter"
    | "onRenderHeader"
  > & {
    validateOnInitialize?: boolean;
  };

export type DatePickerFieldProps = DatePickerInternalProps &
  Omit<
    ExposedFieldProps<DatePickerProps["value"], HtmlElementType>,
    "parse" | "format" | "defaultValue"
  > & { textFieldComponentRef?: ITextFieldProps["componentRef"] };

type DatePickerAdapterProps = Omit<
  DatePickerProps,
  "textFieldProps" | "onMonthChanged" | "onChange"
> &
  FieldRenderProps<DatePickerProps["value"], HtmlElementType> &
  DatePickerInternalProps;

const DatePickerAdapter: React.FunctionComponent<
  DatePickerAdapterProps
> = props => {
  const {
    input,
    meta,
    allowClear,
    styles,
    label,
    required,
    placeholder,
    disabled,
    autoFocus,
    onMonthChanged,
    adjustWidthAsPlaceholder,
    className,
    validateOnInitialize,
    id,
    readOnlyInFormat,
    readOnly,
    textFieldComponentRef,
    onRenderHeader,
    onRenderFooter,
    ...calendarProps
  } = props;

  const { onChange, value, onBlur, onFocus } = input;

  const { meta: formMeta } = useField(input.name);

  if (readOnly || formMeta?.data?.readOnly) {
    return (
      <ReadOnlyTextField
        value={DateTime.fromJSDate(value)?.toDayDefaultFormat()}
        label={label}
      />
    );
  }

  return (
    <DatePicker
      id={id}
      value={value}
      onChange={onChange}
      calendarProps={{ ...calendarProps }}
      allowClear={allowClear}
      name={input.name}
      adjustWidthAsPlaceholder={adjustWidthAsPlaceholder}
      readOnlyInFormat={readOnlyInFormat}
      textFieldProps={{
        componentRef: textFieldComponentRef,
        className,
        autoFocus,
        disabled: disabled || formMeta?.data?.disabled,
        placeholder,
        label,
        required,
        styles,
        errorMessage: getFieldErrorMessage(meta, validateOnInitialize),
        onFocus,
        onBlur,
        autoComplete: "one-time-code"
      }}
      onRenderFooter={onRenderFooter}
      onRenderHeader={onRenderHeader}
    />
  );
};

const isDateEqual = (a: Date | undefined, b: Date | undefined) => {
  return (
    a === b ||
    (!!a && !!b && DateTime.jsDateToISODate(a) === DateTime.jsDateToISODate(b))
  );
};

export const DatePickerField: React.FunctionComponent<
  DatePickerFieldProps
> = props => (
  <Field
    {...props}
    isEqual={props.isEqual || isDateEqual}
    component={DatePickerAdapter}
  />
);
