import { FC, FunctionComponent, useRef } from "react";
import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  getId,
  ITag,
  StaticPicker,
  StaticPickerProps,
  useTheme
} from "@bps/fluent-ui";
import { identity } from "@libs/utils/utils.ts";

import { ExposedFieldProps, getFieldErrorMessage } from "./FinalForm.tsx";
import { FormItemField, FormItemFieldProps } from "./FormItemField.tsx";
import { ReadOnlyTextField } from "./read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";
import { getNewPickerStyles } from "./utils.ts";

type HtmlElementType = any;

export type StaticPickerAdapterProps = StaticPickerProps &
  Pick<FormItemFieldProps, "required" | "label"> & {
    fieldItemStyles?: FormItemFieldProps["styles"];
  };

export type StaticPickerFieldProps = ExposedFieldProps<
  StaticPickerProps["selectedKey"],
  HtmlElementType
> &
  StaticPickerAdapterProps & { horizontalLabel?: boolean };

type InternalStaticPickerAdapterProps = FieldRenderProps<
  StaticPickerProps["selectedKey"],
  HtmlElementType
> &
  StaticPickerAdapterProps & { readOnly?: boolean };

const StaticPickerAdapter: FunctionComponent<
  InternalStaticPickerAdapterProps
> = props => {
  const _id = useRef(getId(props.input.name));
  const theme = useTheme();

  const {
    meta,
    input,
    label,
    styles,
    loading,
    required,
    readOnly,
    disabled,
    inputProps,
    fieldItemStyles,
    horizontalLabel,
    ...pickerProps
  } = props;

  const { meta: formMeta } = useField(input.name);

  if (readOnly || formMeta?.data?.readOnly) {
    const getValueText = async () => {
      const options = await pickerProps.fetchDataSource();
      return options.find(x => x.key === input.value)?.name;
    };
    return (
      <ReadOnlyTextField
        value={input.value}
        getValueText={getValueText}
        label={label}
      />
    );
  }

  const hasErrorMessage = !!getFieldErrorMessage(meta);

  const onChange = (value: string | undefined, item: ITag) => {
    input.onChange(value);
    props.onChange && props.onChange(value, item);
    input.onFocus();
  };

  const _disabled = disabled || formMeta?.data?.disabled;

  return (
    <FormItemField
      htmlFor={_id.current}
      name={input.name}
      required={required}
      label={label}
      styles={fieldItemStyles}
      disabled={_disabled}
      {...dataAttribute(DataAttributes.Loading, !!loading)}
      horizontalLabel={horizontalLabel}
    >
      <StaticPicker
        {...pickerProps}
        disabled={_disabled}
        onChange={onChange}
        selectedKey={input.value}
        inputProps={{
          ...inputProps,
          onBlur: input.onBlur,
          onFocus: input.onFocus,
          id: _id.current,
          autoComplete: "one-time-code"
        }}
        styles={getNewPickerStyles(
          theme,
          { hasErrorMessage, disabled: _disabled },
          styles
        )}
      />
    </FormItemField>
  );
};

/**
 * Use StaticPickerField to use StaticPicker with final-form.
 * It also wraps the picker in a FormItemField to support label and error message.
 * @param props
 */
export const StaticPickerField: FC<StaticPickerFieldProps> = props => (
  <Field
    format={identity}
    parse={identity}
    {...props}
    component={StaticPickerAdapter}
  />
);
