import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import {
  Nav as FluentNav,
  FontSizes,
  FontWeights,
  INavLink,
  INavLinkGroup,
  INavProps,
  INavStyles,
  ITheme,
  mergeStyleSets
} from "@bps/fluent-ui";
import { RouteInfo } from "@libs/routing/routes.ts";
import { RoutePermissionOptions } from "@libs/routing/types/route-permission-options.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  RouteDefinition,
  useRouteDefinitionsFilter
} from "@stores/router/hooks/useRouteDefinitionsFilter.ts";
import {
  NavButton,
  NavButtonProps
} from "@ui-components/navigation/NavButton.tsx";

import { NavLinkWrapper } from "./NavLinkWrapper.tsx";

export interface NavLink
  extends Omit<INavLink, "url" | "links">,
    Pick<RouteDefinition, "tenantLocation">,
    RoutePermissionOptions {
  name: string;
  /*Will default permission and routing from the path provided*/
  path?: RouteInfo;
  url?: string;
  links?: NavLink[];
  withDivider?: boolean;
}

export interface NavLinkBase extends INavLink {
  withDivider?: boolean;
}

export interface NavBarProps
  extends Omit<NavBarBaseProps, "groups">,
    PropsWithChildren {
  links: NavLink[];
  renderAsButton?: boolean;
  buttonProps?: Omit<NavButtonProps, "renderNav">;
}

interface NavGroupBase extends Omit<INavLinkGroup, "links"> {
  links: NavLinkBase[];
}

export interface NavBarBaseProps extends Omit<INavProps, "groups"> {
  groups: NavGroupBase[];
}

export const Nav: React.FC<NavBarProps> = ({
  links,
  selectedKey,
  renderAsButton,
  buttonProps,
  children,
  styles
}) => {
  const { routing } = useStores();
  const routeFilter = useRouteDefinitionsFilter();

  const isNestedNav = links.some((x: NavLink) => x.links);

  const filterLinks = (links: NavLink[]) => {
    const filteredLinks: NavLink[] = [];

    links.forEach((link: NavLink) => {
      //filter all child links by permissions
      if (link.links?.length) {
        const filteredChildLinks = filterLinks(link.links);

        //only show the parent link if at least one child is displayed
        if (filteredChildLinks.length) {
          filteredLinks.push({ ...link, links: filteredChildLinks });
          return;
        }
        return;
      }

      const path = link.path;
      let linkToFilter: NavLink = {
        ...link,
        permissionsOperator: link.permissionsOperator ?? "and"
      };
      if (path) {
        //apply default props from path
        linkToFilter = {
          key: path.pattern,
          permissions: path.permissions,
          permissionsOperator: path.permissionsOperator,
          onClick: () => {
            routing.push(path.pattern);
          },
          ...link
        };
      }
      //Show parent link if child links are available.
      if (linkToFilter.links?.length || routeFilter(linkToFilter)) {
        filteredLinks.push(linkToFilter);
      }
    });
    return filteredLinks;
  };

  const filteredLinks = filterLinks(links);
  if (!filteredLinks.length) {
    return null;
  }

  const mapToINavLinks = (
    navBarButtonLinks: NavLink[],
    onLinkClicked?: () => void
  ): INavLink[] => {
    return navBarButtonLinks.map((navBarButtonLink: NavLink) => {
      return {
        ...navBarButtonLink,
        url: navBarButtonLink.url || "",
        links: navBarButtonLink.links
          ? mapToINavLinks(navBarButtonLink.links, onLinkClicked)
          : undefined,
        onClick: () => {
          navBarButtonLink.onClick && navBarButtonLink.onClick();
          if (!navBarButtonLink.links && onLinkClicked) {
            onLinkClicked();
          }
        }
      };
    });
  };

  const renderNav = (onLinkClicked?: () => void) => (
    <NavBase
      focusZoneProps={{
        allowFocusRoot: false
      }}
      styles={mergeStyleSets(
        !isNestedNav
          ? {
              link: { height: 36, lineHeight: 36 },
              linkText: { paddingLeft: 3 }
            }
          : undefined,
        styles
      )}
      selectedKey={selectedKey}
      groups={[
        {
          links: mapToINavLinks(filteredLinks, onLinkClicked)
        }
      ]}
    />
  );

  if (renderAsButton) {
    return (
      <NavButton {...buttonProps} renderNav={renderNav}>
        {children}
      </NavButton>
    );
  }

  return renderNav();
};

const NavBase: React.FC<NavBarBaseProps> = observer(props => {
  const { groups, styles, ...rest } = props;
  const { routing } = useStores();

  const getNavStyles = (theme: ITheme): Partial<INavStyles> => ({
    group: {
      ".ms-Nav-chevronButton": { fontSize: FontSizes.size24 }
    },
    groupContent: { marginBottom: 0 },
    root: {
      width: 208,
      background: theme.palette.white,
      boxShadow: theme.effects.elevation4
    },
    navItem: {
      ".ms-Nav-navItems": {
        background: theme.palette.neutralLighterAlt
      }
    },
    compositeLink: {
      "&.is-expanded": {
        ".ms-Nav-linkText": { fontWeight: FontWeights.semibold }
      }
    },
    link: {
      border: "none",
      borderRadius: "none",
      background: "transparent",
      color: theme.palette.neutralPrimary,
      padding: 0,
      "&:hover": { color: "initial" }
    },
    linkText: { marginLeft: 5, paddingLeft: 27, paddingRight: 20 }
  });

  const selectedKey: string =
    routing.location.pathname + routing.location.search;

  const renderLink = (
    props: NavLinkBase,
    defaultRender:
      | ((props?: NavLinkBase | undefined) => JSX.Element | null)
      | undefined
  ) => {
    if (!props || !defaultRender) {
      return null;
    }
    return <NavLinkWrapper {...props}>{defaultRender(props)}</NavLinkWrapper>;
  };

  return (
    <FluentNav
      onRenderLink={renderLink}
      styles={({ theme }) => mergeStyleSets(getNavStyles(theme), styles)}
      groups={groups}
      {...rest}
      selectedKey={selectedKey}
    />
  );
});
