import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useField, useForm } from "react-final-form";

import {
  ButtonsGroupChoice,
  MessageBar,
  MessageBarType,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import {
  AutofillDialogPurposeType,
  SubmitActionCode,
  TemplateVisibility
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { AutofillDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextEditorField } from "@ui-components/form/TextEditorField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  autofillNameOf,
  AutofillPivotKey,
  getVisibilityText
} from "./autofill-quick-add-edit-dialog.utils.ts";
import { getDisableStyles } from "./AutofillQuickAddEdit.styles.ts";
import { AutofillQuickAddValues } from "./AutofillQuickAddEditDialog.types.ts";

interface AutofillQuickAddFormProps {
  autofillDialogPurpose: AutofillDialogPurposeType;
  onPurposeChange: () => void;
  isComplex: boolean;
}
export const AutofillQuickAddForm: FunctionComponent<AutofillQuickAddFormProps> =
  observer(({ autofillDialogPurpose, onPurposeChange, isComplex }) => {
    const root = useStores();
    const { document, userExperience } = root;
    const { ui } = userExperience;
    const { setEditingAutoFill } = ui;

    const autofills = Array.from(document.autofills.values());

    const theme = useTheme();
    const form = useForm<AutofillQuickAddValues>();

    const {
      input: { value: updatingAutofill }
    } = useField(autofillNameOf("updatingAutofill"), {
      subscription: { value: true }
    });

    const {
      input: { value: availability }
    } = useField(autofillNameOf("availability"), {
      subscription: { value: true }
    });

    const selectedAutoFill = autofills.find(x => x.id === updatingAutofill);

    const isUpdate = autofillDialogPurpose === AutofillDialogPurposeType.UPDATE;

    const isSharedInitially =
      selectedAutoFill?.documentStatus ===
      SubmitActionCode.PublishToEveryoneCode;

    const isUpdatingToShare =
      availability === SubmitActionCode.PublishToEveryoneCode;

    const isEditorReadonly = isComplex && isUpdate;

    const autofillOptions = autofills.map(x => {
      const documentStatusText =
        x.documentStatus === SubmitActionCode.PublishToEveryoneCode
          ? AutofillPivotKey.Shared
          : AutofillPivotKey.Private;

      const option: KeyTextValue = {
        key: x.id,
        text: `${x.shortcut} — ${x.name} ( ${documentStatusText} )`
      };
      return option;
    });

    const switchToNew = () => {
      setEditingAutoFill(undefined);
      form.batch(() => {
        form.change(autofillNameOf("name"), undefined);
        form.change(autofillNameOf("shortcut"), undefined);
        form.change(autofillNameOf("availability"), undefined);
        form.change(autofillNameOf("visibility"), undefined);
        form.change(autofillNameOf("content"), undefined);
      });
    };

    const switchToUpdate = async (selectedAutoFill: AutofillDto) => {
      setEditingAutoFill(selectedAutoFill);
      const text = await document.renderTemplate(selectedAutoFill.id, {
        contentType: "html",
        skipMerge: true
      });
      form.batch(() => {
        form.change(autofillNameOf("content"), text.content);
        form.change(autofillNameOf("name"), selectedAutoFill.name);
        form.change(autofillNameOf("shortcut"), selectedAutoFill.shortcut);
        form.change(
          autofillNameOf("availability"),
          selectedAutoFill.documentStatus
        );
        form.change(
          autofillNameOf("visibility"),
          getVisibilityText(
            selectedAutoFill.isClinical,
            selectedAutoFill.isAdmin
          )
        );
      });
    };

    return (
      <Stack
        styles={{
          root: {
            minWidth: 600
          }
        }}
        tokens={{
          childrenGap: 8
        }}
      >
        <Fieldset horizontal>
          <ButtonsGroupChoice
            notUnselectable
            options={[
              {
                key: AutofillDialogPurposeType.NEW,
                text: "New"
              },
              {
                key: AutofillDialogPurposeType.UPDATE,
                text: "Update existing"
              }
            ]}
            value={autofillDialogPurpose}
            onChange={async value => {
              onPurposeChange();
              if (value === AutofillDialogPurposeType.UPDATE) {
                if (selectedAutoFill) {
                  await switchToUpdate(selectedAutoFill);
                }
              } else {
                switchToNew();
                form.restart();
              }
            }}
          />
          {isUpdate && (
            <Stack>
              <DropdownField
                name={autofillNameOf("updatingAutofill")}
                options={autofillOptions}
                fieldItemStyles={{
                  root: {
                    width: 395
                  }
                }}
                calloutProps={{
                  calloutMaxHeight: 500
                }}
              />
              <FieldSpy
                name={autofillNameOf("updatingAutofill")}
                onChange={async value => {
                  if (isUpdate && value && selectedAutoFill) {
                    await switchToUpdate(selectedAutoFill);
                  }
                }}
              />
            </Stack>
          )}
        </Fieldset>
        <Stack
          styles={{
            root: {
              display: "grid",
              gridGap: "8px",
              gridTemplateColumns: "1fr 1fr"
            }
          }}
        >
          <TextInputField
            name={autofillNameOf("name")}
            label="Name"
            required={true}
          />
          <TextInputField
            validateOnInitialize={
              (isUpdate && isSharedInitially) || isUpdatingToShare
            }
            name={autofillNameOf("shortcut")}
            label="Keyboard shortcut"
            required={true}
          />
          <ButtonsGroupSingleChoiceField
            label="Availability"
            name={autofillNameOf("availability")}
            required
            options={[
              {
                key: SubmitActionCode.PublishToSelfCode,
                text: AutofillPivotKey.Private
              },
              {
                key: SubmitActionCode.PublishToEveryoneCode,
                text: AutofillPivotKey.Shared
              }
            ]}
            disabled={isUpdate && isSharedInitially}
          />
          <ButtonsGroupSingleChoiceField
            name={autofillNameOf("visibility")}
            label="Visibility"
            required
            options={[
              {
                key: TemplateVisibility.Clinical,
                text: "Clinical"
              },
              {
                key: TemplateVisibility.Admin,
                text: "Admin"
              },
              {
                key: TemplateVisibility.Both,
                text: "Both"
              }
            ]}
          />
        </Stack>

        <TextEditorField
          name={autofillNameOf("content")}
          label="Text"
          required
          readOnly={isEditorReadonly}
          className={getDisableStyles(theme, isEditorReadonly)}
        />
        {(isEditorReadonly || (isUpdate && isSharedInitially)) && (
          <MessageBar messageBarType={MessageBarType.info}>
            <Stack>
              {isSharedInitially && (
                <span>
                  This is a shared autofill, update will create a private copy.
                </span>
              )}
              {isEditorReadonly && (
                <span>
                  This autofill contains over 2000 char., merge fields, tables,
                  or pictures. Use text editor to update
                </span>
              )}
            </Stack>
          </MessageBar>
        )}
      </Stack>
    );
  });
