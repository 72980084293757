import { observer } from "mobx-react-lite";

import {
  CommandBarButton,
  dataAttribute,
  DataAttributes,
  IButtonProps,
  IButtonStyles,
  IContextualMenuProps,
  mergeStyleSets,
  useTheme
} from "@bps/fluent-ui";

import { getNavBarStylesSet } from "./NavBar.styles.ts";

export interface NavButtonProps
  extends Pick<IContextualMenuProps, "gapSpace">,
    Pick<IButtonProps, "text" | "iconProps">,
    Omit<IButtonProps, "styles"> {
  hideChevron?: boolean;
  buttonStyles?: IButtonStyles;
  dataAttributeElementName?: string;
  renderNav: (onLinkClicked: () => void) => React.ReactNode;
}

export const NavButton: React.FC<NavButtonProps> = observer(
  ({
    renderNav,
    text,
    children,
    iconProps,
    buttonStyles,
    gapSpace,
    dataAttributeElementName,
    ...props
  }) => {
    const theme = useTheme();

    const { getCommandBarButtonStyle } = getNavBarStylesSet(theme);
    return (
      <CommandBarButton
        iconProps={iconProps}
        styles={mergeStyleSets(getCommandBarButtonStyle(), buttonStyles)}
        text={text}
        menuProps={{
          gapSpace: gapSpace || 0,
          items: [
            {
              key: "NavKey",
              onRender: (_, dismissMenu) => renderNav(dismissMenu)
            }
          ]
        }}
        {...props}
        {...dataAttribute(DataAttributes.Element, dataAttributeElementName)}
      >
        {children}
      </CommandBarButton>
    );
  }
);
