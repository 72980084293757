import { FunctionComponent, memo, ReactNode } from "react";
import { useFormState } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IconButton,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { defaultConfirm } from "@ui-components/form/submission-form-dialog/defaultConfirm.util.ts";

interface SubmissionFormDialogHeaderProps {
  onCancel: () => void;
  title: string | ReactNode;
  headingId?: string;
  dataTestAttr?: string;
}

export const SubmissionFormDialogHeader: FunctionComponent<SubmissionFormDialogHeaderProps> =
  memo(({ onCancel, title, dataTestAttr }) => {
    const { dirty } = useFormState();
    const theme = useTheme();

    const handleDialogClosing = () => {
      defaultConfirm(dirty, onCancel, onCancel);
    };
    return (
      <Stack
        {...(dataTestAttr
          ? dataAttribute(DataAttributes.Data, `${dataTestAttr}`)
          : undefined)}
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        styles={() => ({
          root: {
            padding: theme.spacing.l1
          }
        })}
      >
        <Heading variant="modal-heading">{title}</Heading>
        <IconButton
          iconProps={{ iconName: "Cancel" }}
          onClick={handleDialogClosing}
          styles={{ icon: { color: theme.palette.neutralPrimary } }}
        />
      </Stack>
    );
  });
