import { Field, FieldRenderProps } from "react-final-form";

import { TimePicker, TimePickerProps } from "@bps/fluent-ui";

import { ExposedFieldProps, getFieldErrorMessage } from "./FinalForm.tsx";

type HtmlElementType = any;

export type TimePickerFieldProps = Omit<TimePickerProps, "onChange" | "value"> &
  ExposedFieldProps<TimePickerProps["value"], HtmlElementType> &
  Omit<React.HTMLAttributes<HTMLElement>, "defaultChecked" | "defaultValue">;

const TimePickerFieldAdapter = (
  props: FieldRenderProps<TimePickerProps["value"], HtmlElementType> &
    TimePickerProps
) => {
  const { input, meta, styles, ...timePickerProps } = props;
  return (
    <TimePicker
      {...input}
      {...timePickerProps}
      styles={styles}
      errorMessage={getFieldErrorMessage(meta)}
    />
  );
};

/**
 * Adapter to use TimePicker with final-form.
 * @param props
 */
export const TimePickerField: React.FC<TimePickerFieldProps> = props => (
  <Field {...props} component={TimePickerFieldAdapter} />
);
