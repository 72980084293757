import { useCallback, useState } from "react";

import {
  Heading,
  IconButton,
  ITextFieldProps,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";

interface PdfViewerOptionsToolbar {
  showTitle?: boolean;
  handleDismiss?: () => void;
  setScale: (value: string) => void;
  maxPageNumber: number;
  documentTitle: string;
}

export const PDFViewerOptionsToolbar: React.FC<PdfViewerOptionsToolbar> = ({
  showTitle,
  handleDismiss,
  setScale,
  maxPageNumber,
  documentTitle
}) => {
  const [currentPage, setCurrentPage] = useState<string>("1");
  const [zoom, setZoom] = useState<string>("100");
  const theme = useTheme();
  const pageNumberWidth = maxPageNumber < 10 ? 25 : 40;

  const onPageChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || parseInt(newValue) <= maxPageNumber) {
        setCurrentPage(newValue || "");
        if (newValue) {
          document
            .getElementById(`pdf-canvas-${newValue.toString()}`)
            ?.scrollIntoView();
        }
      } else {
        setCurrentPage("1");
        document.getElementById("pdf-canvas-1")?.scrollIntoView();
      }
    },
    [maxPageNumber]
  );

  const zoomValues = [
    "25",
    "33",
    "50",
    "67",
    "75",
    "80",
    "90",
    "100",
    "110",
    "125",
    "150",
    "175",
    "200",
    "250",
    "300",
    "400",
    "500"
  ];

  const getNearestZoomStep = (value: number, increasing: boolean) => {
    let nearestStep: number = 100;
    if (value <= 29) {
      nearestStep = 25;
    } else if (value > 29 && value <= 42) {
      nearestStep = 33;
    } else if (value > 42 && value <= 59) {
      nearestStep = 50;
    } else if (value > 59 && value <= 71) {
      nearestStep = 67;
    } else if (value > 71 && value <= 78) {
      nearestStep = 75;
    } else if (value > 78 && value <= 85) {
      nearestStep = 80;
    } else if (value > 85 && value <= 95) {
      nearestStep = 90;
    } else if (value > 95 && value <= 105) {
      nearestStep = 100;
    } else if (value > 105 && value <= 118) {
      nearestStep = 110;
    } else if (value > 118 && value <= 138) {
      nearestStep = 125;
    } else if (value > 138 && value <= 163) {
      nearestStep = 150;
    } else if (value > 163 && value <= 188) {
      nearestStep = 175;
    } else if (value > 188 && value <= 225) {
      nearestStep = 200;
    } else if (value > 225 && value <= 275) {
      nearestStep = 250;
    } else if (value > 275 && value <= 350) {
      nearestStep = 300;
    } else if (value > 350 && value <= 450) {
      nearestStep = 400;
    } else {
      nearestStep = 500;
    }

    if (increasing) {
      if (nearestStep <= value) {
        const zoomIndex = zoomValues.indexOf(zoom);
        setZoom(zoomValues[zoomIndex + 1]);
        setScale(zoomValues[zoomIndex + 1]);
      } else if (nearestStep > value) {
        setZoom(nearestStep.toString());
        setScale(nearestStep.toString());
      }
    } else {
      if (nearestStep >= value) {
        const zoomIndex = zoomValues.indexOf(zoom);
        setZoom(zoomValues[zoomIndex - 1]);
        setScale(zoomValues[zoomIndex - 1]);
      } else if (nearestStep < value) {
        setZoom(nearestStep.toString());
        setScale(nearestStep.toString());
      }
    }
  };

  const handleZoomIncrease = () => {
    const parsedValue = parseInt(zoom);
    if (parsedValue && parsedValue < 500) {
      getNearestZoomStep(parsedValue, true);
    }
  };

  const handleZoomDecrease = () => {
    const parsedValue = parseInt(zoom);
    if (parsedValue && parsedValue > 25) {
      getNearestZoomStep(parsedValue, false);
    }
  };

  const onZoomChange: ITextFieldProps["onChange"] = (ev, value) => {
    if (!value || parseInt(value) <= 25) {
      setZoom(value || "");
      setScale("25");
    } else if (parseInt(value) <= 500) {
      setZoom(value);
      setScale(value);
    } else {
      setZoom("500");
      setScale("500");
    }
  };

  const onZoomBlur: ITextFieldProps["onBlur"] = () => {
    if (parseInt(zoom) < 25 || zoom === "") {
      setZoom("25");
    }
  };
  return (
    <Stack
      horizontal
      verticalAlign="center"
      horizontalAlign={showTitle && handleDismiss ? "space-between" : "center"}
      styles={{
        root: {
          width: "100%",
          padding: 24
        }
      }}
    >
      {showTitle && (
        <Stack styles={{ root: { width: "30%" } }}>
          <Heading
            variant="modal-heading"
            styles={{
              root: {
                overflow: "hidden",
                whiteSpace: "nowrap"
              }
            }}
          >
            {documentTitle}
          </Heading>
        </Stack>
      )}
      <Stack
        horizontal
        horizontalAlign="center"
        styles={{ root: { width: "40%" } }}
      >
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 12 }}
          horizontalAlign="end"
          styles={{ root: { width: 160, paddingRight: 8 } }}
        >
          <Text>Page</Text>
          {maxPageNumber === 1 ? (
            <Text>{maxPageNumber}</Text>
          ) : (
            <TextField
              value={currentPage}
              styles={{ root: { width: pageNumberWidth } }}
              onChange={onPageChange}
            />
          )}

          <Text>/</Text>
          <Text>{maxPageNumber}</Text>
        </Stack>
        <Stack horizontalAlign="center">
          <Separator
            vertical={true}
            styles={{
              root: { paddingLeft: "16px", paddingRight: "16px" }
            }}
          />
        </Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 8 }}
          horizontalAlign="start"
          styles={{ root: { width: 160 } }}
        >
          <TooltipHost content="Zoom out">
            <IconButton
              iconProps={{ iconName: "CalculatorSubtract" }}
              onClick={handleZoomDecrease}
              disabled={parseInt(zoom) <= 25}
            />
          </TooltipHost>
          <TextField
            value={zoom}
            suffix="%"
            styles={{ root: { width: 74 } }}
            onChange={onZoomChange}
            onBlur={onZoomBlur}
          />
          <TooltipHost content="Zoom in">
            <IconButton
              iconProps={{ iconName: "Add" }}
              onClick={handleZoomIncrease}
              disabled={parseInt(zoom) >= 500}
            />
          </TooltipHost>
        </Stack>
      </Stack>
      {showTitle && handleDismiss && (
        <Stack horizontalAlign="end" styles={{ root: { width: "30%" } }}>
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            onClick={handleDismiss}
            styles={{ icon: { color: theme.palette.neutralPrimary } }}
          />
        </Stack>
      )}
    </Stack>
  );
};
