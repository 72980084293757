import { useStores } from "@stores/hooks/useStores.ts";

import { DataFetcher } from "../data-fetcher/DataFetcher.tsx";
import { DocumentPrintView } from "../document-viewer/DocumentPrintView.tsx";
import { Print } from "./Print.tsx";
import { PrintContentWrapper } from "./PrintContentWrapper.tsx";

interface DocumentPrintProps {
  documentTitle: string;
  getDocumentData: () => Promise<string>;
  completePrinting?: () => void;
  isImage?: boolean;
  pageStyle?: string;
}

export const DocumentPrint: React.FC<DocumentPrintProps> = ({
  completePrinting,
  documentTitle,
  getDocumentData,
  isImage,
  pageStyle = `@page {
      size: A4;
    }`
}) => {
  const { notification } = useStores();

  const printProps = {
    onAfterPrint: completePrinting,
    pageStyle,
    documentTitle
  };

  return (
    <DataFetcher
      fetch={getDocumentData}
      renderError={(error: Error) => {
        return notification.error(error);
      }}
    >
      {(sasUri: any) => {
        return (
          <Print {...printProps}>
            {print => (
              <PrintContentWrapper>
                <DocumentPrintView
                  onImagesRendered={print.print}
                  documentUrl={sasUri}
                  isImage={isImage}
                />
              </PrintContentWrapper>
            )}
          </Print>
        );
      }}
    </DataFetcher>
  );
};
