import {
  getPersonaInitialsColor,
  IPersonaProps,
  IPersonaStyles,
  ITheme,
  mergeFuncStyles,
  mergeStyles
} from "@bps/fluent-ui";
import { ContactStatus } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { horizontalItem } from "@ui-components/pickers/contact-picker/compactPeoplePickerOf.tsx";
import { ContactCategoryText } from "@ui-components/RefText.tsx";

import { getContactPickerItemStyles } from "./ContactPicker.styles.ts";
import organisationPersonaImg from "./organisation-persona.png";

export const getContactSuggestionItem = (
  contact: Contact,
  theme: ITheme,
  options: {
    overrides?: Partial<IPersonaProps>;
    isSelected: boolean;
    reversedName?: boolean;
  }
) => {
  const getBadgeClass = () => {
    const { inactive, deceased } = getContactPickerItemStyles(theme);
    if (options.isSelected && contact.status === ContactStatus.Inactive) {
      return mergeStyles("item-inactive", [inactive, horizontalItem]);
    } else if (
      options.isSelected &&
      contact.status === ContactStatus.Deceased
    ) {
      return mergeStyles("item-deceased", [deceased, horizontalItem]);
    }

    return undefined;
  };

  const suggestionItem: IPersonaProps = {
    text: options.reversedName ? contact.name : contact.reversedName,
    imageUrl: contact.isPerson ? undefined : organisationPersonaImg,
    initialsColor: getPersonaInitialsColor({ text: contact.id }),
    imageInitials: contact.initials,
    // render inactive badge is a contact has inactive status, and it is selected
    className: getBadgeClass(),
    coinProps: {
      styles: () => ({
        image: {
          borderRadius: 0
        }
      })
    },
    onRenderSecondaryText: () => (
      <ContactCategoryText code={contact.categories} />
    ),
    ...options?.overrides
  };

  if (!suggestionItem.secondaryText && !suggestionItem.showSecondaryText) {
    suggestionItem.styles = mergeFuncStyles(
      (): Partial<IPersonaStyles> => ({
        secondaryText: { display: "none" }
      }),
      suggestionItem.styles
    );
  }
  return suggestionItem;
};
