import { FunctionComponent, useRef } from "react";
import { Field, FieldRenderProps } from "react-final-form";

import {
  getId,
  ITag,
  MultiTagPicker,
  MultiTagPickerProps,
  useTheme
} from "@bps/fluent-ui";

import { ExposedFieldProps, getFieldErrorMessage } from "./FinalForm.tsx";
import { FormItemField, FormItemFieldProps } from "./FormItemField.tsx";
import { getNewPickerStyles } from "./utils.ts";

type HtmlElementType = any;

export type TagPickerAdapterProps = MultiTagPickerProps &
  Pick<FormItemFieldProps, "required" | "label"> & {
    formItemFieldStyles?: FormItemFieldProps["styles"];
  };

export type TagPickerFieldProps = ExposedFieldProps<
  MultiTagPickerProps["selectedKeys"],
  HtmlElementType
> &
  Omit<TagPickerAdapterProps, "defaultSelectedItems"> & {
    validateOnInitialize?: boolean;
  };

type InternalTagPickerAdapterProps = FieldRenderProps<
  MultiTagPickerProps["selectedKeys"],
  HtmlElementType
> &
  TagPickerAdapterProps;

const TagPickerAdapter: FunctionComponent<InternalTagPickerAdapterProps> = ({
  meta,
  input,
  label,
  styles,
  required,
  onChange,
  disabled,
  inputProps,
  formItemFieldStyles,
  validateOnInitialize,
  ...pickerProps
}) => {
  const theme = useTheme();
  const id = useRef<string>(getId(input.name));

  const hasErrorMessage = !!getFieldErrorMessage(meta, validateOnInitialize);

  const onChangeInternal = (
    selectedItems: string[] | undefined,
    items: ITag[]
  ) => {
    input.onChange(selectedItems);
    // input re-obtains the focus on change but onFocus is not triggered
    // https://github.com/OfficeDev/@fluentui/react/blob/b982a68f449a61b16429d1a025cd8a949177d954/packages/@fluentui/react/src/components/pickers/BasePicker.tsx#L875
    input.onFocus();
    if (onChange) {
      onChange(selectedItems, items);
    }
  };

  const _disabled = disabled || meta?.data?.disabled;
  return (
    <FormItemField
      styles={formItemFieldStyles}
      name={input.name}
      disabled={_disabled}
      required={required}
      label={label}
      htmlFor={id.current}
    >
      <MultiTagPicker
        {...pickerProps}
        onChange={onChangeInternal}
        disabled={_disabled}
        selectedKeys={input.value}
        inputProps={{
          ...inputProps,
          onBlur: input.onBlur,
          onFocus: input.onFocus,
          id: id.current
        }}
        styles={getNewPickerStyles(
          theme,
          { hasErrorMessage, disabled: _disabled },
          styles
        )}
      />
    </FormItemField>
  );
};

const normalizetoArray = (value: any) => (Array.isArray(value) ? value : []);

/**
 * Use TagPickerField to use TagPicker with final-form.
 * It also wraps the picker in a FormItemField to support label and error message.
 * @param props
 */
export const TagPickerField: React.FC<TagPickerFieldProps> = props => (
  <Field
    {...props}
    format={normalizetoArray}
    parse={normalizetoArray}
    component={TagPickerAdapter}
  />
);
