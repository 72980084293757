import { Field } from "react-final-form";

import { TagsSelect, TagsSelectProps } from "@bps/fluent-ui";

import { ExposedFieldProps } from "./FinalForm.tsx";
import { FormItemField } from "./FormItemField.tsx";

type HtmlElementType = any;

export type TagsSelectAdapterFieldProps = TagsSelectProps &
  HtmlElementType &
  Omit<TagsSelectProps, "value " | "onChange">;

export type TagsSelectFieldProps = ExposedFieldProps<
  TagsSelectProps["value"],
  HtmlElementType
> &
  TagsSelectAdapterFieldProps &
  Omit<TagsSelectProps, "value " | "onChange">;

const TagsSelectFieldAdapter = (props: TagsSelectAdapterFieldProps) => {
  const {
    input,
    styles,
    required,
    label,
    horizontalLabel,
    fieldItemStyles,
    ...tagsSelectFieldProps
  } = props;

  const onChange = (value: string[]) => {
    input.onChange(value);
  };

  return (
    <FormItemField
      name={input.name}
      required={required}
      label={label}
      horizontalLabel={horizontalLabel}
      styles={fieldItemStyles}
    >
      <TagsSelect
        value={input.value}
        onChange={onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        {...tagsSelectFieldProps}
      />
    </FormItemField>
  );
};

export const TagsSelectField: React.FunctionComponent<
  TagsSelectFieldProps
> = props => <Field {...props} component={TagsSelectFieldAdapter} />;
