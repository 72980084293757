import { TemplateVisibility } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

import { AutofillQuickAddValues } from "./AutofillQuickAddEditDialog.types.ts";

export const autofillNameOf = nameOfFactory<AutofillQuickAddValues>();
export const getVisibilityText = (isClinical: boolean, isAdmin: boolean) => {
  if (isClinical && isAdmin) {
    return TemplateVisibility.Both;
  } else {
    return isClinical ? TemplateVisibility.Clinical : TemplateVisibility.Admin;
  }
};

export enum AutofillPivotKey {
  Shared = "Shared",
  Private = "Private"
}
