import { MutableState, Mutator, Tools } from "final-form";
import { Mutators } from "final-form-arrays";

export interface AllMutators extends Mutators {
  sort: (name: string, compareFn: (a: any, b: any) => number) => void;
  setFieldData: (name: string, data: any) => void;
}

export const customMutators: {
  setFieldData: Mutator<any, any>;
  sort: Mutator<any, any>;
} = {
  setFieldData: (args: any[], state: MutableState<any, any>) => {
    const [name, data] = args;
    const field = state.fields[name];
    if (field) {
      field.data = { ...field.data, ...data };
    }
  },
  sort: (
    [name, compareFn]: [string, (a: any, b: any) => number],
    state: MutableState<any>,
    tools: Tools<any>
  ) => {
    type FormValues = {
      [key: string]: any;
    };
    const sortedArray = (state.formState.values as FormValues)[name].sort(
      compareFn
    );
    tools.setIn(state, `formState.values${name}`, sortedArray);
  }
};
