import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  ActionButtonProps,
  CheckboxGroup,
  CheckboxGroupProps,
  FieldItemProps
} from "@bps/fluent-ui";

import { ExposedFieldProps } from "./FinalForm.tsx";
import { FormItemField, FormItemFieldProps } from "./FormItemField.tsx";
import { ReadOnlyCheckboxGroup } from "./read-only-fields/ReadOnlyCheckboxGroup/ReadOnlyCheckboxGroup.tsx";

type HtmlElementType = HTMLElement | HTMLInputElement;

export type CheckboxGroupAdapterProps<T> = Pick<
  CheckboxGroupProps<T>,
  | "options"
  | "checkAllOption"
  | "horizontal"
  | "disabled"
  | "wrap"
  | "checkboxWidth"
  | "styles"
> &
  Pick<FormItemFieldProps, "required" | "label" | "name"> & {
    fieldItemStyles?: FieldItemProps["styles"];
    actionButton?: ActionButtonProps;
  };

export type CheckboxGroupFieldProps<T = string> = ExposedFieldProps<
  CheckboxGroupProps<any>["value"],
  HtmlElementType
> &
  CheckboxGroupAdapterProps<T>;

type InternalCheckboxGroupFieldAdapter<T> = FieldRenderProps<
  CheckboxGroupProps<T>["value"],
  HtmlElementType
> &
  CheckboxGroupAdapterProps<T>;

const CheckboxGroupFieldAdapter = <T extends string>(
  props: InternalCheckboxGroupFieldAdapter<T>
) => {
  const {
    input,
    meta,
    required,
    label,
    onChange,
    fieldItemStyles,
    styles,
    horizontal,
    wrap,
    checkboxWidth,
    disabled,
    actionButton,
    readOnly,
    ...rest
  } = props;

  const { meta: formMeta } = useField(input.name);

  if (readOnly || formMeta?.data?.readOnly) {
    return (
      <ReadOnlyCheckboxGroup {...rest} label={label} value={input.value} />
    );
  }

  const onChangeInternal = (selectedKeys: T[]) => {
    input.onChange(selectedKeys);

    if (onChange) {
      onChange(selectedKeys);
    }
  };

  return (
    <FormItemField
      name={input.name}
      required={required}
      label={label}
      actionButton={actionButton}
      labelAsLegend
    >
      <CheckboxGroup
        {...rest}
        onChange={onChangeInternal}
        value={input.value}
        disabled={disabled || formMeta?.data?.disabled}
        horizontal={horizontal}
        wrap={wrap}
        checkboxWidth={checkboxWidth}
        styles={styles}
      />
    </FormItemField>
  );
};

export const CheckboxGroupField = <T extends any = string>(
  props: CheckboxGroupFieldProps<T>
) => <Field {...props} component={CheckboxGroupFieldAdapter} />;
