import {
  ContactPickerField,
  ContactPickerFieldProps
} from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";
import { PatientPicker } from "@ui-components/pickers/contact-picker/PatientPicker.tsx";

type PatientPickerFieldProps = ContactPickerFieldProps;

/**
 * Use PatientPickerField when requiring a PatientPicker within react final form.
 * @param props
 */
export const PatientPickerField = (props: PatientPickerFieldProps) => (
  <ContactPickerField as={PatientPicker} {...props} />
);
