import { ReactNode } from "react";
import { ReactQuillProps } from "react-quill";

import { AutofillContext } from "./editor-autofills/editor-autofill.types.ts";

export interface TextEditorProps extends ReactQuillProps {
  heading?: ReactNode;
  withClinicalAutofill?: boolean;
  autofillContext?: AutofillContext;
  displayAutoFillBar?: boolean;
}

/**
 * Default toolbar elements approved my PMs.
 */
export const modules = {
  toolbar: [
    [{ size: [] }],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["clean"]
  ]
};
