import { FunctionComponent } from "react";

import { MessageBar, MessageBarType, Spinner } from "@bps/fluent-ui";

import { MultiUserPickerProps } from "./UserPicker.types.tsx";

interface MultiUserPickerFallbackProps
  extends Pick<MultiUserPickerProps, "loading" | "error"> {}

export const MultiUserPickerFallback: FunctionComponent<
  MultiUserPickerFallbackProps
> = ({ loading, error }) => {
  return (
    <>
      {loading && (
        <Spinner styles={{ root: { position: "absolute", top: 6, left: 6 } }} />
      )}
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          styles={{ root: { position: "absolute", top: 0 } }}
        >
          {error.message}
        </MessageBar>
      )}
    </>
  );
};
