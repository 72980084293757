import { FunctionComponent, memo } from "react";

import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";

import { ContactsSelect, ContactsSelectProps } from "./ContactsSelect.tsx";

export const PatientsSelect: FunctionComponent<ContactsSelectProps> = memo(
  props => {
    return (
      <ContactsSelect
        contactFilter={{ ...props.contactFilter, types: [ContactType.Patient] }}
        {...props}
      />
    );
  }
);
