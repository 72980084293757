import { createContext, ReactNode, useRef } from "react";
import { IReactToPrintProps, useReactToPrint } from "react-to-print";

interface IPrintContext {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  print: (() => void) | undefined;
}

interface PrintProps extends Omit<IReactToPrintProps, "content"> {
  children: (print: IPrintContext) => ReactNode;
}

export const PrintContext = createContext<IPrintContext>({} as IPrintContext);

export const Print: React.FC<PrintProps> = ({ children, ...props }) => {
  const elementToPrint = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => elementToPrint.current,
    ...props
  });

  const printProps = { ref: elementToPrint, print: handlePrint };

  return (
    <PrintContext.Provider value={printProps}>
      {children(printProps)}
    </PrintContext.Provider>
  );
};
