import { useState } from "react";

import {
  Dialog,
  IconButton,
  Spinner,
  Stack,
  useScreenSize,
  useTheme
} from "@bps/fluent-ui";
import { useDialogOpenedAnalytics } from "@libs/analytics/hooks/useDialogOpenedAnalytics.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { downloadFile, isImage, isPdf } from "@libs/utils/file.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { ErrorAlert } from "@ui-components/Alert.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { PDFViewerProps } from "./PDFViewer.tsx";
import { PDFViewer } from "./PDFViewerLazy.tsx";

export interface Document {
  documentId: string;
  name: string;
  extension: string;
  entityType: DocumentEntityType;
  entityId: string;
  downloadUri?: string;
  previewUri?: string | Uint8Array;
}

export interface DocumentViewerDialogProps
  extends Pick<
    PDFViewerProps,
    | "onRenderButtons"
    | "renderDownloadButton"
    | "showPrint"
    | "onDownloadClick"
    | "pageStyle"
  > {
  getDocument: () => Promise<Document> | Document;
  getDownloadUri?: () => Promise<string>;
  closeDocumentViewer: () => void;
  downloadInNewTab?: boolean;
  hideDownloadButton?: boolean;
  previewIfAvailable?: boolean;
}

export const DocumentViewerDialog: React.FC<DocumentViewerDialogProps> = ({
  getDownloadUri,
  closeDocumentViewer,
  getDocument,
  downloadInNewTab,
  hideDownloadButton = false,
  renderDownloadButton,
  previewIfAvailable,
  ...pdfViewerProps
}) => {
  const { core } = useStores();

  const [hidden, setHidden] = useState<boolean>(false);
  const screenSize = useScreenSize();

  useDialogOpenedAnalytics(
    "Document viewer dialog",
    core.user?.fullName,
    hidden
  );

  const download = async (document: Document) => {
    let fileUri = "";
    if (document.downloadUri) {
      fileUri = document.downloadUri;
    } else if (getDownloadUri) {
      fileUri = await getDownloadUri();
    }

    downloadFile(fileUri, { newTab: downloadInNewTab });
  };

  const handleDismiss = () => {
    setHidden(true);
    closeDocumentViewer();
  };

  const getDocumentViewData =
    () =>
    async ({
      inbox
    }: RootStore): Promise<{
      document: Document;
      previewUri: string | Uint8Array;
      isImage: boolean;
    }> => {
      const document = await getDocument();

      const previewUri =
        (document.previewUri &&
          (isImage(document.extension) || isPdf(document.extension))) ||
        (document.previewUri && previewIfAvailable)
          ? document.previewUri
          : await inbox.createPdf(
              {
                entityType: document.entityType,
                entityId: document.entityId,
                documentId: document.documentId
              },
              document.extension
            );

      return {
        document,
        previewUri,
        isImage: isImage(document.extension)
      };
    };

  const theme = useTheme();
  const MAX_WIDTH = 1500;
  const minDialogWidth =
    screenSize.width - 96 > MAX_WIDTH ? MAX_WIDTH : "calc(100% - 96px)";

  const MIN_HEIGHT = "calc(100% -96px)";

  const renderErrorOrLoading = (display: JSX.Element): JSX.Element => {
    return (
      <>
        <Stack
          horizontal
          horizontalAlign="end"
          styles={{
            root: {
              padding: 24
            }
          }}
        >
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            onClick={handleDismiss}
            styles={{ icon: { color: theme.palette.neutralPrimary } }}
          />
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="center"
          styles={{ root: { height: "calc(100% - 75px)" } }}
        >
          {display}
        </Stack>
      </>
    );
  };

  return (
    <Dialog
      hidden={hidden}
      onDismiss={handleDismiss}
      dialogContentProps={{
        styles: {
          content: { height: "100%" },
          innerContent: {
            height: "90vh"
          },
          inner: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            height: "100%"
          },
          header: {
            height: 0
          }
        }
      }}
      minWidth={minDialogWidth}
      styles={{ main: { minHeight: MIN_HEIGHT } }}
    >
      <DataFetcher
        fetch={getDocumentViewData()}
        fallback={renderErrorOrLoading(<Spinner />)}
        renderError={(error: Error) => {
          return renderErrorOrLoading(<ErrorAlert error={error} />);
        }}
      >
        {documentData => (
          <Stack
            data-is-scrollable="true"
            styles={{
              root: {
                height: "100%",
                overflowY: "auto"
              }
            }}
          >
            <PDFViewer
              pdfUrl={documentData.previewUri}
              renderDownloadButton={renderDownloadButton}
              onDownloadClick={
                hideDownloadButton
                  ? undefined
                  : () => download(documentData.document)
              }
              handleDismiss={handleDismiss}
              showTitle={true}
              showPrint={true}
              documentTitle={documentData.document.name}
              isImage={documentData.isImage}
              {...pdfViewerProps}
            />
          </Stack>
        )}
      </DataFetcher>
    </Dialog>
  );
};
