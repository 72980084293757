import { useTheme } from "@bps/fluent-ui";

import { PageLinkProps } from "./PageLink.types.ts";
import { PageLinkBase } from "./PageLinkBase.tsx";

export const NestedPageLink: React.FC<PageLinkProps> = (
  props: PageLinkProps
) => {
  const theme = useTheme();

  return (
    <PageLinkBase
      headingLevel="section-heading-light"
      styles={{
        root: {
          borderColor: theme.palette.neutralLighter,
          padding: "16px 24px"
        }
      }}
      {...props}
    />
  );
};
