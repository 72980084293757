import { Fragment, useRef } from "react";

import {
  dataAttribute,
  DataAttributes,
  EventContent,
  EventContentProps,
  FontWeights,
  mergeStyles,
  Shimmer,
  ShimmerElementType,
  Stack,
  Text
} from "@bps/fluent-ui";
import { groupBy } from "@bps/utils";

import { LegacyScrollListener } from "./LegacyScrollListener.tsx";

export type ExpandableItem = Pick<
  EventContentProps,
  | "onRenderContent"
  | "contentHeader"
  | "title"
  | "iconName"
  | "id"
  | "iconStyles"
> & {
  key?: string;
};

interface EventListProps {
  items: ExpandableItem[];
  onLoadMore?: () => void;
  hasMore?: boolean;
  loading?: boolean;
  emptyListContent?: React.ReactNode;
  loadingShimmer?: React.ReactNode;
  groupByKey?: boolean;
  onRowClick: (
    sourceId: string,
    listElementId: string | undefined,
    capturedScrollPosition: number | undefined
  ) => void;
  elementId?: string;
}

export const EventList: React.FunctionComponent<EventListProps> = ({
  items,
  onLoadMore,
  hasMore,
  loading,
  emptyListContent,
  loadingShimmer,
  groupByKey,
  onRowClick,
  elementId
}) => {
  const renderEntries = (items: ExpandableItem[]): React.ReactNode => {
    return (
      <ul>
        {items.map((x, index) => {
          return (
            <li
              className={mergeStyles({
                marginBottom: 0,
                selectors: {
                  ":last-child": {
                    marginBottom: 16
                  }
                }
              })}
              {...dataAttribute(DataAttributes.Element, "event-list-item")}
              key={x.id + x.key + index.toString()}
            >
              <EventContent
                iconName={x.iconName}
                id={x.id}
                title={x.title}
                contentHeader={x.contentHeader}
                onRenderContent={x.onRenderContent}
                iconStyles={x.iconStyles}
                onClick={(sourceId: string) => {
                  onRowClick(
                    sourceId,
                    divRef.current?.id,
                    divRef.current?.scrollTop
                  );
                }}
              />
            </li>
          );
        })}
      </ul>
    );
  };

  const defaultLoadingShimmer = (
    <Shimmer
      ariaLabel="Loading content"
      shimmerElements={[
        { type: ShimmerElementType.gap, width: "4%" },
        {
          type: ShimmerElementType.line,
          height: 10,
          verticalAlign: "top"
        }
      ]}
    />
  );

  let itemsByKey: [string | undefined, ExpandableItem[]][] | undefined;

  if (groupByKey) {
    itemsByKey = groupBy(items, item => item.key);
  }

  const divRef = useRef<HTMLDivElement>(null);

  return (
    <Stack
      verticalFill
      grow
      tokens={{ childrenGap: 8 }}
      styles={{ root: { minHeight: "100px" } }}
    >
      <div
        // data-is-scrollable used by ScrollListener
        ref={divRef}
        data-is-scrollable={true}
        className={mergeStyles({ flexGrow: 1, overflowY: "auto" })}
        id={`event-list-${elementId}`}
      >
        {items && items.length === 0 ? (
          emptyListContent
        ) : (
          <div {...dataAttribute(DataAttributes.Loading, loading)}>
            {groupByKey &&
              itemsByKey &&
              itemsByKey.map(item => {
                const [key, groupItems] = item;

                return (
                  <Fragment key={key}>
                    <Text
                      styles={{
                        root: { fontWeight: FontWeights.semibold }
                      }}
                    >
                      {key!}
                    </Text>
                    {renderEntries(groupItems)}
                  </Fragment>
                );
              })}
            {!groupByKey && renderEntries(items)}
          </div>
        )}

        {loading && (loadingShimmer ?? defaultLoadingShimmer)}
        <Shimmer
          isDataLoaded={!loading && !hasMore}
          ariaLabel="Loading content"
          shimmerElements={[
            { type: ShimmerElementType.gap, width: "4%" },
            {
              type: ShimmerElementType.line,
              height: 10,
              verticalAlign: "top"
            }
          ]}
        />
        {hasMore && <LegacyScrollListener onScrolledToBottom={onLoadMore} />}
      </div>
    </Stack>
  );
};
