import React from "react";

import {
  IStyle,
  IStyleFunctionOrObject,
  ITheme,
  SideRailMenuItem,
  SideRailMenuStyleProps,
  SideRailMenuStyles,
  TopBarWrapperStyleProps,
  TopBarWrapperStyles
} from "@bps/fluent-ui";

export interface SideMenuLayoutStylesProps {
  theme: ITheme;
}

export interface SideMenuLayoutProps {
  children?: React.ReactNode;
  sideRailMenuItems: SideRailMenuItem[];
  header: React.ReactNode;
  messageBar?: React.ReactNode;
  styles?: IStyleFunctionOrObject<
    SideMenuLayoutStylesProps,
    SideMenuLayoutStyles
  >;
}

export interface SideMenuLayoutStyles {
  root: IStyle;
  subComponentStyles: {
    topBarWrapper: IStyleFunctionOrObject<
      TopBarWrapperStyleProps,
      TopBarWrapperStyles
    >;
    sideRailMenu: IStyleFunctionOrObject<
      SideRailMenuStyleProps,
      SideRailMenuStyles
    >;
  };
  content: IStyle;
}

export const getStyles = (): SideMenuLayoutStyles => {
  return {
    root: {
      display: "flex",
      width: "auto",
      alignItems: "center",
      height: "100%"
    },
    subComponentStyles: {
      topBarWrapper: {},
      sideRailMenu: {
        root: {
          justifyContent: "center",
          display: "grid",
          gridTemplateColumns: "1fr 2fr"
        },
        containerWrapper: {
          width: 270,
          padding: 16,
          justifySelf: "end"
        }
      }
    },
    content: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "auto",
      height: "auto",
      grow: 1,
      maxWidth: 726,
      marginTop: 16,
      paddingBottom: 24
    }
  };
};
