import { IStackItemStyles, ITheme } from "@bps/fluent-ui";

export const getPDFStyles = (theme: ITheme) => {
  const pdfWrapper: IStackItemStyles = {
    root: {
      height: "100%",
      width: "100%",
      overflowY: "auto",
      textAlign: "center",
      position: "relative",
      justifyContent: "center",
      color: theme.palette.neutralTertiary,
      backgroundColor: theme.palette.neutralLighterAlt
    }
  };

  const pdfDialogWrapper: IStackItemStyles = {
    root: {
      height: "100%",
      width: "100%",
      overflowY: "auto",
      textAlign: "center",
      position: "relative",
      justifyContent: "center",
      color: theme.palette.neutralTertiary,
      backgroundColor: theme.palette.neutralDark
    }
  };

  const noMarginsLandscapePdfstyles = `@media print {
  @page {
  size: landscape;
  margin-top: 0mm;
  margin-bottom: 0mm
  }
  }`;

  return {
    pdfWrapper,
    pdfDialogWrapper,
    noMarginsLandscapePdfstyles
  };
};
