import { GetContactOptions } from "@shared-types/practice/get-contact-options.type.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { DataFetcher, DataFetcherProps } from "./data-fetcher/DataFetcher.tsx";

type Props = Omit<DataFetcherProps<Contact>, "fetch"> & {
  contactId: string;
  options?: GetContactOptions;
};
export const ContactFetcher = (props: Props) => (
  <DataFetcher<Contact>
    key={
      props.options
        ? `${props.contactId}.${JSON.stringify(props.options)}`
        : props.contactId
    }
    fetch={({ practice }) =>
      practice.getContact(props.contactId, props.options)
    }
    {...props}
  />
);
