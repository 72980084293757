import { FunctionComponent, PropsWithChildren, useRef } from "react";

import { CSSTransition, Dialog, mergeStyles, useTheme } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { SubmissionForm } from "./form/submission-form/SubmissionForm.tsx";

export interface DeleteDialogProps extends PropsWithChildren {
  formName: string;
  hidden: boolean;
  title: string;
  subText?: string;
  reasonLabel?: string;
  options: { key: string; text: string }[];
  okButtonText?: string;
  cancelButtonText?: string;
  onDeleteSucceeded?: () => void;
  onConfirm: (
    reasonForDelete: string,
    reasonForDeleteComment?: string
  ) => Promise<void>;
  onCancel?: () => void;
}

export interface DeleteDialogFormValues {
  reasonForDelete: string;
  reasonForDeleteComment?: string;
}

export class DeleteDialogValidator extends Validator<DeleteDialogFormValues> {
  constructor() {
    super();
    this.forField("reasonForDeleteComment", [
      predicate(
        (val: string, parent: DeleteDialogFormValues) =>
          parent.reasonForDelete === "OTH"
      ),
      maxLength(250)
    ]);
    this.forField("reasonForDelete", [required()]);
  }
}

const nameof = nameOfFactory<DeleteDialogFormValues>();
const validator = new DeleteDialogValidator();

export const DeleteDialog: FunctionComponent<DeleteDialogProps> = props => {
  const { formName, title, subText, children, ...rest } = props;
  const textElementOffset = useRef<number>();
  const theme = useTheme();
  return (
    <Dialog
      {...rest}
      onDismiss={props.onCancel}
      minWidth="400px"
      dialogContentProps={{
        title,
        subText,
        showCloseButton: true,
        styles: {
          inner: { padding: 0 },
          subText: {
            padding: "0 24px",
            margin: 0,
            color: theme.palette.neutralPrimary
          },
          title: { padding: "24px 46px 26.5px 24px" }
        }
      }}
    >
      <SubmissionForm<DeleteDialogFormValues>
        formName={formName}
        onSubmit={values => {
          return props.onConfirm(
            values.reasonForDelete,
            values.reasonForDeleteComment
          );
        }}
        onSubmitSucceeded={rest.onDeleteSucceeded}
        buttonsProps={{
          submitButtonProps: {
            text: props.okButtonText ? props.okButtonText : "Delete",
            iconProps: {}
          },
          cancelButtonProps: {
            text: props.cancelButtonText ? props.cancelButtonText : "Cancel",
            iconProps: {}
          },
          onCancel: props.onCancel,
          hideButtonsSeparator: true,
          styles: { root: { padding: "40px 24px 24px 24px", margin: 0 } }
        }}
        validate={values => validator.validate(values)}
        styles={{
          fields: { padding: "0px 24px" }
        }}
      >
        {form => {
          return (
            <>
              {children}

              <Fieldset styles={{ root: { padding: "8px 0px 8px" } }}>
                <ChoiceGroupField
                  options={props.options}
                  name={nameof("reasonForDelete")}
                  label={props.reasonLabel ? props.reasonLabel : "Reason"}
                  required={true}
                  styles={{ root: { padding: "8px 0px 0px" } }}
                  fieldItemStyles={{
                    wrapper: { input: { width: 0 } }
                  }}
                />
                <CSSTransition
                  in={form.values.reasonForDelete === "OTH"}
                  unmountOnExit
                  timeout={300}
                  classNames={{
                    enter: mergeStyles({ maxHeight: 0 }),
                    enterActive: mergeStyles({
                      maxHeight: textElementOffset.current,
                      transition: "max-height 300ms ease-in",
                      overflow: "hidden"
                    }),
                    exit: mergeStyles({
                      maxHeight: textElementOffset.current,
                      transition: "max-height 300ms ease-in"
                    }),
                    exitActive: mergeStyles({
                      maxHeight: 0,
                      transition: "max-height 300ms ease-out",
                      overflow: "hidden"
                    })
                  }}
                >
                  <TextInputField
                    componentRef={r => {
                      if (!textElementOffset.current && r) {
                        // set TextInputField root element offsetHeight ref
                        textElementOffset.current =
                          r[
                            "_textElement"
                          ].current.offsetParent.offsetParent.offsetHeight;
                      }
                    }}
                    multiline={true}
                    name={nameof("reasonForDeleteComment")}
                    label="Comment"
                    resizable={false}
                    rows={4}
                    required={false}
                  />
                </CSSTransition>
              </Fieldset>
            </>
          );
        }}
      </SubmissionForm>
    </Dialog>
  );
};
