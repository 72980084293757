import { IRootStore } from "@shared-types/root/root-store.interface.ts";

import { IEditorAutofill } from "./editor-autofill.types.ts";

export abstract class EditorAutofillBase<T>
  implements Omit<IEditorAutofill, "replace" | "jumpLinksHandler">
{
  constructor(root: IRootStore) {
    this.root = root;
  }

  protected root: IRootStore;
  public documentEditor: T;
  protected readonly jumpLinkCharacter: string = "^";
  protected readonly f6KeyCode = 117;

  public setDocumentEditor = (editor: T) => {
    if (!this.documentEditor) {
      this.documentEditor = editor;
    }
    return this;
  };
}
