import { FC } from "react";

import { useTheme } from "@bps/fluent-ui";

import { PageLinkProps } from "./PageLink.types.ts";
import { PageLinkBase } from "./PageLinkBase.tsx";

export const PageLink: FC<PageLinkProps> = (props: PageLinkProps) => {
  const theme = useTheme();

  return (
    <PageLinkBase
      headingLevel="section-heading"
      styles={{
        root: {
          border: "none",
          boxShadow: theme.effects.elevation4,
          width: "100%"
        }
      }}
      {...props}
    />
  );
};
