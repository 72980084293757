import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  IStackProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";

import { CHARS_TO_ESCAPE, getCutText, getEllipsis } from "./utils.ts";

interface HighlightedTextProps extends IStackProps {
  text: string;
  filter: string;
  cut?: boolean;
  startOnly?: boolean;
}

export const HighlightedText: FunctionComponent<HighlightedTextProps> = ({
  text,
  filter,
  cut,
  startOnly,
  ...props
}) => {
  const theme = useTheme();

  let textToDisplay = text;

  if (cut && !startOnly) {
    textToDisplay = getCutText(text, filter);
  } else {
    textToDisplay = getEllipsis(text, filter);
  }

  const filterWithEscapedCharacters = filter.replace(
    CHARS_TO_ESCAPE,
    (match: string) => `\\${match}`
  );

  // splits the text by the filter, retaining the filter
  // eg, "abcfilterdeffiltergh" becomes ["abc", "filter", "def", "filter", "gh"]

  let arr = [text];
  if (filter) {
    if (startOnly) {
      const substringIndex = text.toLowerCase().indexOf(filter.toLowerCase());
      if (substringIndex === 0) {
        arr = [text.slice(0, filter.length), text.slice(filter.length)];
      }
    } else {
      arr = textToDisplay.split(
        new RegExp(`(${filterWithEscapedCharacters})`, "i")
      );
    }
  }

  const arrWithKeys = arr.map(s => ({ text: s, key: newGuid() }));

  return (
    <Stack
      horizontal
      {...dataAttribute(DataAttributes.Element, "highlighted-text-field")}
      {...props}
    >
      {arrWithKeys.map(str => {
        const isFilterString = str.text.toLowerCase() === filter.toLowerCase();

        return (
          <Stack.Item
            key={str.key}
            {...(isFilterString
              ? dataAttribute(DataAttributes.Element, "highlighted-text-item")
              : null)}
            styles={{
              root: {
                backgroundColor: isFilterString
                  ? theme.palette.themeLighter
                  : undefined,
                whiteSpace: "pre"
              }
            }}
          >
            {str.text}
          </Stack.Item>
        );
      })}
    </Stack>
  );
};
