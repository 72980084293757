import { FunctionComponent, memo } from "react";

import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";

import { UsersSelect, UsersSelectProps } from "./UsersSelect.tsx";

export const BookableUsersSelect: FunctionComponent<UsersSelectProps> = memo(
  props => {
    return (
      <UsersSelect
        usersFilter={{
          showOnCalendar: true,
          statusCodes: [UserStatus.Active]
        }}
        {...props}
      />
    );
  }
);
