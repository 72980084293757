import { DATE_FORMATS, DateTime } from "@bps/utils";

const NUMBERS_AND_PLUS_ONLY_REG_EXP = /^(\+?)\d+$/;
const WORDS_COMMA_PARENTHESIS_REG_EXP = /^[\w,()' -]*$/;
const isNumber = (value: string) => {
  return NUMBERS_AND_PLUS_ONLY_REG_EXP.test(value);
};

const isWordWithParenthesisAndComma = (value: string) => {
  return WORDS_COMMA_PARENTHESIS_REG_EXP.test(value);
};
export const hasValidBirthdayData = (value: string | undefined) =>
  value
    ? DateTime.fromFormat(value, DATE_FORMATS.DAY_DEFAULT_FORMAT).isValid
    : false;
export const convertSearchValueToBirthdayString = (
  value: string | undefined
) =>
  value
    ? DateTime.fromFormat(value, DATE_FORMATS.DAY_DEFAULT_FORMAT).toISODate()
    : undefined;
export const hasValidPhoneNumberData = (value: string | undefined) =>
  value ? isNumber(value) : false;
export const hasValidNameData = (value: string | undefined) =>
  value ? isWordWithParenthesisAndComma(value) : false;
