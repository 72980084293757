import { ToggleField, ToggleFieldProps } from "./ToggleField.tsx";

export const YesNoToggleField: React.FC<ToggleFieldProps> = props => {
  const yesNoOptions = {
    onText: "Yes",
    offText: "No"
  };

  return <ToggleField {...props} {...yesNoOptions} />;
};
