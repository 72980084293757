import { FormApi } from "final-form";

import {
  ConfirmOptions,
  IButtonProps,
  IContextualMenuItem,
  IStackProps,
  IStackStyles,
  ITooltipHostProps
} from "@bps/fluent-ui";

export const submitButtonsText = {
  confirm: "Submit",
  cancel: "Cancel"
};

type FormSubmitButtonsStyles<FormValues> = {
  styles: (
    isDirty: boolean,
    form: FormApi<FormValues>
  ) => IStackProps["styles"];
};

export type FormSubmitButtonsProps<FormValues> = {
  className?: string;
  styles?: IStackStyles | FormSubmitButtonsStyles<FormValues>["styles"];
  onCancel?: (form: FormApi<FormValues>) => void;
  onSubmit?: (form: FormApi<FormValues>) => void;
  disableCancelOnPristine?: boolean;
  disableSubmitOnPristine?: boolean;
  disableSubmitOnFormNotFullyFilled?: boolean;
  extraActionsBefore?: (
    form: FormApi<FormValues>,
    isPrimaryDisabled?: boolean
  ) => React.ReactNode;
  extraActionsAfter?: React.ReactNode;
  extraActionsBetween?: (
    form: FormApi<FormValues>,
    isPrimaryDisabled?: boolean
  ) => React.ReactNode;
  buttonsGap?: number;
  /**
   * Do not set this prop to true if you're already passing an onCancel function that changes the URI because it will trigger the same prompt twice.
   * For example the AppointmentFormFields changes the URL when the user clicks the cancel button, which will automatically trigger the prompt.
   */
  promptOnCancel?: boolean;
  /* promptOnSubmit - Prompts for confirmation on form submission */
  promptOnSubmit?: boolean;
  submitButtonProps?: Omit<IButtonProps, "split"> & {
    items?: IContextualMenuItem[];
    tooltipProps?: ITooltipHostProps;
  };
  cancelButtonProps?: Omit<IButtonProps, "split"> & {
    items?: IContextualMenuItem[];
  };
  hideButtonsSeparator?: boolean;
  disableSubmitOnFormInvalid?: boolean;
  /* extraPromptConditionOnCancel - Can add extra conditions to be handled for showing the prompt on clicking onCancel button */
  extraPromptConditionOnCancel?: (form: FormApi<FormValues>) => boolean;
  /* extraPromptConditionOnSubmit - Extra condition to check before displaying the confirmation dialog, to be used with promptOnSubmit */
  extraPromptConditionOnSubmit?: (form: FormApi<FormValues>) => boolean;
  /* submitConfirmProps - Props to customise the confirm dialog displayed by promptOnSubmit */
  submitConfirmProps?: ConfirmOptions;
  hideSubmit?: boolean;
  hideCancel?: boolean;
};
