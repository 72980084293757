import { ComponentType, FunctionComponent } from "react";

import { classNamesFunction, styled } from "@bps/fluent-ui";

import { getReadOnlyCheckboxGroupStyles } from "./ReadOnlyCheckboxGroup.styles.ts";
import {
  ReadOnlyCheckboxGroupProps,
  ReadOnlyCheckboxGroupStyles,
  ReadOnlyCheckboxGroupStylesProps
} from "./ReadOnlyCheckboxGroup.types.ts";

const getClassNames = classNamesFunction<
  ReadOnlyCheckboxGroupStylesProps,
  ReadOnlyCheckboxGroupStyles
>();

const ReadOnlyCheckboxGroupBase: FunctionComponent<
  ReadOnlyCheckboxGroupProps
> = ({ options, label, styles, className, theme, value }) => {
  const classNames = getClassNames(styles, { className, theme });

  return (
    <div className={classNames.root}>
      {label && <span className={classNames.label}>{label}</span>}
      <div className={classNames.flexContainer}>
        {options.map(option => {
          if ((value || [])?.every(key => key !== option.value)) {
            return null;
          }

          return (
            <div key={option.value} className="checkbox-option">
              <span>{option.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ReadOnlyCheckboxGroup = styled<
  ReadOnlyCheckboxGroupProps,
  ReadOnlyCheckboxGroupStylesProps,
  ReadOnlyCheckboxGroupStyles
>(ReadOnlyCheckboxGroupBase, getReadOnlyCheckboxGroupStyles) as ComponentType<
  Omit<ReadOnlyCheckboxGroupProps, "theme">
>;
