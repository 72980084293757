import { NoDataTile, useFormContext } from "@bps/fluent-ui";

interface DefaultNoResultsTileProps {
  defaultText?: string;
  isFilteringText?: string;
}

export const DefaultNoResultsTile: React.FC<DefaultNoResultsTileProps> = ({
  defaultText,
  isFilteringText
}) => {
  const {
    state: { dirty: isFiltering },
    actions: { reset }
  } = useFormContext();

  const text = defaultText || "No matches found";
  const filteringText = isFilteringText || text;

  return (
    <NoDataTile
      textProps={{
        text: isFiltering ? text : filteringText
      }}
      linkProps={{
        text: "Clear filters",
        onClick: () => reset(),
        hidden: !isFiltering
      }}
      showBoxShadow={false}
    />
  );
};
