import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";

import { dataAttribute, DataAttributes } from "@bps/fluent-ui";
import { BusinessRoleClasses } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { maybePromiseObservable } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { contains } from "@libs/utils/utils.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { MultiUserPicker, sortUsers } from "./MultiUserPicker.tsx";
import { MultiUserPickerProps } from "./UserPicker.types.tsx";

export type MultiBookableUserPickerProps = Omit<
  MultiUserPickerProps,
  "loading" | "error"
>;

export function filterBookableUsers(
  providers: User[],
  currentUser: User | undefined,
  searchParam?: string
) {
  return providers.filter(p => {
    if (searchParam) {
      return contains(p.name, searchParam);
    }

    return providers.slice().sort((a, b) => sortUsers(a, b, currentUser));
  });
}

const bookableUsersPromise = maybePromiseObservable<User[]>();
/**
 * Provider Picker is a component used to pick a one to many bookableUsers from a list.
 */
export const MultiBookableUserPicker: React.FC<MultiBookableUserPickerProps> =
  observer((props: MultiBookableUserPickerProps) => {
    const {
      filter,
      useBusinessRoleClass,
      currentUserOnTop,
      ...basePickerProps
    } = props;

    const { core } = useStores();

    const loadBookableUsers = useCallback(
      () =>
        core
          .fetchUsers({
            businessRoleClasses: useBusinessRoleClass
              ? [BusinessRoleClasses.Provider]
              : [],
            ...filter
          })
          .then(users =>
            users
              .slice()
              .sort((a, b) =>
                sortUsers(a, b, currentUserOnTop ? core.user : undefined)
              )
          ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const bookableUsers = (bookableUsersPromise.value ?? []).filter(u => {
      if (filter && "showOnCalendar" in filter) {
        return !!u.userSetting?.showOnCalendar;
      }
      return true;
    });

    useEffect(() => {
      bookableUsersPromise.set(loadBookableUsers());
      return () => {
        bookableUsersPromise.clear();
      };
    }, [core, loadBookableUsers]);

    const handleEmptySearchItems: MultiUserPickerProps["onEmptySearchItems"] =
      async () => {
        if (!bookableUsersPromise.pendingOrFulfilled)
          bookableUsersPromise.set(loadBookableUsers());

        return bookableUsers;
      };

    const handleSearchItems: MultiUserPickerProps["onSearchItems"] =
      async searchParam => {
        if (!bookableUsersPromise.pendingOrFulfilled)
          bookableUsersPromise.set(loadBookableUsers());

        const providers = filterBookableUsers(
          bookableUsers,
          core.user,
          searchParam
        );

        return {
          results: providers,
          take: providers.length,
          skip: 0,
          total: providers.length
        };
      };

    return (
      <MultiUserPicker
        disabled={bookableUsersPromise.pending}
        loading={bookableUsersPromise.pending}
        error={bookableUsersPromise.error}
        excludeSelectedItems={false}
        suggestionDefaultLimit={100}
        onSearchItems={handleSearchItems}
        onEmptySearchItems={handleEmptySearchItems}
        {...basePickerProps}
        inputProps={{
          ...dataAttribute(DataAttributes.Element, "user-picker-input"),
          ...basePickerProps.inputProps
        }}
      />
    );
  });
