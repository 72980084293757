import { FunctionComponent } from "react";

import { IBasePickerStyles, mergeStyleSets } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  StaticPickerField,
  StaticPickerFieldProps
} from "@ui-components/form/StaticPickerField.tsx";

type TitlePickerFieldProps = Omit<StaticPickerFieldProps, "fetchDataSource">;

export const TitlePickerField: FunctionComponent<
  TitlePickerFieldProps
> = props => {
  const { core } = useStores();
  const stylesOverride: Partial<IBasePickerStyles> = {
    root: { maxWidth: 300 }
  };

  return (
    <StaticPickerField
      {...props}
      fetchDataSource={core.ref.titles.fetchAsKeyNameValues}
      styles={mergeStyleSets(stylesOverride, props.styles)}
    />
  );
};
