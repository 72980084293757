import { FontSizes, FontWeights } from "@bps/fluent-ui";

import {
  ReadOnlyTextFieldStyles,
  ReadOnlyTextFieldStylesProps
} from "./ReadOnlyTextField.types.ts";

export const getReadOnlyTextFieldStyles = (
  props: ReadOnlyTextFieldStylesProps
): ReadOnlyTextFieldStyles => {
  const { className, theme, alignAsField } = props;
  return {
    root: [
      "read-only-text-field",
      className,
      { display: "flex", flexDirection: "column" }
    ],
    label: {
      ...theme.fonts.medium,
      fontWeight: FontWeights.semibold,
      padding: "5px 0"
    },
    text: {
      display: "flex",
      alignItems: alignAsField ? "center" : "baseline",
      height: alignAsField ? 32 : "auto",
      ...theme.fonts.medium
    },
    hint: {
      fontSize: FontSizes.size12,
      fontWeight: FontWeights.regular,
      color: theme.palette.neutralSecondary,
      paddingTop: 5
    }
  };
};
