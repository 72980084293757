import { ComponentType, memo, useRef } from "react";

import {
  DatePickerProps,
  DatesRangePickerProps,
  standardRangesOptions
} from "@bps/fluent-ui";
import { AppInsightsEventNames } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { useAppTrackEvent } from "@libs/analytics/app-insights/useAppTrackEvent.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

type DatesRangePickerAnalytics = {
  interaction: string;
  role?: string;
  listId?: string;
};

export const withDatesRangePickerFieldAnalytics = (
  Component: ComponentType<DatesRangePickerProps>
) => {
  const WithAnalytics = (props: DatesRangePickerProps) => {
    const { core } = useStores();

    const sessionInteractions = useRef<Set<string>>(new Set());

    const trackEvent = useAppTrackEvent(
      AppInsightsEventNames.dateRangePickerSelection,
      undefined,
      true
    );

    const prepareTelemetry = (interaction: string) => {
      // See if this type has already been sent through.
      if (sessionInteractions.current.has(interaction)) {
        return;
      }

      const businessRoles = core.user?.businessRoles ?? [];
      const userRoles = businessRoles.map(
        role =>
          core.catalogBusinessRoles.find(
            catalogRole => catalogRole.code === role
          )?.text ?? "unknown"
      );

      const builtData: DatesRangePickerAnalytics = {
        interaction,
        role: userRoles.join(", "),
        listId: props.id
      };

      // Publish AppInsights event
      trackEvent(builtData);

      // Persist selected session interactions
      if (!sessionInteractions.current.has(interaction)) {
        sessionInteractions.current.add(interaction);
      }
    };

    const startDateOnChange: DatePickerProps["onChange"] = (
      value,
      fixedRange,
      pasteStartDatePressed
    ) => {
      props.startDateProps.onChange(value, fixedRange, pasteStartDatePressed);
      // Range logic only needs to be checked on one side, either startDate or endDate as a range
      // selection will update both these values.
      if (fixedRange !== undefined) {
        const option = standardRangesOptions.find(_ => _.key === fixedRange);
        if (option) {
          prepareTelemetry(option.text);
        }

        return;
      }

      prepareTelemetry("Custom");
    };

    const endDateOnChange: DatePickerProps["onChange"] = (
      value,
      fixedRange,
      pasteStartDatePressed
    ) => {
      props.endDateProps.onChange(value, fixedRange, pasteStartDatePressed);

      if (pasteStartDatePressed) {
        prepareTelemetry("Sync");
        return;
      }

      if (!fixedRange && value) {
        prepareTelemetry("Custom");
      }
    };

    return (
      <Component
        {...props}
        startDateProps={{
          ...props.startDateProps,
          onChange: startDateOnChange
        }}
        endDateProps={{
          ...props.endDateProps,
          onChange: endDateOnChange
        }}
      />
    );
  };

  return memo(WithAnalytics) as ComponentType<DatesRangePickerProps>;
};
