import { parsePath, To } from "react-router-dom";

const absoluteRegexp = new RegExp("^(?:[a-z]+:)?//", "i");

export const isAbsoluteUrl = (href: string) => absoluteRegexp.test(href);

export function isModifiedEvent(event: React.MouseEvent<any>) {
  return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
}

export function normalizeToLocation(to: To) {
  return typeof to === "string" ? parsePath(to) : to;
}
