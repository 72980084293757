import { FormEvent, FunctionComponent } from "react";
import { Field, FieldRenderProps } from "react-final-form";

import {
  ChoiceComboBox,
  ChoiceComboBoxProps,
  FieldItem,
  FieldItemProps,
  IComboBox,
  IComboBoxStyles,
  mergeStyleSets,
  metaToLabelColor,
  useTheme
} from "@bps/fluent-ui";
import { identity } from "@libs/utils/utils.ts";

import { ExposedFieldProps, getFieldErrorMessage } from "./FinalForm.tsx";
import { ReadOnlyTextField } from "./read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

type HtmlElementType = HTMLElement;

export type ComboBoxFieldProps = Pick<
  ChoiceComboBoxProps,
  | "styles"
  | "options"
  | "label"
  | "placeholder"
  | "required"
  | "autoComplete"
  | "allowFreeform"
  | "calloutProps"
  | "useComboBoxAsMenuWidth"
  | "disabled"
  | "onRenderFieldText"
  | "multiSelect"
  | "dynamicOptions"
> &
  ExposedFieldProps<ChoiceComboBoxProps["selectedKey"], HtmlElementType> & {
    validateOnInitialize?: boolean;
    hint?: string;
  } & { fieldItemStyles?: FieldItemProps["styles"] } & Partial<
    Pick<ChoiceComboBoxProps, "onChange">
  >;

type ComboBoxAdapterProps = ChoiceComboBoxProps &
  FieldRenderProps<ChoiceComboBoxProps["selectedKey"], HtmlElementType> &
  Pick<ComboBoxFieldProps, "hint" | "fieldItemStyles">;

const ComboBoxAdapter: FunctionComponent<ComboBoxAdapterProps> = (
  props
): JSX.Element => {
  const theme = useTheme();

  const {
    input: { value, onFocus, onBlur, name },
    meta: formMeta,
    styles,
    selectedKey,
    validateOnInitialize,
    label,
    required,
    hint,
    fieldItemStyles,
    allowFreeform,
    ...comboBoxProps
  } = props;

  const errorMessage = getFieldErrorMessage(formMeta, validateOnInitialize);

  const disabled = comboBoxProps.disabled || formMeta?.data?.disabled;

  const errorColor = metaToLabelColor(
    {
      active: formMeta.active,
      hasErrorMessage: !!errorMessage
    },
    theme
  );

  const newStyles: Partial<IComboBoxStyles> = mergeStyleSets(
    {
      label: {
        color: errorColor
      },
      container: {
        selectors: {
          ".ms-ComboBox::after": {
            borderColor: errorColor,
            borderWidth: formMeta.active ? "2px" : "1px"
          },
          ".ms-ComboBox:hover:hover::after": {
            borderColor: errorColor
          }
        }
      }
    },
    styles
  );

  if (formMeta?.data?.readOnly) {
    return (
      <ReadOnlyTextField
        value={Array.isArray(value) ? value.join(", ") : value}
      />
    );
  }

  return (
    <FieldItem
      disabled={disabled}
      name={name}
      label={label}
      required={required}
      errorMessage={errorMessage}
      hint={hint}
      active={formMeta.active}
      styles={fieldItemStyles}
    >
      <ChoiceComboBox
        {...comboBoxProps}
        data-name-for-input={name}
        onChange={(
          _evt: FormEvent<IComboBox>,
          key: string | string[] | undefined
        ) => {
          props.input.onChange(key);
          comboBoxProps.onChange &&
            comboBoxProps.onChange(_evt, key as string & string[]);
        }}
        styles={newStyles}
        onFocus={onFocus as any}
        onBlur={onBlur as any}
        allowFreeform={allowFreeform}
        disabled={disabled}
        selectedKey={value as any} // ⚠ It has to be any since multiSelect is not determined on this level to get correct value type. Ilya S.
      />
    </FieldItem>
  );
};

export const ComboBoxField: FunctionComponent<ComboBoxFieldProps> = props => (
  <Field {...props} format={identity} component={ComboBoxAdapter} />
);
