import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  Dropdown,
  DropdownProps,
  IDropdownProps,
  mergeFuncStyles,
  useTheme
} from "@bps/fluent-ui";
import { identity } from "@libs/utils/utils.ts";
import { ReadOnlyTextField } from "@ui-components/form/read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

import {
  ExposedFieldProps,
  getFieldErrorMessage,
  getLabelStyles
} from "./FinalForm.tsx";

type HtmlElementType = HTMLDivElement;
export type DropdownFieldProps = Omit<
  DropdownProps,
  | "selectedKey"
  | "selectedKeys"
  | "onChange"
  | "onChanged"
  | "defaultValue"
  | "name"
> &
  ExposedFieldProps<
    DropdownProps["selectedKey"] | DropdownProps["selectedKeys"],
    HtmlElementType & {
      loading?: boolean;
    }
  > & { validateOnInitialize?: boolean; readOnly?: boolean };

type DropdownAdapterProps = FieldRenderProps<
  DropdownProps["selectedKey"] | DropdownProps["selectedKeys"],
  HtmlElementType
> &
  IDropdownProps & {
    loading?: boolean;
    readOnly: boolean;
  };

const DropdownAdapter: React.FC<DropdownAdapterProps> = props => {
  const theme = useTheme();

  const {
    input: { name, value, onBlur, onFocus },
    meta,
    styles,
    loading,
    validateOnInitialize,
    readOnly,
    disabled,
    ...dropdownProps
  } = props;

  const { meta: formMeta } = useField(name);

  const onDropdownChangeProp = props.onChange;
  const onInputChangeProp = props.input.onChange;
  const hasErrorMessage = !!getFieldErrorMessage(meta, validateOnInitialize);
  const _disabled = disabled || formMeta?.data?.disabled;
  const newStyles = mergeFuncStyles(
    {
      subComponentStyles: {
        label: getLabelStyles(meta)
      }
    },
    hasErrorMessage
      ? {
          root: {
            selectors: {
              ".ms-Dropdown:focus::after": {
                borderColor: theme.semanticColors.errorText
              }
            }
          }
        }
      : undefined,
    styles
  );

  // sending null instead of undefined will ensure that
  // dropdown selectedKey(s) are reset
  let selectedKey: DropdownProps["selectedKey"];
  if (props.multiSelect) {
    selectedKey = undefined;
  } else if (value === undefined) {
    selectedKey = null;
  } else {
    selectedKey = value;
  }

  let selectedKeys: DropdownProps["selectedKeys"];
  if (!props.multiSelect) {
    selectedKeys = undefined;
  } else if (value === undefined) {
    selectedKeys = null;
  } else {
    selectedKeys = value as string[] | number[];
  }

  if (readOnly || formMeta?.data?.readOnly) {
    let value: string | undefined;
    if (selectedKey && !dropdownProps.multiSelect) {
      value = dropdownProps.options.find(x => x.key === selectedKey)?.text;
    }

    if ((selectedKeys || []).length > 0 && dropdownProps.multiSelect) {
      const values: string[] = [];
      selectedKeys?.forEach((key: string | number) => {
        const text = dropdownProps.options.find(x => x.key === key)?.text;
        if (text) {
          values.push(text);
        }
      });
      value = values.join(", ");
    }

    return (
      <ReadOnlyTextField value={value || ""} label={dropdownProps.label} />
    );
  }

  const onDropdownChange: DropdownProps["onChange"] = (...args) => {
    const [event, option, index, newValue] = args;

    onInputChangeProp(newValue);

    if (onDropdownChangeProp) {
      onDropdownChangeProp(event, option, index);
    }
  };

  return (
    <Dropdown
      {...dataAttribute(DataAttributes.Element, "dropdown-input")}
      name={name}
      {...dropdownProps}
      active={meta.active}
      disabled={_disabled}
      data-name={name}
      selectedKeys={selectedKeys}
      selectedKey={selectedKey}
      onChange={onDropdownChange}
      onBlur={onBlur}
      onFocus={onFocus}
      styles={newStyles}
      errorMessage={getFieldErrorMessage(meta, validateOnInitialize)}
      {...dataAttribute(DataAttributes.Loading, !!loading)}
    />
  );
};

/**
 * Integrates the Dropdown component with React Final Form.
 * @param props
 */
export const DropdownField: React.FunctionComponent<
  DropdownFieldProps
> = props => (
  <Field
    parse={identity}
    format={identity}
    {...props}
    component={DropdownAdapter}
  />
);
