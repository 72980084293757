import { FULFILLED, PENDING, REJECTED } from "mobx-utils";
import { useRef } from "react";
import { Field, useForm } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import { Stack } from "@bps/fluent-ui";
import { Guid } from "@syncfusion/ej2-pdf-export";
import { FilePickerField } from "@ui-components/form/FilePickerField/FilePickerField.tsx";
import {
  FileFieldValue,
  FilePickerFieldProps
} from "@ui-components/form/FilePickerField/FilePickerField.types.ts";

import { Fieldset } from "../Fieldset.tsx";
import {
  FileNameFieldData,
  FileUploaderFieldProps
} from "./FileUploaderField.types.ts";
import { FileUploadProgress } from "./FileUploadProgress.tsx";

/**
 * Integrates the FilePickerField with Azure Blob Storage.
 * All selected files get uploaded to an Azure Blob Storage container.
 * While they are uploading,a progress indicator is displayed for each file.
 * Once they are uploaded, a text field allows to change the file name.
 *
 * The blob name is a unique random name. The filename is not uploaded as part of the Blob metadata
 * but its value is accessible from the Final Form field.
 */
export const FileUploaderField = <T extends FileFieldValue>(
  props: FileUploaderFieldProps<T>
) => {
  const form = useForm();
  const fieldsRef = useRef<
    FieldArrayRenderProps<T, HTMLElement>["fields"] | undefined
  >(undefined);

  const {
    getContainerClient,
    legend,
    renderUploadedFile,
    onDropAccepted,
    onFileFulfilled,
    getStagingId,
    ...filePickerProps
  } = props;

  const setFileFieldData = (
    fileFieldName: string,
    data: Partial<FileNameFieldData>
  ) => {
    form.mutators.setFieldData(fileNameField(fileFieldName), data);
  };

  const handleDropAccepted: FilePickerFieldProps["onDropAccepted"] = async (
    files: File[],
    addedIndex: number
  ) => {
    let fileIndex = 0;
    const containerClient = await getContainerClient();

    // setTimeout is used because the {fileFieldName}.name field is only created
    // after the <Field> is mounted. Until them, fields.length will be 0
    setTimeout(() => {
      fieldsRef.current &&
        fieldsRef.current.map((fileFieldName, index) => {
          if (index < addedIndex) {
            return undefined;
          }

          const file = files[fileIndex++];
          const aborter = new AbortController();

          const blobName = getStagingId
            ? getStagingId()
            : Guid.getNewGuidString();

          setFileFieldData(fileFieldName, {
            loadedBytes: 0,
            uploadStatus: PENDING
          });

          const blockBlobClient = containerClient.getBlockBlobClient(blobName);
          const uploadResponsePromise = blockBlobClient.uploadBrowserData(
            file,
            {
              abortSignal: aborter.signal,
              onProgress: ({ loadedBytes }) => {
                setFileFieldData(fileFieldName, {
                  loadedBytes
                });
              },
              blobHTTPHeaders: {
                blobContentType: file.type
              }
            }
          );

          setFileFieldData(fileFieldName, {
            uploadResponsePromise,
            aborter
          });

          uploadResponsePromise
            .then(() => {
              setFileFieldData(fileFieldName, {
                uploadStatus: FULFILLED,
                blobName
              });
              if (onFileFulfilled) {
                onFileFulfilled(file, blobName);
              }
            })
            .catch(() => {
              setFileFieldData(fileFieldName, {
                uploadStatus: REJECTED
              });
            });

          return undefined;
        });
    });
  };

  const renderFileField = (fileFieldName: string, index: number) => {
    const fileFieldValue = fieldsRef.current?.value[index];

    const handleRemoveFile = () => {
      fieldsRef.current?.remove(index);
    };

    return (
      <li key={fileFieldName}>
        <Field
          subscription={{ data: true, value: true }}
          name={fileNameField(fileFieldName)}
        >
          {({ meta }) => {
            const data = meta.data as FileNameFieldData;
            const handleCancelUpload = () => {
              if (data.aborter) {
                // abort throws an exception, we don't want our app to crash
                try {
                  data.aborter.abort();
                } catch (err) {}
              }
              fieldsRef.current?.remove(index);
            };

            if (
              (data.uploadStatus === FULFILLED && fileFieldValue) ||
              fileFieldValue?.uploadStatus === FULFILLED
            ) {
              return renderUploadedFile({
                fileFieldValue,
                fileFieldName,
                onRemoveFile: handleRemoveFile
              });
            } else if (fileFieldValue) {
              return (
                <FileUploadProgress
                  loadedBytes={data.loadedBytes || 0}
                  totalBytes={fileFieldValue.file.size}
                  fileName={fileFieldValue.file.name}
                  onCancelUpload={handleCancelUpload}
                  uploadError={
                    data.uploadStatus === REJECTED
                      ? "Upload failed. Cancel and try again"
                      : undefined
                  }
                />
              );
            } else return null;
          }}
        </Field>
      </li>
    );
  };

  return (
    <FilePickerField<T>
      {...filePickerProps}
      onDropAccepted={handleDropAccepted}
    >
      {(props: any) => {
        // Note we cannot use useFieldArray since we render field async after dropping
        // files
        fieldsRef.current = props.fields;
        return (
          <>
            {!!props.fields.length && (
              <Fieldset legend={legend}>
                <Stack
                  as="ul"
                  tokens={{ childrenGap: 5 }}
                  styles={{
                    root: {
                      margin: 0,
                      padding: 0,
                      listStyle: "none"
                    }
                  }}
                >
                  {props.fields.map((fileFieldName: string, index: number) =>
                    renderFileField(fileFieldName, index)
                  )}
                </Stack>
              </Fieldset>
            )}
          </>
        );
      }}
    </FilePickerField>
  );
};

const fileNameField = (fileFieldName: string) => `${fileFieldName}.name`;

/**
 * Integrates the FilePickerField with Azure Blob Storage.
 * All selected files get uploaded to an Azure Blob Storage container.
 * While they are uploading,a progress indicator is displayed for each file.
 * Once they are uploaded, a text field allows to change the file name.
 *
 * The blob name is a unique random name. The filename is not uploaded as part of the Blob metadata
 * but its value is accessible from the Final Form field.
 */
