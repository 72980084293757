import { useRef } from "react";
import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  FieldItemStyles,
  getId,
  IInputProps,
  mergeStyleSets,
  useTheme
} from "@bps/fluent-ui";
import { identity } from "@libs/utils/utils.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import {
  ExposedFieldProps,
  getFieldErrorMessage
} from "@ui-components/form/FinalForm.tsx";
import {
  FormItemField,
  FormItemFieldProps
} from "@ui-components/form/FormItemField.tsx";
import { ReadOnlyTextField } from "@ui-components/form/read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";
import { ContactPicker } from "@ui-components/pickers/contact-picker/ContactPicker.tsx";
import { ContactPickerProps } from "@ui-components/pickers/contact-picker/ContactPicker.types.ts";
import { onResolveSuggestionItem as onResolveSuggestionItemWithExtraInfo } from "@ui-components/pickers/contact-picker/PatientPicker.tsx";

type HtmlElementType = any;

export type ContactPickerAdapterProps = Omit<
  ContactPickerProps,
  "defaultSelectedItems"
> &
  Pick<FormItemFieldProps, "required" | "label" | "actionButton"> &
  Pick<IInputProps, "placeholder" | "autoFocus"> & {
    as?: React.ComponentType<ContactPickerProps>;
    fieldItemStyle?: Partial<Pick<FieldItemStyles, "item" | "root">>;
    onChanged?: (id: string | undefined) => void;
  };

export type ContactPickerFieldProps = ExposedFieldProps<
  ContactPickerProps["selectedKey"],
  HtmlElementType
> &
  ContactPickerAdapterProps;

type InternalContactPickerAdapterProps = FieldRenderProps<
  ContactPickerProps["selectedKey"],
  HtmlElementType
> &
  ContactPickerAdapterProps;

const ContactPickerAdapter: React.FC<InternalContactPickerAdapterProps> = ({
  input,
  autoFocus,
  meta,
  styles,
  required,
  label,
  actionButton,
  disabled,
  as,
  placeholder,
  fieldItemStyle,
  onChanged,
  onResolveSuggestionItem,
  readOnly,
  ...pickerProps
}) => {
  const theme = useTheme();

  const _id = useRef<string>(getId(input.name));
  const { meta: formMeta } = useField(input.name);

  if (readOnly || formMeta?.data?.readOnly) {
    const getValueText = async (root: IRootStore) => {
      if (!input.value) {
        return "";
      }

      const contact = await root.practice.getContact(input.value);
      return contact.name || "";
    };
    return (
      <ReadOnlyTextField
        label={label}
        value={input.value}
        getValueText={getValueText}
      />
    );
  }

  const hasErrorMessage = !!getFieldErrorMessage(meta);

  const newStyles = mergeStyleSets(
    {
      text: hasErrorMessage && {
        borderColor: theme.semanticColors.errorText,
        selectors: {
          "&:focus, &:hover": {
            borderColor: theme.semanticColors.errorText
          },
          "&::after": {
            borderColor: theme.semanticColors.errorText
          }
        }
      }
    },
    styles
  );

  const handleChange = (id: string | undefined) => {
    input.onChange(id);
    onChanged && onChanged(id);
    if (!id) {
      // input re-obtains the focus on change when removing an item but onFocus is not triggered
      // https://github.com/OfficeDev/@fluentui/react/blob/b982a68f449a61b16429d1a025cd8a949177d954/packages/@fluentui/react/src/components/pickers/BasePicker.tsx#L875
      input.onFocus();
    }
  };

  const ContactPickerComponent = as || ContactPicker;

  return (
    <FormItemField
      name={input.name}
      required={required}
      disabled={disabled || formMeta?.data?.disabled}
      actionButton={actionButton}
      label={label}
      styles={fieldItemStyle}
      htmlFor={_id.current}
    >
      <ContactPickerComponent
        {...pickerProps}
        onChange={handleChange}
        disabled={disabled || formMeta?.data?.disabled}
        selectedKey={input.value}
        onResolveSuggestionItem={
          onResolveSuggestionItem ?? onResolveSuggestionItemWithExtraInfo
        }
        inputProps={{
          onBlur: input.onBlur,
          onFocus: input.onFocus,
          autoFocus,
          placeholder,
          id: _id.current,
          name: input.name
        }}
        styles={newStyles}
      />
    </FormItemField>
  );
};

/**
 * Use ContactPickerField to use ContactPicker with final-form, or one of its specialized
 * components such as PersonPickerField or PatientPickerField.
 * It also wraps the picker in a FormItemField to support label and error message.
 * @param props
 */
export const ContactPickerField: React.FunctionComponent<
  ContactPickerFieldProps
> = props => (
  <Field
    format={identity}
    parse={identity}
    {...props}
    component={ContactPickerAdapter}
  />
);
