import { ComponentType, FunctionComponent } from "react";

import { classNamesFunction, styled } from "@bps/fluent-ui";

import { getReadOnlyChoiceGroupStyles } from "./ReadOnlyChoiceGroup.styles.ts";
import {
  ReadOnlyChoiceGroupProps,
  ReadOnlyChoiceGroupStyles,
  ReadOnlyChoiceGroupStylesProps
} from "./ReadOnlyChoiceGroup.types.ts";

const getClassNames = classNamesFunction<
  ReadOnlyChoiceGroupStylesProps,
  ReadOnlyChoiceGroupStyles
>();

const ReadOnlyChoiceGroupBase: FunctionComponent<ReadOnlyChoiceGroupProps> = ({
  selectedKey,
  options,
  label,
  styles,
  className,
  theme
}) => {
  const classNames = getClassNames(styles, { className, theme });

  const selectedOption = options?.find(option => option.key === selectedKey);

  return (
    <div className={classNames.root}>
      {label && <span className={classNames.label}>{label}</span>}
      <div className={classNames.flexContainer}>
        {selectedOption && (
          <div className="choice-selected-option">
            <span>{selectedOption.text}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const ReadOnlyChoiceGroup = styled<
  ReadOnlyChoiceGroupProps,
  ReadOnlyChoiceGroupStylesProps,
  ReadOnlyChoiceGroupStyles
>(ReadOnlyChoiceGroupBase, getReadOnlyChoiceGroupStyles) as ComponentType<
  Omit<ReadOnlyChoiceGroupProps, "theme">
>;
