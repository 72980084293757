import { Field, FieldRenderProps } from "react-final-form";

import {
  ButtonsGroupChoice,
  ButtonsGroupChoiceProps,
  FieldItemProps,
  IStyle,
  mergeFuncStyles,
  useTheme
} from "@bps/fluent-ui";

import { identity } from "../../libs/utils/utils.ts";
import { ExposedFieldProps, getFieldErrorMessage } from "./FinalForm.tsx";
import { FormItemField, FormItemFieldProps } from "./FormItemField.tsx";

type HtmlElementType = HTMLElement | HTMLInputElement;

export type ButtonsChoiceGroupAdapterProps<T> = Omit<
  ButtonsGroupChoiceProps<T>,
  "multiselect"
> &
  Pick<FormItemFieldProps, "required" | "label" | "name" | "disabled"> & {
    fieldItemStyles?: FieldItemProps["styles"];
  };

export type ButtonsChoiceGroupFieldProps<T = string> = ExposedFieldProps<
  ButtonsGroupChoiceProps<any>["value"],
  HtmlElementType
> &
  Omit<ButtonsChoiceGroupAdapterProps<T>, "value" | "onChange">;

type InternalButtonsChoiceGroupAdapter<T> = FieldRenderProps<
  ButtonsGroupChoiceProps<any>["value"],
  HtmlElementType
> &
  ButtonsChoiceGroupAdapterProps<T>;

const ButtonsChoiceGroupAdapter = <T extends any>(
  props: InternalButtonsChoiceGroupAdapter<T>
) => {
  const theme = useTheme();
  const {
    input,
    meta,
    required,
    label,
    onChange,
    fieldItemStyles,
    styles,
    disabled,
    ...buttonsChoiceGroupProps
  } = props;

  const onChangeInternal = (selectedKeys: T[]) => {
    input.onChange(selectedKeys);

    if (onChange) {
      onChange(selectedKeys);
    }
  };

  const getErrorButtonStyles = (): IStyle => {
    if (!!getFieldErrorMessage(meta)) {
      return {
        color: theme.semanticColors.errorText,
        borderColor: theme.semanticColors.errorText
      };
    }
    return {};
  };

  return (
    <FormItemField
      name={input.name}
      required={required}
      label={label}
      disabled={disabled}
    >
      <ButtonsGroupChoice<T>
        {...buttonsChoiceGroupProps}
        multiselect
        onChange={onChangeInternal}
        value={input.value as T}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        styles={mergeFuncStyles(styles, { text: getErrorButtonStyles() })}
      />
    </FormItemField>
  );
};

export const ButtonsChoiceGroupField = <T extends any = string>(
  props: ButtonsChoiceGroupFieldProps<T>
) => (
  <Field
    parse={identity}
    format={identity}
    {...props}
    component={ButtonsChoiceGroupAdapter}
  />
);
