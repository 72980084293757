import { IStackStyles } from "@bps/fluent-ui";

export const hideUnlessPrintingStyles: IStackStyles = {
  root: {
    display: "none",
    "@media print": { display: "block" }
  }
};

export const showUnlessPrintingStyles: IStackStyles = {
  root: {
    "@media print": { display: "none" }
  }
};
