import {
  IBasePickerStyleProps,
  IBasePickerStyles,
  IStyleFunctionOrObject,
  ITag,
  ITheme,
  mergeStyleSets
} from "@bps/fluent-ui";

export const formatUndefined =
  (isArray: boolean) => (x: string | string[] | undefined) => {
    // if we do not pass initial values in form the format value
    // is undefined, by default final-form converts it to empty string,
    // but in a case of array is must be empty array
    if (typeof x === "undefined") {
      return isArray ? [] : "";
    }
    return x;
  };

export const minimumSearchLengthFilter = (
  searchText: string,
  minimumSearchLength: number,
  items: ITag[]
) => {
  if (searchText.length < minimumSearchLength) {
    return [];
  }

  const searchFilter = searchText.toUpperCase();
  const searchResult: ITag[] = items.filter(x =>
    x.name
      .toUpperCase()
      .split(" ")
      .some(item => item.startsWith(searchFilter))
  );
  return searchResult;
};

export const getNewPickerStyles = (
  theme: ITheme,
  stylesOptions: { hasErrorMessage: boolean; disabled?: boolean },
  styles:
    | IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles>
    | undefined
) =>
  mergeStyleSets(
    {
      text: {
        ...(stylesOptions.disabled && {
          "& .ms-Persona-initials": {
            backgroundColor: theme.semanticColors.disabledText
          },
          "& .ms-Persona-primaryText": {
            color: theme.semanticColors.disabledText
          },
          "& .ms-TagItem-text": {
            color: theme.semanticColors.disabledText
          }
        }),
        ...(stylesOptions.hasErrorMessage && {
          borderColor: theme.semanticColors.errorText,
          selectors: {
            "&:focus, &:hover": {
              borderColor: theme.semanticColors.errorText
            },
            "&::after": {
              borderColor: theme.semanticColors.errorText
            }
          }
        })
      }
    },
    styles
  );
