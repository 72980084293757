import { ReactNode } from "react";
import { LinkProps as RouterLinkProps, To, useHref } from "react-router-dom";

import { ActionButton, IButtonProps } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { isModifiedEvent, normalizeToLocation } from "./utils.ts";

type LinkButtonProps = IButtonProps & Partial<Pick<RouterLinkProps, "to">>;

interface HrefWrapperProps {
  to: To;
  children: (href: string) => ReactNode;
}

const HrefWrapper: React.FC<HrefWrapperProps> = ({ to, children }) => {
  const href = useHref(to);

  return <>{children(href)}</>;
};

/**
 * Replicates what React Router Link does while wrapping the Fluent UI Button.
 * See https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js
 *
 * The current version of Link doesn't support custom components and makes it unsuitable to integrate
 * with Fluent UI Link. This is why the logic is extracted and replicated here.
 *
 * @param props
 */
export const LinkButton = (props: LinkButtonProps) => {
  const { to, ...rest } = props;

  const { routing: history } = useStores();

  const location = to ? normalizeToLocation(to) : undefined;

  const onClick: IButtonProps["onClick"] = event => {
    try {
      if (props.onClick) {
        props.onClick(event);
      }
    } catch (ex) {
      event.preventDefault();
      throw ex;
    }

    if (
      location &&
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      (!props.target || props.target === "_self") && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      navigate(location);
    }
  };

  const navigate = (to: To) => {
    const method = history.push;
    method(to);
  };

  return to ? (
    <HrefWrapper to={to}>
      {href => <ActionButton {...rest} onClick={onClick} href={href} />}
    </HrefWrapper>
  ) : (
    <ActionButton {...rest} onClick={onClick} />
  );
};
