import { FontIcon, mergeStyles, useTheme } from "@bps/fluent-ui";

interface PickerIconProps {
  iconName: string | undefined;
}

export const PickerIcon: React.FunctionComponent<PickerIconProps> = ({
  iconName
}) => {
  const theme = useTheme();
  const iconClass = mergeStyles(
    {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      width: 32,
      minHeight: 32,
      top: 0,
      left: 0,
      fontSize: "16px",
      color: theme.semanticColors.link,
      cursor: "text"
    },
    "picker-icon"
  );

  return (
    <>{!!iconName && <FontIcon iconName={iconName} className={iconClass} />}</>
  );
};
