import { ReactNode } from "react";
import { Field } from "react-final-form";

/**
 *
 * Used to render fields based on a condition
 */

type PredicateCondition<FieldValue extends any> = (
  value: FieldValue
) => boolean;

export const FieldCondition = <FieldValue extends any>({
  when,
  is,
  children
}: {
  when: string;
  is: FieldValue | PredicateCondition<FieldValue>;
  children:
    | React.ReactNode
    | React.ReactNode[]
    | ((val: FieldValue) => ReactNode);
}) => {
  const condition: PredicateCondition<FieldValue> =
    typeof is === "function"
      ? (is as PredicateCondition<FieldValue>)
      : (value: FieldValue) => value === is;

  const getChildren = (val: FieldValue) => {
    if (typeof children === "function") {
      return children(val);
    } else {
      return children;
    }
  };

  return (
    <Field<FieldValue> name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (condition(value) ? getChildren(value) : null)}
    </Field>
  );
};
