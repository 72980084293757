import { SubmitActionCode } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { AutofillDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { messageWithData } from "@libs/validation/messageWithData.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { AutofillQuickAddValues } from "./AutofillQuickAddEditDialog.types.ts";

export class AutofillQuickAddValidator extends Validator<AutofillQuickAddValues> {
  constructor(
    values: AutofillQuickAddValues,
    autofills: AutofillDto[],
    editingAutoFill?: AutofillDto
  ) {
    super();
    this.forField("name", [required()]);
    this.forField("shortcut", [
      required(),
      maxLength(20),
      (val: string) => {
        // Allow characters, numbers, _ and - only
        const regex = new RegExp("^[a-zA-Z0-9_-]+$");
        return regex.test(val) ? undefined : ValidationMessages.allowShortcut;
      },
      (val: string) => {
        // Check duplicate shortcut
        return autofills.some(
          x =>
            x.shortcut === val &&
            x.id !== editingAutoFill?.id &&
            values.availability === x.documentStatus
        )
          ? messageWithData(ValidationMessages.duplicatedShortcut, val)
          : undefined;
      },
      (val: string) => {
        // check duplicated private (copy) shorcut of a shared autofill
        if (
          editingAutoFill &&
          editingAutoFill.documentStatus ===
            SubmitActionCode.PublishToEveryoneCode
        ) {
          return autofills.some(
            x =>
              x.shortcut === val &&
              x.documentStatus === SubmitActionCode.PublishToSelfCode
          )
            ? messageWithData(
                ValidationMessages.duplicatedShortcutOnUpdatingSharedAutofill,
                val
              )
            : undefined;
        }
        return;
      }
    ]);

    this.forField("visibility", required());
    this.forField("content", required());
  }
}
