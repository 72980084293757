import { FunctionComponent } from "react";

import { useFieldContext, useFieldRegister } from "@bps/fluent-ui";
import {
  LocationsSelect,
  LocationsSelectProps
} from "@ui-components/selects/LocationsSelect.tsx";

export interface LocationsSelectFilterItemProps
  extends Omit<
    LocationsSelectProps,
    "selectedKeys" | "onChangeSelectedKeys" | "onFocus" | "onBlur"
  > {
  name: string;
}
export const LocationsSelectFilterItem: FunctionComponent<
  LocationsSelectFilterItemProps
> = ({ name, ...usersSelectProps }) => {
  useFieldRegister(name);

  const { value, setFieldValue, focusField, blurField } = useFieldContext<
    string | undefined | string[]
  >(name);

  return (
    <LocationsSelect
      hideSearchOption
      showIcon={false}
      showInactiveLocation
      disableInactiveLocation
      {...usersSelectProps}
      onFocus={focusField}
      onBlur={blurField}
      selectedKeys={value}
      onChangeSelectedKeys={setFieldValue}
    />
  );
};
