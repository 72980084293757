import { FunctionComponent } from "react";
import { FormSpy } from "react-final-form";

import {
  confirm,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  IButtonProps,
  mergeStyleSets,
  SplitButton,
  Stack,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";

import {
  FORM_PROMPT_DEFAULT_MESSAGE,
  FORM_SUBMIT_PROMPT_DEFAULT_MESSAGE
} from "../FinalForm.tsx";
import { defaultButtonsText } from "../prompt/Prompt.tsx";
import {
  FormSubmitButtonsProps,
  submitButtonsText
} from "./FormSubmitButtons.types.ts";

const defaultProps: Partial<FormSubmitButtonsProps<object>> = {
  disableSubmitOnPristine: true,
  buttonsGap: 8
};

const checkIsSubmitDisabled = (options: {
  submitting: boolean;
  isDirty: boolean;
  isFullyFilled?: boolean;
  disableSubmitOnPristine?: boolean;
  isFormInvalid?: boolean;
}): boolean => {
  return (
    options.submitting ||
    (options.disableSubmitOnPristine && !options.isDirty) ||
    (typeof options.isFullyFilled !== "undefined" && !options.isFullyFilled) ||
    (typeof options.isFormInvalid !== "undefined" && !options.isFormInvalid)
  );
};

export const FormSubmitButtons: FunctionComponent<
  FormSubmitButtonsProps<any>
> = props => {
  const {
    onCancel,
    onSubmit,
    extraPromptConditionOnCancel,
    extraPromptConditionOnSubmit,
    submitConfirmProps,
    promptOnCancel,
    promptOnSubmit,
    submitButtonProps,
    cancelButtonProps,
    hideButtonsSeparator,
    styles,
    disableSubmitOnFormNotFullyFilled,
    disableSubmitOnPristine,
    disableCancelOnPristine,
    disableSubmitOnFormInvalid,
    hideSubmit,
    hideCancel,
    ...restProps
  } = props;

  const theme = useTheme();

  return (
    <FormSpy
      subscription={{
        pristine: true,
        dirtySinceLastSubmit: true,
        submitSucceeded: true,
        submitting: true,
        values: true,
        dirtyFields: true,
        dirty: true,
        touched: true,
        modified: true,
        visited: true
      }}
    >
      {({ form, submitting, dirty, values }) => {
        // See https://reacttraining.com/react-router/core/api/Prompt/message-func
        const { touched, modified, visited, errors } = form.getState();
        const registeredFields = touched ?? modified ?? visited;

        const isFullyFilled: boolean | undefined =
          disableSubmitOnFormNotFullyFilled &&
          registeredFields &&
          Object.keys(registeredFields).length === Object.keys(values).length;

        const isFormInvalid: boolean | undefined =
          disableSubmitOnFormInvalid &&
          errors &&
          Object.keys(errors).length === 0;

        const handleCancel = async () => {
          if (promptOnCancel) {
            if (
              dirty ||
              (extraPromptConditionOnCancel &&
                extraPromptConditionOnCancel(form))
            ) {
              const isConfirmed = await confirm({
                dialogContentProps: {
                  subText: FORM_PROMPT_DEFAULT_MESSAGE
                },
                confirmButtonProps: {
                  text: defaultButtonsText.confirm
                },
                cancelButtonProps: {
                  text: defaultButtonsText.cancel
                }
              });

              if (!isConfirmed) {
                return;
              }
            }
          }

          if (onCancel) {
            onCancel(form);
          } else {
            form.reset();
          }
        };

        const isSubmitDisabled = checkIsSubmitDisabled({
          submitting,
          isDirty: dirty,
          isFullyFilled,
          disableSubmitOnPristine,
          isFormInvalid
        });

        const onSubmitClick: React.MouseEventHandler<
          HTMLButtonElement
        > = async ev => {
          if (submitting) {
            ev.preventDefault();
            ev.stopPropagation();
            return;
          }

          if (submitButtonProps?.onClick) {
            submitButtonProps?.onClick(ev);
            if (ev.isDefaultPrevented()) {
              return;
            }
          }

          if (promptOnSubmit) {
            if (
              !extraPromptConditionOnSubmit ||
              extraPromptConditionOnSubmit(form)
            ) {
              const isConfirmed = await confirm({
                dialogContentProps: {
                  subText: FORM_SUBMIT_PROMPT_DEFAULT_MESSAGE
                },
                confirmButtonProps: {
                  text: submitButtonsText.confirm
                },
                cancelButtonProps: {
                  text: submitButtonsText.cancel
                },
                ...submitConfirmProps
              });

              if (!isConfirmed) {
                return;
              }
            }
          }

          if (onSubmit) {
            onSubmit(form);
          } else {
            form.submit();
          }
        };

        const isCancelDisabled = disableCancelOnPristine && !dirty;
        const submitButtonCommonProps: IButtonProps = {
          ...dataAttribute(DataAttributes.Element, "submit-button"),
          disabled: isSubmitDisabled,
          iconProps: { iconName: "Save" },
          text: "Save",
          primary: true,
          ...submitButtonProps,
          onClick: onSubmitClick
        };

        const cancelButtonCommonProps: IButtonProps = {
          ...dataAttribute(DataAttributes.Element, "cancel-button"),
          disabled: isCancelDisabled,
          onClick: handleCancel,
          text: !submitting ? "Cancel" : "Close",
          ...cancelButtonProps
        };

        return (
          <Stack
            horizontal
            tokens={{
              childrenGap: props.buttonsGap
            }}
            horizontalAlign="end"
            styles={mergeStyleSets(
              {
                root: {
                  zIndex: 101,
                  borderTop: hideButtonsSeparator ? "none" : "1px solid ",
                  borderTopColor: theme.palette.neutralLight,
                  backgroundColor: "inherit",
                  paddingTop: 24,
                  // if no top border, total gap should be 40px (16 + 24)
                  marginTop: hideButtonsSeparator ? 16 : 24
                }
              },
              typeof styles === "function" ? styles(dirty, form) : styles
            )}
            {...restProps}
          >
            {props.extraActionsBefore &&
              props.extraActionsBefore(form, isSubmitDisabled)}

            {!hideSubmit && (
              <Stack.Item styles={{ root: { flexShrink: 0 } }}>
                <TooltipHost {...submitButtonProps?.tooltipProps}>
                  {submitButtonProps?.items ? (
                    <SplitButton
                      disableContextMenuButton={isSubmitDisabled}
                      {...submitButtonCommonProps}
                      items={submitButtonProps.items}
                    />
                  ) : (
                    <DefaultButton
                      {...submitButtonCommonProps}
                      onClick={onSubmitClick}
                    />
                  )}
                </TooltipHost>
              </Stack.Item>
            )}
            {props.extraActionsBetween &&
              props.extraActionsBetween(form, isSubmitDisabled)}
            {!hideCancel && (
              <Stack.Item styles={{ root: { flexShrink: 0 } }}>
                {cancelButtonProps?.items ? (
                  <SplitButton
                    {...cancelButtonCommonProps}
                    items={cancelButtonProps.items}
                  />
                ) : (
                  <DefaultButton {...cancelButtonCommonProps} />
                )}
              </Stack.Item>
            )}

            {props.extraActionsAfter}
          </Stack>
        );
      }}
    </FormSpy>
  );
};

FormSubmitButtons.defaultProps = defaultProps;
