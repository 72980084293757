import { FunctionComponent, ReactNode, useEffect, useRef } from "react";

import {
  HideStack,
  IconButton,
  IMessageBarProps,
  Link,
  mergeFuncStyles,
  MessageBar,
  MessageBarType,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  ConflictError,
  HttpError,
  NotFoundError,
  RequestError,
  ServerError
} from "@bps/http-client";
import { DateTime } from "@bps/utils";
import { DuplicateError } from "@libs/api/errors/DuplicateError.ts";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const DEFAULT_ERROR_TEXT = "An error request occurred.";
export type ErrorAlertProps = {
  error?: string | Error | HttpError;
  showReloadLink?: boolean;
  onReload?: () => void;
} & IMessageBarProps;

const getErrorMessage = (error: ErrorAlertProps["error"]) => {
  if (error instanceof HttpError) return error.detail ?? error.detail;
  if (error instanceof Error) {
    return error.message;
  }
  return error ?? DEFAULT_ERROR_TEXT;
};

const getStackTrace = (error: ErrorAlertProps["error"]) => {
  if (error instanceof Error) return error;
  return undefined;
};

export const ErrorAlert: FunctionComponent<ErrorAlertProps> = ({
  error,
  showReloadLink,
  styles,
  onReload,
  ...messageBarProps
}) => {
  const appInsights = useAppInsightsContext();
  const errorDetailsRef = useRef<HTMLTextAreaElement | null>(null);
  const message = getErrorMessage(error);
  const stackTrace = getStackTrace(error);

  useEffect(
    () => {
      const timestamp = DateTime.now().toISO();

      if (error && message) {
        const timestamp = DateTime.now().toISO();
        // eslint-disable-next-line no-console
        console.log(`[${timestamp}] Error message:`, message);
      }
      if (stackTrace) {
        // eslint-disable-next-line no-console
        console.error(`[${timestamp}] Stack trace:`, stackTrace);

        appInsights.trackException(
          { exception: stackTrace },
          {
            name: "OMNI:CONSOLE ERROR"
          }
        );
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [error, message]
  );

  if (!error) return null;

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onReload && onReload();
    window.location.reload();
  };

  const shouldShowReloadLink = showReloadLink || error instanceof ConflictError;

  const formatMessage = (): React.ReactNode => {
    return (
      <span className="alert-text">
        {message as ReactNode}
        {shouldShowReloadLink && (
          <Link href="#" onClick={onClick} styles={{ root: { marginLeft: 5 } }}>
            Refresh page
          </Link>
        )}
        <br />
      </span>
    );
  };

  const traceId =
    error instanceof ServerError ||
    error instanceof NotFoundError ||
    error instanceof ConflictError ||
    error instanceof RequestError ||
    error instanceof DuplicateError
      ? error.traceId
      : undefined;

  let errorDetails = message;

  if (traceId) {
    errorDetails += ` Trace ID: ${traceId}`;
  }
  if (error instanceof ServerError && error.message) {
    errorDetails += ` Data: ${error.message}`;
  }

  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      {...messageBarProps}
      styles={mergeFuncStyles(
        {
          root: { minHeight: 40, width: "auto" },
          text: { marginTop: 4 },
          content: { height: "100%" },
          icon: { display: "flex", alignItems: "center" }
        },
        styles
      )}
    >
      <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 4 }}>
        <Text nowrap>{formatMessage()} </Text>
        <IconButton
          title="Copy error details to clipboard"
          ariaLabel="Copy error details to clipboard"
          iconProps={{ iconName: "Copy" }}
          styles={{ root: { alignSelf: "start" } }}
          onClick={() => {
            errorDetailsRef.current?.select();
            document.execCommand("copy");
          }}
        />
      </Stack>

      <HideStack when={true}>
        <textarea
          readOnly
          ref={r => {
            errorDetailsRef.current = r;
          }}
          className="visually-hidden"
          value={errorDetails as string}
        />
      </HideStack>
    </MessageBar>
  );
};
