import { LinkProps as RouterLinkProps, To } from "react-router-dom";

import { ILinkProps, Link } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import {
  isAbsoluteUrl,
  isModifiedEvent,
  normalizeToLocation
} from "./utils.ts";

export type NavigateProps = ILinkProps &
  Partial<Pick<RouterLinkProps, "to" | "replace" | "state">> & {};

/**
 * Wraps the UI Fluent ActionButton to support navigating to internal URLs through React
 * Router.
 * Use it in the same manner as using React Router link with all the props that
 * Fluent UI ActionButton supports:
 * <Link styles={{root: { color: "blue" }}} to={"/path/resource"} text="Go to resource"/>
 */
export const Navigate = (props: NavigateProps) => {
  const { to: toProps, replace, href: hrefProps, state, ...rest } = props;

  //to check using routing to get history now as no useHistory hook
  const { routing } = useStores();

  const to =
    toProps || (hrefProps && !isAbsoluteUrl(hrefProps) ? hrefProps : undefined);

  const location = to ? normalizeToLocation(to) : undefined;

  const href = location ? routing.createHref(location) : hrefProps;

  const onClick: ILinkProps["onClick"] = event => {
    try {
      if (props.onClick) {
        props.onClick(event);
      }
    } catch (ex) {
      event.preventDefault();
      throw ex;
    }

    if (
      location &&
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      (!props.target || props.target === "_self") && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      navigate(location);
    }
  };

  const navigate = (to: To) => {
    const method = replace ? routing.replace : routing.push;
    method(to, state);
  };

  return <Link {...rest} onClick={onClick} href={href} />;
};
