import { useForm } from "react-final-form";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";

import { SubmissionFormProps } from "./SubmissionForm.types.ts";

interface FormSubmitWarningMessageProps<FormValues extends object = object>
  extends Pick<
    SubmissionFormProps<FormValues>,
    "warning" | "warningMessageBarProps"
  > {}

export const FormSubmitWarningMessage = <FormValues extends object = object>({
  warning,
  warningMessageBarProps
}: FormSubmitWarningMessageProps<FormValues>) => {
  const form = useForm<FormValues>();

  const warningToRender =
    typeof warning === "function" ? warning(form) : warning;
  if (!warningToRender) return null;

  return (
    <MessageBar
      {...warningMessageBarProps}
      messageBarType={MessageBarType.warning}
    >
      {warningToRender}
    </MessageBar>
  );
};
