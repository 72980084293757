import { ITheme, mergeStyles } from "@bps/fluent-ui";

export const getDisableStyles = (theme: ITheme, disable: boolean) => {
  return mergeStyles({
    "& .quill": {
      backgroundColor: disable
        ? theme.semanticColors.disabledBackground
        : undefined
    }
  });
};
