import { FunctionComponent } from "react";

import { useFieldContext, useFieldRegister } from "@bps/fluent-ui";

import { ContactsSelectProps } from "../../selects/ContactsSelect.tsx";
import { PatientsSelect } from "../../selects/PatientsSelect.tsx";

interface PatientsSelectFilterItemProps
  extends Omit<
    ContactsSelectProps,
    "selectedKeys" | "onChangeSelectedKeys" | "onFocus" | "onBlur"
  > {
  name: string;
}

export const PatientsSelectFilterItem: FunctionComponent<
  PatientsSelectFilterItemProps
> = ({ name, ...patientsSelectProps }) => {
  useFieldRegister(name);

  const { value, setFieldValue, focusField, blurField } = useFieldContext<
    string | undefined | string[]
  >(name);

  return (
    <PatientsSelect
      {...patientsSelectProps}
      onFocus={focusField}
      onBlur={blurField}
      selectedKeys={value as any}
      onChangeSelectedKeys={setFieldValue}
    />
  );
};
