import {
  FontWeights,
  getFilterBarStyles as getBaseFilterBarStyles
} from "@bps/fluent-ui";

import { FilterBarStyles, FilterBarStylesProps } from "./FilterBar.types.ts";

export const getFilterBarStyles = ({
  theme
}: FilterBarStylesProps): FilterBarStyles => {
  const { select } = getBaseFilterBarStyles({ theme });
  return {
    peoplePicker: {
      root: {
        selectors: {
          "& +.picker-icon": {
            color: theme.palette.neutralSecondaryAlt
          }
        }
      },
      "& .ms-BasePicker-text": {
        border: "none",
        background: theme.palette.neutralLighterAlt
      },
      "& .ms-Persona-primaryText": {
        fontWeight: FontWeights.semibold
      }
    },
    select
  };
};
