import { observer } from "mobx-react-lite";
import { fromPromise, PENDING, REJECTED } from "mobx-utils";
import { FunctionComponent, useEffect, useMemo, useRef } from "react";

import { dataAttribute, DataAttributes, Overlay } from "@bps/fluent-ui";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import type { RootStore } from "@stores/root/RootStore.ts";
import { ErrorAlert } from "@ui-components/Alert.tsx";

/**
 * Component that accepts a render function as children and will pass the resolved value
 * from the fetch. In the meantime, pending and rejected used the configured fallback and renderError
 * props respectively.
 * @param props
 */

type BaseDataFetcherProps<T> = {
  fetch: (root: RootStore) => PromiseLike<T>;
  refetchId?: string | number;
};

export type DataFetcherProps<T> = {
  children: (data: T) => React.ReactNode;
  fallback?: React.ReactNode;
  renderError?: (error: Error) => React.ReactNode;
  noExceptionsHandlers?: never;
} & BaseDataFetcherProps<T>;

export type DataFetcherPropsNoExceptionsHandlers<T> = {
  children: (
    data: T | undefined,
    loading?: boolean,
    error?: Error
  ) => React.ReactNode;
  fallback?: never;
  renderError?: never;
  noExceptionsHandlers: true;
} & BaseDataFetcherProps<T>;

const BaseDataFetcherUntyped: FunctionComponent<
  DataFetcherProps<any> | DataFetcherPropsNoExceptionsHandlers<any>
> = observer(
  ({
    fetch,
    children,
    fallback = (
      <Overlay
        {...dataAttribute(DataAttributes.Element, "overlay")}
        styles={{ root: { zIndex: 100 } }}
      />
    ),
    renderError = (error: Error) => <ErrorAlert error={error} />,
    noExceptionsHandlers,
    refetchId
  }) => {
    const root = useStores();
    const unmounted = useRef<boolean>(false);

    const promiseWrapper = useMemo(
      () => fromPromise(fetch(root)),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [refetchId]
    );

    useEffect(() => {
      return () => {
        unmounted.current = true;
      };
    }, []);

    if (unmounted.current) {
      return null;
    }

    if (noExceptionsHandlers) {
      return (
        <>
          {children(
            promiseWrapper.value,
            promiseWrapper.state === PENDING,
            promiseWrapper.state === REJECTED
              ? (promiseWrapper.value as Error)
              : undefined
          )}
        </>
      );
    } else {
      if (promiseWrapper) {
        return promiseWrapper.case({
          pending: () => <>{fallback}</>,
          rejected: e => <>{renderError(e)}</>,
          fulfilled: v => <>{children(v)}</>
        });
      } else {
        return null;
      }
    }
  }
);

export const DataFetcher = <T extends any>(
  props: DataFetcherProps<T> | DataFetcherPropsNoExceptionsHandlers<T>
) => {
  return <BaseDataFetcherUntyped {...props} />;
};

/**
 * HOC that will only render the component when the fetch promise resolves.
 * By default, pending promise will show an overlay and rejected promise will
 * show an error message.
 * @param fetch function that takes the root store and returns a promise.
 * It is also possible to return an array of promises and withFetch will combine them
 * into Promise.all
 * @param Component the target component
 * @param options Ability to override the renderin in pending and rejected modes.
 */
export const withFetch = <T extends object, P extends any>(
  fetch: (root: RootStore) => Promise<any> | Promise<any>[],
  Component: React.ComponentType<P>,
  {
    fallback,
    renderError
  }: Pick<DataFetcherProps<T>, "fallback" | "renderError"> = {}
): React.ComponentType<P> => {
  const normalizedFetch = (root: RootStore) => {
    const result = fetch(root);
    if (Array.isArray(result)) {
      return Promise.all(result);
    }
    return result;
  };

  return (props: P) => {
    return (
      <DataFetcher
        fetch={normalizedFetch}
        fallback={fallback}
        renderError={renderError}
      >
        {() => <Component {...(props as any)} />}
      </DataFetcher>
    );
  };
};

/**
 * See withFetch. The difference is that the resolved value will be passed as prop
 * to the component.
 */
export const withFetchPassThrough = <T extends object, P extends T>(
  fetch: (root: IRootStore) => PromiseLike<T>,
  Component: React.ComponentType<P>,
  {
    fallback,
    renderError
  }: Pick<DataFetcherProps<T>, "fallback" | "renderError"> = {}
): React.ComponentType<Omit<P, keyof T>> => {
  return (props: Omit<P, keyof T>) => {
    return (
      <DataFetcher fetch={fetch} fallback={fallback} renderError={renderError}>
        {data => <Component {...(props as any)} {...data} />}
      </DataFetcher>
    );
  };
};
