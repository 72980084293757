import { ConvertToSinglePickerProps } from "@bps/fluent-ui";
import { User } from "@stores/core/models/User.ts";
import { extendToSinglePersonaPicker } from "@ui-components/pickers/extendToSinglePersonaPicker.tsx";

import {
  MultiBookableUserPicker,
  MultiBookableUserPickerProps
} from "./MultiBookableUserPicker.tsx";

export type BookableUserPickerProps = ConvertToSinglePickerProps<
  MultiBookableUserPickerProps,
  User
>;

/**
 * Bookable User Picker is a component used to pick a single user (isProvider) from a list.
 */
export const BookableUserPicker = extendToSinglePersonaPicker(
  MultiBookableUserPicker
) as React.ComponentType<BookableUserPickerProps>;
