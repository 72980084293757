import React from "react";
import { useFormState } from "react-final-form";

import { isDeepEmptyUtils } from "@libs/utils/isDeepEmpty.utils.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  FormStatusIndicatorBadge,
  FormStatusIndicatorBadgeProps
} from "./FormStatusIndicatorBadge.tsx";

interface FormStatusIndicatorProps<T extends object>
  extends Omit<FormStatusIndicatorBadgeProps, "valid"> {
  validator: Validator<T>;
}

export const FormStatusIndicatorBase = <T extends object>(
  props: FormStatusIndicatorProps<T>
) => {
  const formState = useFormState<T>({ subscription: { values: true } });
  const { validator, ...rest } = props;

  const valid = isDeepEmptyUtils(props.validator.validate(formState.values));

  return <FormStatusIndicatorBadge valid={valid} {...rest} />;
};

export const FormStatusIndicator = React.memo(
  FormStatusIndicatorBase
) as typeof FormStatusIndicatorBase;
