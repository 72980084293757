import React from "react";

import {
  FontSizes,
  IButtonProps,
  IconButton,
  mergeStyleSets,
  useTheme
} from "@bps/fluent-ui";

type DeleteButtonProps = Omit<IButtonProps, "iconProps">;

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  styles,
  ...props
}) => {
  const theme = useTheme();
  return (
    <IconButton
      {...props}
      iconProps={{ iconName: "delete" }}
      styles={mergeStyleSets(
        {
          icon: {
            color: theme.palette.themePrimary,
            fontSize: FontSizes.size16
          }
        },
        styles
      )}
    />
  );
};
