import { User } from "@stores/core/models/User.ts";

import { DataFetcher, DataFetcherProps } from "./data-fetcher/DataFetcher.tsx";

type UserFetcherProps = Omit<DataFetcherProps<User>, "fetch"> &
  (
    | {
        userId: string;
        username?: never;
      }
    | { userId?: never; username: string }
  );
export const UserFetcher: React.FC<UserFetcherProps> = props => {
  const { userId, username, ...restProps } = props;

  if (userId) {
    return (
      <DataFetcher<User>
        refetchId={userId}
        fetch={({ core }) => core.getUser(userId)}
        {...restProps}
      />
    );
  }

  if (username) {
    return (
      <DataFetcher<User>
        refetchId={username}
        fetch={({ core }) => core.getUserByUserName(username)}
        {...restProps}
      />
    );
  }

  return null;
};
