import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useRef, useState } from "react";

import { Heading, PrimaryButton } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import {
  AutofillDialogPurposeType,
  CorrespondenceType,
  DocumentContentType,
  DocumentExtensionType,
  DocumentTabStatus,
  PurposeType,
  SubmitActionCode,
  TemplateTypeCode,
  TemplateVisibility
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  AutofillDto,
  TemplateDto
} from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { Template } from "@stores/documents/models/Template.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { AutofillQuickAddValues } from "./AutofillQuickAddEditDialog.types.ts";
import { AutofillQuickAddForm } from "./AutofillQuickAddEditForm.tsx";
import { AutofillQuickAddValidator } from "./AutofillQuickAddEditValidator.ts";

interface AutofillQuickAddDialogBaseProps {
  loading?: boolean;
  error: Error | undefined;
  data: {
    isDialogHidden: boolean;
    onDismiss: () => void;
  };
}

interface AutofillQuickAddDialogProps {
  isDialogHidden: boolean;
  onDismiss: () => void;
}

export const AutofillQuickAddEditDialogBase: FunctionComponent<AutofillQuickAddDialogBaseProps> =
  observer(({ loading, error, data }) => {
    const { document, userExperience } = useStores();
    const { ui } = userExperience;
    const { editingAutoFill } = ui;
    const { isDialogHidden, onDismiss } = data;
    const withEditor = useRef<boolean>(false);
    const [autofillDialogPurpose, setAutofillDialogPurpose] =
      useState<AutofillDialogPurposeType>(AutofillDialogPurposeType.NEW);

    const autofills = Array.from(document.autofills.values());

    if (isDialogHidden) return null;

    const isUpdatingSharedAutofill =
      editingAutoFill &&
      editingAutoFill.documentStatus === SubmitActionCode.PublishToEveryoneCode;

    const templateExtensions = Array.from(
      userExperience.templateExtensionMap.values()
    );

    const selectedTemplateExtension = templateExtensions.find(
      x => x.templateId === editingAutoFill?.id
    );

    const isEditingComplexAutofill = selectedTemplateExtension?.isComplex;

    const getAutofillTemplateContent = async (
      formValueContent: string,
      editingAutoFill?: AutofillDto
    ) => {
      if (editingAutoFill && isEditingComplexAutofill) {
        const renderedContent = await document.renderTemplate(
          editingAutoFill.id,
          {
            skipMerge: true
          }
        );
        return renderedContent.content;
      }
      return formValueContent;
    };

    const getTemplateDto = async (val: AutofillQuickAddValues) => {
      const content = await getAutofillTemplateContent(
        val.content ?? "",
        editingAutoFill
      );

      const templateDto: TemplateDto = {
        name: val.name,
        shortcut: val.shortcut,
        documentType: CorrespondenceType.Autofill,
        isCustom: true,
        isSystem: false,
        documentStatus: isUpdatingSharedAutofill
          ? SubmitActionCode.PublishToSelfCode
          : val.availability,
        isAdmin:
          val.visibility === TemplateVisibility.Both ||
          val.visibility === TemplateVisibility.Admin,
        isClinical:
          val.visibility === TemplateVisibility.Both ||
          val.visibility === TemplateVisibility.Clinical,
        templateFormat: isEditingComplexAutofill
          ? DocumentContentType.Sfdt
          : DocumentExtensionType.Html,
        id:
          editingAutoFill && !isUpdatingSharedAutofill
            ? editingAutoFill.id
            : newGuid(),
        templateTypeCode: TemplateTypeCode.Clinical,
        content,
        eTag: "",
        isLetterhead: false
      };

      return templateDto;
    };

    const onSubmit = async (val: AutofillQuickAddValues) => {
      const dto: TemplateDto = await getTemplateDto(val);
      let autofill: Template | undefined;
      try {
        if (
          autofillDialogPurpose === AutofillDialogPurposeType.NEW ||
          isUpdatingSharedAutofill
        ) {
          autofill = await document.createTemplate(dto);
        } else {
          autofill = await document.editTemplate(dto);
        }

        if (autofill && withEditor.current) {
          document.setActiveTemplateTab({
            documentId: autofill.id,
            documentTabStatus: DocumentTabStatus.Edit
          });
        }
      } catch (e) {
        withEditor.current = false;
        return e;
      }
    };

    const initialValues: AutofillQuickAddValues = {
      name: "",
      documentContent: "",
      visibility: "",
      purpose: PurposeType.AUTO,
      updatingAutofill: autofills.length > 0 ? autofills[0].id : undefined
    };

    const sendtoEditorButton = (
      form: FormApi<AutofillQuickAddValues>,
      isPrimaryDisabled?: boolean | undefined
    ) => {
      return (
        <PrimaryButton
          text="Continue with text editor"
          onClick={() => {
            withEditor.current = true;
            form.submit();
          }}
          disabled={isPrimaryDisabled}
        />
      );
    };

    const onClose = () => {
      onDismiss();
      setAutofillDialogPurpose(AutofillDialogPurposeType.NEW);
    };
    return (
      <SubmissionFormDialog
        loadingData={loading}
        dataLoadingError={error?.message}
        onSubmit={(formValues: AutofillQuickAddValues) => {
          onSubmit(formValues);
        }}
        dialogName="Write template dialog"
        onSubmitSucceeded={onClose}
        initialValues={initialValues}
        dialogProps={{
          maxWidth: 650,
          styles: { root: { width: "100%" } },
          onDismiss: onClose,
          dialogContentProps: {
            title: (
              <Heading variant="modal-heading">New / update autofill</Heading>
            ),
            showCloseButton: true
          }
        }}
        buttonsProps={{
          disableSubmitOnFormInvalid: true,
          disableSubmitOnPristine: true,
          extraActionsBetween: sendtoEditorButton
        }}
        autoFocus={false}
        validate={(values: AutofillQuickAddValues) => {
          const validator = new AutofillQuickAddValidator(
            values,
            document.autofills,
            editingAutoFill
          );
          return validator.validate(values);
        }}
      >
        {() => {
          return (
            <AutofillQuickAddForm
              autofillDialogPurpose={autofillDialogPurpose}
              onPurposeChange={() => {
                setAutofillDialogPurpose(
                  autofillDialogPurpose === AutofillDialogPurposeType.NEW
                    ? AutofillDialogPurposeType.UPDATE
                    : AutofillDialogPurposeType.NEW
                );
              }}
              isComplex={!!isEditingComplexAutofill}
            />
          );
        }}
      </SubmissionFormDialog>
    );
  });

export const AutofillQuickAddEditDialog: FunctionComponent<
  AutofillQuickAddDialogProps
> = (props: AutofillQuickAddDialogProps) => {
  const { userExperience } = useStores();

  return (
    <DataFetcher
      noExceptionsHandlers
      fetch={() => userExperience.getAllTemplateExtensions()}
    >
      {(data, loading, error) => (
        <AutofillQuickAddEditDialogBase
          error={error}
          loading={loading}
          data={props}
        />
      )}
    </DataFetcher>
  );
};
