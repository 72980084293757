// eslint-disable-next-line import/extensions
import isEqual from "lodash/isEqual";
import { FunctionComponent, memo } from "react";
import { useField, useFormState } from "react-final-form";

import {
  ButtonsGroupOption,
  DatesRangePicker,
  DatesRangePickerProps,
  IStyle,
  mergeFuncStyles,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ReadOnlyTextField } from "@ui-components/form/read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

import { ButtonsGroupSingleChoiceField } from "../ButtonsGroupSingleChoiceField.tsx";
import { getFieldErrorMessage } from "../FinalForm.tsx";
import { FormItemField } from "../FormItemField.tsx";
import { withDatesRangePickerFieldAnalytics } from "./withDatesRangePickerFieldAnalytics.tsx";

interface SelectionProps {
  options: ButtonsGroupOption<string>[];
  name: string;
  label?: string;
}

type DatesRangePickerFieldProps = Omit<
  DatesRangePickerProps,
  | "errorMessage"
  | "endDateProps"
  | "startDateProps"
  | "onBlur"
  | "onFocus"
  | "renderValue"
> & {
  validateOnInitialize?: boolean;
  startDateName: string;
  endDateName: string;
  startDateProps?: Pick<
    DatesRangePickerProps["startDateProps"],
    "maxDate" | "minDate" | "label" | "id"
  >;
  endDateProps?: Pick<
    DatesRangePickerProps["startDateProps"],
    "maxDate" | "minDate" | "label" | "id"
  >;
  readOnly?: boolean;
  horizontalLabel?: boolean;
  selectionProps?: SelectionProps;
  renderValue?: (
    startValue: Date | undefined,
    endValue: Date | undefined,
    allValues: any
  ) => string | undefined;
};

const DatesRangePickerWithAnalytics =
  withDatesRangePickerFieldAnalytics(DatesRangePicker);

export const DatesRangePickerField: FunctionComponent<DatesRangePickerFieldProps> =
  memo(props => {
    const {
      label,
      placeholder,
      required,
      disabled,
      hidePasteStartButton,
      closeCalloutOnSelection,
      endDateName,
      startDateName,
      readOnly,
      startDateProps,
      renderCloseBtn,
      endDateProps,
      styles,
      validateOnInitialize,
      className,
      standardRanges,
      id,
      weekRanges,
      selectionProps,
      horizontalLabel,
      renderValue
    } = props;

    const { values } = useFormState({
      subscription: { values: !!renderValue }
    });

    const theme = useTheme();

    const {
      meta: formMetaStartDate,
      input: {
        value: startDateValue,
        onChange: onChangeStartDate,
        onFocus: onFocusStartDate,
        onBlur: onBlurStartDate
      }
    } = useField<Date | undefined>(startDateName, { isEqual });

    const {
      meta: formMetaEndDate,
      input: {
        value: endDateValue,
        onChange: onChangeEndDate,
        onFocus: onFocusEndDate,
        onBlur: onBlurEndDate
      }
    } = useField<Date | undefined>(endDateName, { isEqual });

    if (
      readOnly ||
      formMetaStartDate?.data?.readOnly ||
      formMetaEndDate?.data?.readOnly
    ) {
      return (
        <ReadOnlyTextField
          value={`${DateTime.fromJSDate(
            startDateValue
          )?.toDayDefaultFormat()} - ${DateTime.fromJSDate(
            endDateValue
          )?.toDayDefaultFormat()}`}
          label={label}
        />
      );
    }

    const renderSelection = () => {
      if (!selectionProps) return null;
      return (
        <ButtonsGroupSingleChoiceField
          label={selectionProps?.label ?? "Apply to"}
          name={selectionProps.name}
          options={selectionProps.options}
        />
      );
    };

    const _renderValue: DatesRangePickerProps["renderValue"] = renderValue
      ? (startProps, endProps) =>
          renderValue(startProps.value, endProps.value, values)
      : undefined;

    const hasErrorMessage = !!getFieldErrorMessage([
      formMetaStartDate,
      formMetaEndDate
    ]);

    const getErrorInputStyles = (): IStyle => {
      if (hasErrorMessage) {
        return {
          color: theme.semanticColors.errorText,
          borderColor: theme.semanticColors.errorText,
          "&:hover": {
            color: theme.semanticColors.errorText,
            borderColor: theme.semanticColors.errorText
          },
          "&::after": {
            border: `2px solid ${theme.semanticColors.errorText}`
          }
        };
      }
      return {};
    };

    const rangesProperties = standardRanges
      ? { standardRanges, weekRanges: undefined }
      : { weekRanges, standardRanges: undefined };

    return (
      <FormItemField
        name={startDateName}
        label={label}
        required={required}
        horizontalLabel={horizontalLabel}
      >
        <DatesRangePickerWithAnalytics
          {...rangesProperties}
          id={id}
          className={className}
          renderCloseBtn={renderCloseBtn}
          hidePasteStartButton={hidePasteStartButton}
          placeholder={placeholder}
          disabled={disabled}
          closeCalloutOnSelection={closeCalloutOnSelection}
          styles={mergeFuncStyles(styles, {
            fieldGroup: getErrorInputStyles()
          })}
          startDateProps={{
            ...startDateProps,
            value: startDateValue,
            onChange: onChangeStartDate,
            name: startDateName,
            label: startDateProps?.label,
            errorMessage: getFieldErrorMessage(
              formMetaStartDate,
              validateOnInitialize
            )
          }}
          endDateProps={{
            ...endDateProps,
            value: endDateValue,
            onChange: onChangeEndDate,
            name: endDateName,
            label: endDateProps?.label,
            errorMessage: getFieldErrorMessage(
              formMetaEndDate,
              validateOnInitialize
            )
          }}
          onFocus={e => {
            onFocusStartDate(e);
            onFocusEndDate(e);
          }}
          onBlur={e => {
            onBlurStartDate(e);
            onBlurEndDate(e);
          }}
          renderSelection={selectionProps ? renderSelection : undefined}
          renderValue={_renderValue}
        />
      </FormItemField>
    );
  });
