import { FunctionComponent, useEffect, useState } from "react";
import { Field, FieldRenderProps } from "react-final-form";

import {
  IMaskedTextFieldProps,
  SearchBox,
  SearchBoxProps
} from "@bps/fluent-ui";

import { ExposedFieldProps } from "./FinalForm.tsx";

type HtmlElementType = HTMLInputElement | HTMLTextAreaElement;

type SearchBoxFieldProps = Omit<
  SearchBoxProps,
  | "value"
  | "onSearch"
  | "onChange"
  | "onClear"
  | "defaultChecked"
  | "defaultValue"
> &
  ExposedFieldProps<SearchBoxProps["value"], Omit<HtmlElementType, "type">>;

type SearchBoxAdapterProps = Pick<
  FieldRenderProps<IMaskedTextFieldProps["value"], HtmlElementType>,
  "input" | "meta"
> &
  Pick<
    SearchBoxProps,
    | "styles"
    | "className"
    | "id"
    | "iconProps"
    | "placeholder"
    | "debounceSearchOnChange"
  >;

const SearchBoxAdapter = (props: SearchBoxAdapterProps) => {
  const {
    input,
    placeholder,
    styles,
    className,
    id,
    iconProps,
    debounceSearchOnChange,
    ...rest
  } = props;

  const [value, setValue] = useState<string>(input.value ?? "");

  useEffect(() => {
    if (value && !input.value) {
      setValue("");
    }
    // ⚠️ Added this like here, since I need to observe input.value to reset the local state. Ilya S.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value]);

  return (
    <SearchBox
      {...rest}
      id={id}
      placeholder={placeholder}
      className={className}
      iconProps={iconProps}
      styles={styles}
      value={debounceSearchOnChange === false ? input.value ?? "" : value}
      onChange={(_evt, value) => {
        debounceSearchOnChange === false
          ? input.onChange(value ?? "")
          : setValue(value ?? "");
      }}
      name={input.name}
      onSearch={input.onChange}
      onClear={() => {
        input.onChange("");
      }}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      debounceSearchOnChange={debounceSearchOnChange}
    />
  );
};

export const SearchBoxField: FunctionComponent<SearchBoxFieldProps> = props => (
  <Field {...props} component={SearchBoxAdapter} />
);
