import { FunctionComponent } from "react";
import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  concatStyleSets,
  IToggleProps,
  IToggleStyleProps,
  metaToLabelColor,
  Toggle
} from "@bps/fluent-ui";
import { ReadOnlyTextField } from "@ui-components/form/read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

import { ExposedFieldProps, getFieldErrorMessage } from "../FinalForm.tsx";

type HtmlElementType = HTMLElement;

export type ToggleFieldProps = Omit<
  IToggleProps,
  | "checked"
  | "onChanged"
  | "onChange"
  | "defaultChecked"
  | "defaultValue"
  | "automationAttribute"
> &
  ExposedFieldProps<IToggleProps["checked"], HtmlElementType> & {
    automationAttribute?: string;
    readOnly?: boolean;
    alignWithInputs?: boolean;
  };

const ToggleFieldAdapter = (
  props: FieldRenderProps<IToggleProps["checked"], HtmlElementType> &
    IToggleProps & { readOnly?: boolean; alignWithInputs?: boolean }
) => {
  const {
    input: { value, onChange, onBlur, onFocus, name },
    meta,
    styles,
    readOnly,
    disabled,
    alignWithInputs,
    ...toggleProps
  } = props;

  const { meta: formMeta } = useField(name);

  if (readOnly || formMeta?.data?.readOnly) {
    if (typeof toggleProps.label === "string") {
      if (props.onText && props.offText) {
        return (
          <ReadOnlyTextField
            label={toggleProps.label}
            value={value ? props.onText : props.offText}
          />
        );
      }
      if (value) {
        return <ReadOnlyTextField value={toggleProps.label} />;
      }
    } else if (value) {
      return toggleProps.label || null;
    }
    return null;
  }

  const newStyles = (props: IToggleStyleProps) =>
    concatStyleSets(
      {
        label: metaToLabelColor(
          {
            active: meta.active,
            hasErrorMessage: !!getFieldErrorMessage(meta)
          },
          props.theme
        ),
        container: alignWithInputs
          ? {
              marginTop: 7
            }
          : undefined
      },
      styles
    );

  return (
    <Toggle
      onChange={(ev, checked) => onChange(checked)}
      onBlur={onBlur}
      checked={value}
      onFocus={onFocus}
      {...toggleProps}
      disabled={disabled || formMeta?.data?.disabled}
      styles={newStyles}
    />
  );
};

export const ToggleField: FunctionComponent<ToggleFieldProps> = props => (
  <Field {...props} component={ToggleFieldAdapter} />
);
