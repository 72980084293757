import { reaction } from "mobx";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

export const useFavicon = () => {
  const { core } = useStores();

  reaction(
    () => core.isLoggedIn,
    isLoggedIn => {
      const faviconUpdate = () => {
        const hasPrerelease = core.hasPermissions(Permission.PreRelease);
        const favicon = document.getElementById("favicon");

        if (hasPrerelease) {
          favicon?.setAttribute("href", "/prereleaseIndicator.ico");
        }
      };
      isLoggedIn && faviconUpdate();
    },
    { fireImmediately: true }
  );
};
