import { Field, FieldRenderProps } from "react-final-form";

import { FieldItem, FieldItemProps } from "@bps/fluent-ui";
import {
  ExposedFieldProps,
  getFieldErrorMessage
} from "@ui-components/form/FinalForm.tsx";
import { TextEditor } from "@ui-components/text-editors/TextEditor.tsx";
import { TextEditorProps } from "@ui-components/text-editors/TextEditor.types";

type HtmlElementType = HTMLElement;

type CommonTextEditorProps = Omit<
  TextEditorProps,
  "model" | "onModelChange"
> & {
  placeholder?: string;
  validateOnInitialize?: boolean;
} & Pick<FieldItemProps, "label" | "required">;

export type TextEditorFieldProps = CommonTextEditorProps &
  ExposedFieldProps<TextEditorProps["value"], HtmlElementType>;

type TextEditorFieldAdapterProps = FieldRenderProps<
  TextEditorProps["value"],
  HtmlElementType
> &
  CommonTextEditorProps;

const TextEditorFieldAdapter = (props: TextEditorFieldAdapterProps) => {
  const {
    input,
    placeholder,
    label,
    required,
    meta,
    validateOnInitialize,
    ...documentEditorProps
  } = props;

  const errorMessage = getFieldErrorMessage(meta, validateOnInitialize);

  return (
    <FieldItem
      name={input.name}
      label={label}
      required={required}
      errorMessage={errorMessage}
      disabled={props.readOnly}
    >
      <TextEditor
        {...documentEditorProps}
        placeholder={placeholder}
        value={input.value}
        onChange={input.onChange}
        onFocus={() => input.onFocus()}
        onBlur={() => input.onBlur()}
      />
    </FieldItem>
  );
};

export const TextEditorField: React.FunctionComponent<
  TextEditorFieldProps
> = props => <Field {...props} component={TextEditorFieldAdapter} />;
