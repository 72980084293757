import { FontWeights } from "@bps/fluent-ui";

import {
  ReadOnlyCheckboxGroupStyles,
  ReadOnlyCheckboxGroupStylesProps
} from "./ReadOnlyCheckboxGroup.types.ts";

export const getReadOnlyCheckboxGroupStyles = (
  props: ReadOnlyCheckboxGroupStylesProps
): ReadOnlyCheckboxGroupStyles => {
  const { theme } = props;
  return {
    root: {
      ".checkbox-option": {
        minHeight: 20,
        marginTop: 8
      }
    },
    label: {
      ...theme.fonts.medium,
      fontWeight: FontWeights.semibold,
      padding: "5px 0"
    }
  };
};
