import { ComponentType, FunctionComponent } from "react";

import {
  classNamesFunction,
  Spinner,
  SpinnerSize,
  styled
} from "@bps/fluent-ui";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { getReadOnlyTextFieldStyles } from "./ReadOnlyTextField.styles.ts";
import {
  ReadOnlyTextFieldProps,
  ReadOnlyTextFieldStyles,
  ReadOnlyTextFieldStylesProps
} from "./ReadOnlyTextField.types.ts";

const getClassNames = classNamesFunction<
  ReadOnlyTextFieldStylesProps,
  ReadOnlyTextFieldStyles
>();

const ReadOnlyTextFieldBase: FunctionComponent<ReadOnlyTextFieldProps> = ({
  getValueText,
  value,
  label,
  styles,
  className,
  theme,
  alignAsField = false,
  hint
}) => {
  const classNames = getClassNames(styles, { className, theme, alignAsField });

  const textElement = getValueText ? (
    <DataFetcher
      fetch={getValueText}
      refetchId={value}
      fallback={<Spinner size={SpinnerSize.small} />}
    >
      {fetchedValue => <>{fetchedValue}</>}
    </DataFetcher>
  ) : (
    value
  );

  return (
    <div className={classNames.root}>
      {label && <span className={classNames.label}>{label}</span>}
      <span className={classNames.text}>{textElement}</span>
      {hint && <div className={classNames.text}>{hint}</div>}
    </div>
  );
};

export const ReadOnlyTextField = styled<
  ReadOnlyTextFieldProps,
  ReadOnlyTextFieldStylesProps,
  ReadOnlyTextFieldStyles
>(ReadOnlyTextFieldBase, getReadOnlyTextFieldStyles) as ComponentType<
  Omit<ReadOnlyTextFieldProps, "theme">
>;
