import React from "react";

import {
  classNamesFunction,
  ScrollablePane,
  SideRailMenu,
  Stack,
  styled,
  TopBarWrapper,
  useTheme
} from "@bps/fluent-ui";

import {
  getStyles,
  SideMenuLayoutProps,
  SideMenuLayoutStyles,
  SideMenuLayoutStylesProps
} from "./CardFormLayout.styles.ts";
import { CardFormLayoutHelper } from "./context/CardFormLayoutHelper.tsx";
import {
  CardFormLayoutContext,
  useCardFormLayoutContext
} from "./context/CardFormLayoutHelperContext.ts";

const getClassNames = classNamesFunction<
  SideMenuLayoutStylesProps,
  SideMenuLayoutStyles
>();

const CardFormLayoutBase: React.FC<SideMenuLayoutProps> = ({
  styles,
  children,
  sideRailMenuItems,
  ...props
}) => {
  const { setSelectedMenuItemId } = useCardFormLayoutContext();

  const theme = useTheme();

  const classNames = getClassNames(styles, {
    theme
  });

  return (
    <TopBarWrapper
      styles={classNames.subComponentStyles.topBarWrapper}
      {...props}
    >
      <div className={classNames.root}>
        <SideRailMenu
          styles={classNames.subComponentStyles.sideRailMenu}
          items={sideRailMenuItems}
          delay={500}
          onMenuItemClicked={setSelectedMenuItemId}
        >
          <div style={{ position: "relative" }}>
            <ScrollablePane>
              <div className={classNames.content}>
                <Stack tokens={{ childrenGap: 16 }}>{children}</Stack>
              </div>
            </ScrollablePane>
          </div>
        </SideRailMenu>
      </div>
    </TopBarWrapper>
  );
};

export const CardFormLayoutProvider: React.FC<SideMenuLayoutProps> = ({
  ...props
}) => {
  const helper = new CardFormLayoutHelper();
  return (
    <CardFormLayoutContext.Provider value={helper}>
      <CardFormLayoutBase {...props} />
    </CardFormLayoutContext.Provider>
  );
};

export const CardFormLayout: React.FC<SideMenuLayoutProps> = styled<
  SideMenuLayoutProps,
  SideMenuLayoutStylesProps,
  SideMenuLayoutStyles
>(CardFormLayoutProvider, getStyles);
