import {
  dataAttribute,
  DataAttributes,
  mergeStyles,
  useTheme
} from "@bps/fluent-ui";

import { NavLinkBase } from "./Nav.tsx";

type NavLinkWrapperProps = React.PropsWithChildren<NavLinkBase>;

export const NavLinkWrapper: React.FC<NavLinkWrapperProps> = ({
  showBorder,
  children,
  dataAttributeName
}) => {
  const theme = useTheme();

  return (
    <div
      className={mergeStyles(
        { flexGrow: 1 },
        showBorder && {
          position: "absolute",
          left: 0,
          right: 0,
          borderBottom: `1px solid ${theme.palette.neutralLighter}`,
          height: "100%"
        }
      )}
      {...dataAttribute(DataAttributes.Element, dataAttributeName)}
    >
      {children}
    </div>
  );
};
