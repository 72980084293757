import {
  CompoundButton,
  FontIcon,
  FontSizes,
  Heading,
  IIconProps,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { PageLinkBaseProps } from "./PageLink.types.ts";

export const PageLinkBase: React.FC<PageLinkBaseProps> = ({
  iconName,
  headingLevel,
  headingText,
  descriptionText,
  messageBarText,
  styles,
  disabled,
  isWarningType,
  onClick,
  children
}) => {
  const theme = useTheme();

  const chevronIconStyles: Partial<IIconProps> = {
    iconName: "ChevronRight",
    styles: { root: { color: theme.palette.themePrimary } }
  };

  return (
    <CompoundButton
      disabled={disabled}
      styles={mergeStyleSets(styles, {
        flexContainer: {
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          alignItems: "center"
        },
        icon: {
          fontSize: FontSizes.size16
        },
        rootHovered: {
          color: theme.palette.neutralPrimary
        },
        rootPressed: {
          color: theme.palette.neutralPrimary
        },
        root: {
          selector: {
            ".ms-Fabric--isFocusVisible &:focus::after": {
              outline: "none"
            },
            ".ms-Fabric--isFocusVisible &:focus": {
              outline: "none",
              borderRadius: 4,
              boxShadow: `0 0 0 1px ${theme.palette.neutralDark}`
            }
          },
          borderRadius: 4,
          padding: "16px 24px",
          maxWidth: "none"
        }
      })}
      iconProps={chevronIconStyles}
      onClick={onClick}
    >
      <Stack grow tokens={{ childrenGap: 4 }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
          {iconName && (
            <FontIcon
              iconName={iconName}
              styles={{
                root: {
                  color: theme.palette.themePrimary,
                  fontSize: FontSizes.size24
                }
              }}
            />
          )}
          <Heading variant={headingLevel}>{headingText}</Heading>
        </Stack>
        {descriptionText && (
          <Text styles={{ root: { alignSelf: "flex-start" } }}>
            {descriptionText}
          </Text>
        )}
        {messageBarText && (
          <MessageBar
            messageBarType={
              isWarningType ? MessageBarType.warning : MessageBarType.success
            }
            styles={{ root: { marginRight: 10 } }}
          >
            {messageBarText}
          </MessageBar>
        )}
        {children}
      </Stack>
    </CompoundButton>
  );
};
