import { FunctionComponent } from "react";

import { useFieldContext, useFieldRegister } from "@bps/fluent-ui";

import {
  ContactsSelect,
  ContactsSelectProps
} from "../../selects/ContactsSelect.tsx";

interface ContactsSelectFilterItemProps
  extends Omit<
    ContactsSelectProps,
    "selectedKeys" | "onChangeSelectedKeys" | "onFocus" | "onBlur"
  > {
  name: string;
}

export const ContactsSelectFilterItem: FunctionComponent<
  ContactsSelectFilterItemProps
> = ({ name, ...contactsSelectProps }) => {
  useFieldRegister(name);
  const { value, setFieldValue, focusField, blurField } = useFieldContext<
    string | undefined | string[]
  >(name);

  return (
    <ContactsSelect
      {...contactsSelectProps}
      onFocus={focusField}
      onBlur={blurField}
      selectedKeys={value as any}
      onChangeSelectedKeys={setFieldValue}
    />
  );
};
