import { IColumn, ShimmeredDetailsListProps } from "@bps/fluent-ui";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";

export interface InfiniteScrollProps<Item extends { id: string }> {
  getItems: (query: PagingOptions) => Promise<QueryResult<Item>>;
  onGetItems?: (items: Item[]) => void;
  onRenderNoResults?: () => JSX.Element;
  refreshKey?: string;
  // sort should be provided if you want to control the sorting externally
  sort?: InfiniteScrollListSortProps;
  // onSort is required if sort prop is provided
  onSort?: (newSort: InfiniteScrollListSortProps) => void;
  // initialSort cannot be used if sort prop is provided
  initialSort?: InfiniteScrollListSortProps;
}

export interface InfiniteScrollListProps<Item extends { id: string }>
  extends InfiniteScrollProps<Item>,
    Omit<
      ShimmeredDetailsListProps,
      "items" | "enableShimmer" | "enableTrailingShimmer" | "columns"
    > {
  onRenderNoResults?: () => JSX.Element;
  columns: InfiniteScrollListColumn[];
  displayDefaultCheckBox?: boolean;
  checkboxAutomationAttribute?: string;
}

export type InfiniteScrollListColumn = Omit<
  IColumn,
  "isSortedDescending" | "isSorted"
> & {
  isSortable?: boolean;
  sortDefaultDirection?: SortDirection;
};

export interface InfiniteScrollListSortProps {
  sortColumn: string;
  sortDescending?: true;
}

export enum SortDirection {
  Ascending = "ASC",
  Descending = "DES"
}
