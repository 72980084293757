import { FORM_ERROR } from "final-form";
import { FunctionComponent } from "react";
import { FormSpy } from "react-final-form";

import { dataAttribute, DataAttributes } from "@bps/fluent-ui";
import { ErrorAlert, ErrorAlertProps } from "@ui-components/Alert.tsx";

export const FormErrorMessage: FunctionComponent<ErrorAlertProps> = ({
  styles
}) => {
  return (
    <FormSpy
      subscription={{
        hasSubmitErrors: true,
        hasValidationErrors: true,
        submitError: true,
        submitting: true,
        errors: true
      }}
    >
      {({
        hasSubmitErrors,
        hasValidationErrors,
        submitError,
        submitting,
        errors
      }) => {
        let error: Error | undefined;
        if (hasSubmitErrors && submitError && !submitting) {
          error = submitError;
        } else if (hasValidationErrors && errors?.[FORM_ERROR]) {
          // This isn't quite right.  The final form docs say that validation errors that use FORM_ERROR
          //  should appear as "error" rather than with the other validation errors in "errors".  However
          //  it doesn't seem to work that way so this will have to do for now.
          error = errors[FORM_ERROR];
        }

        if (!error) return null;

        return (
          <ErrorAlert
            {...dataAttribute(DataAttributes.Element, "submission-form-error")}
            styles={styles}
            error={error}
          />
        );
      }}
    </FormSpy>
  );
};
