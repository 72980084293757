export const CHARS_TO_ESCAPE: RegExp = /[*\\^$.|?+()[{]/gim;
export const getCutText = (text: string, filter: string) => {
  const regExp = new RegExp(
    `${filter
      .replace(CHARS_TO_ESCAPE, (match: string) => `\\${match}`)
      .split(" ")
      .join(".+|")}.+`,
    "gim"
  );

  const matches = regExp.exec(text);
  if (matches !== null && matches[0] !== text) {
    return `... ${matches[0]}`;
  }
  return text;
};
export const getEllipsis = (text: string, filter: string) => {
  const MAX_DISPLAYED_LENGTH = 80;
  const CHARS_AHEAD = 40;
  const ELLIPSIS = "...";
  let truncatedText = text;

  const index = text.toLowerCase().indexOf(filter.toLowerCase());

  const textLength = text.length;

  // Calculates the starting position for extracting text.
  const start = Math.max(index - CHARS_AHEAD, 0);

  // Calculates the ending position for extracting text.
  const end = Math.min(index + filter.length + CHARS_AHEAD, textLength);

  // if index of matched > 80 we need add ellipsis
  if (index + 1 >= MAX_DISPLAYED_LENGTH) {
    //if it's the end of sentence then we add ... before matched
    if (!text.charAt(index + CHARS_AHEAD)) {
      truncatedText = `${ELLIPSIS}${text.slice(start, end)}`;
    } else {
      // otherwise we add ... at the beginning and at the end
      truncatedText = `${ELLIPSIS}${text.slice(start, end)}${ELLIPSIS}`;
    }
  } // if index is less than 40 and if string is longer than 80 char we truncate it and add ...
  else if (index + 1 < CHARS_AHEAD && text.length > MAX_DISPLAYED_LENGTH) {
    truncatedText = `${text.slice(0, MAX_DISPLAYED_LENGTH)}${ELLIPSIS}`;
  }
  return truncatedText;
};
