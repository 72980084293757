import { observer } from "mobx-react-lite";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { OutboundCommType } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { EmailConfirmationModalHelper } from "./context/EmailConfirmationModalHelper.tsx";
import { EmailConfirmationModalValues } from "./EmailConfirmationModal.types.ts";
import { EmailConfirmationModalValidator } from "./EmailConfirmationModalValidator.ts";

enum Labels {
  preferredEmailText = "Save as preferred for billing correspondence"
}

interface EmailConfirmationModalProps {
  accountContactId: string;
  onDismiss: () => void;
  onSubmit: (values: EmailConfirmationModalValues) => void;
  commType: OutboundCommType;
  statementDate?: string;
}

const validator = new EmailConfirmationModalValidator();

export const EmailConfirmationModal: React.FC<EmailConfirmationModalProps> =
  observer(
    ({ accountContactId, onDismiss, onSubmit, commType, statementDate }) => {
      const root = useStores();
      const nameOf = nameOfFactory<EmailConfirmationModalValues>();
      const helper = new EmailConfirmationModalHelper(root, accountContactId);

      const fetchData = async (contactId: string) => {
        return root.practice.getContact(contactId, {
          includeContactPreferences: true
        });
      };

      return (
        <DataFetcher<Contact>
          fetch={() => fetchData(accountContactId)}
          noExceptionsHandlers
        >
          {(contact, loading, error) => {
            const emailCommunications =
              contact?.communications.filter(
                communication => communication.type === CommunicationType.Email
              ) ?? [];

            const preferredCommEmail =
              contact?.contactPreferences?.invoiceCommunicationPreferences
                ?.preferredCommAddressValue;

            const orgEmailDropdownOptions =
              contact?.isOrganisation &&
              contact?.organisationRolesEmployerEmails.map(comm => ({
                key: comm,
                text: comm
              }));

            const emailDropdownOptions = emailCommunications.map(comm => ({
              key: comm.value,
              text: comm.value
            }));

            const combinedDropdownOptions = orgEmailDropdownOptions
              ? [...orgEmailDropdownOptions, ...emailDropdownOptions]
              : emailDropdownOptions;

            const getText = () => {
              if (combinedDropdownOptions.length === 0) {
                const contactLabel = contact?.isPatient
                  ? root.userExperience.localisedConfig("patientDisplay")
                  : "contact";

                return (
                  <Stack>
                    <Text block>Profile has no email address recorded.</Text>
                    {!contact?.isOrganisation && (
                      <Text block>
                        {`This will be saved to the ${contactLabel} record.`}
                      </Text>
                    )}
                  </Stack>
                );
              }

              if (combinedDropdownOptions.length > 1) {
                return (
                  <Stack>
                    <Text block>There is more than one email available.</Text>
                    <Text block>
                      Please select the email to use / add a new one.
                    </Text>
                  </Stack>
                );
              }

              return (
                <Stack>
                  {!preferredCommEmail && !contact?.isOrganisation && (
                    <Text block>
                      The email address isn't set as preferred correspondence.
                    </Text>
                  )}
                  <Text block>
                    Please confirm the address below or add another one.
                  </Text>
                </Stack>
              );
            };

            const getHeader = () => {
              const contactName = contact?.name ?? "";

              return OutboundCommType.Statement && statementDate
                ? `Send ${DateTime.fromISO(
                    statementDate
                  )?.toDayDefaultFormat()} ${commType.toLowerCase()} to ${contactName}`
                : `Send ${commType.toLowerCase()} to ${contactName}`;
            };

            const initialValues: EmailConfirmationModalValues = {
              email: contact?.billingEmail ?? ""
            };

            return (
              <SubmissionFormDialog<EmailConfirmationModalValues>
                dialogName="Accounts - Email confirmation dialog"
                onSubmit={async values => {
                  await helper.onSubmit(values);
                  return onSubmit(values);
                }}
                validate={validator.validate}
                initialValues={initialValues}
                buttonsProps={{
                  submitButtonProps: {
                    ...dataAttribute(DataAttributes.Element, "send-button"),
                    text: "Send now",
                    iconProps: { hidden: true }
                  },
                  cancelButtonProps: {
                    ...dataAttribute(
                      DataAttributes.Element,
                      "send-cancel-button"
                    )
                  },
                  disableSubmitOnPristine: false
                }}
                dialogProps={{
                  onDismiss,
                  minWidth: 600,
                  dialogContentProps: {
                    title: (
                      <Heading variant="modal-heading">{getHeader()}</Heading>
                    )
                  }
                }}
                loadingData={loading}
                dataLoadingError={error?.message}
              >
                {() => (
                  <Fieldset>
                    {getText()}
                    <ComboBoxField
                      name={nameOf("email")}
                      options={combinedDropdownOptions}
                      useComboBoxAsMenuWidth
                      allowFreeform
                    />
                    {!preferredCommEmail && !contact?.isOrganisation && (
                      <CheckboxField
                        label={Labels.preferredEmailText}
                        name={nameOf("isPreferredEmail")}
                      />
                    )}
                  </Fieldset>
                )}
              </SubmissionFormDialog>
            );
          }}
        </DataFetcher>
      );
    }
  );
