import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { DefaultButton } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { ErrorAlert } from "./Alert.tsx";
import { SubmissionFormDialog } from "./form/submission-form-dialog/SubmissionFormDialog.tsx";

export interface NoChargeCommentFormValues {
  noChargeComment: string;
}

interface NoChargeCommentProps {
  setDialogVisibility: (visibility: boolean) => void;
  noChargeComment: string;
  onSave: (comment: string) => void;
}
// exported to allow for easier testing
export const CANCEL_BTN_ID = "no-charge-comment-dialog-cancel-button";
export const DELETE_BTN_ID = "no-charge-comment-dialog-delete-button";
const SUBMIT_BTN_ID = "no-charge-comment-dialog-submit-button";

const nameof = nameOfFactory<NoChargeCommentFormValues>();

export const NoChargeCommentDialog: React.FC<NoChargeCommentProps> = observer(
  ({ noChargeComment, setDialogVisibility, onSave }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const onDelete = async () => {
      try {
        await onSave("");
        setDialogVisibility(false);
      } catch (error) {
        setErrorMessage(error.message);
      }
    };

    const onSubmit = async (values: NoChargeCommentFormValues) => {
      await onSave(values.noChargeComment || "");
    };

    const renderDeleteButton = (form: FormApi<NoChargeCommentFormValues>) => {
      return (
        noChargeComment && (
          <DefaultButton
            id={DELETE_BTN_ID}
            disabled={form.getState().submitting}
            text="Remove no charge"
            onClick={onDelete}
          />
        )
      );
    };

    const dialogContentProps = {
      title: "No charge for this appointment",
      showCloseButton: true
    };

    const submitButtonProps = {
      id: SUBMIT_BTN_ID,
      text: "Save with no charge",
      iconProps: {}
    };

    const cancelButtonProps = {
      id: CANCEL_BTN_ID,
      text: "Cancel",
      iconProps: {}
    };

    return (
      <SubmissionFormDialog
        dialogName="no charge comment dialog"
        dialogProps={{
          onDismiss: () => setDialogVisibility(false),
          dialogContentProps,
          minWidth: 600
        }}
        initialValues={{ noChargeComment }}
        onSubmit={onSubmit}
        onSubmitSucceeded={() => setDialogVisibility(false)}
        buttonsProps={{
          submitButtonProps,
          cancelButtonProps,
          extraActionsBefore: renderDeleteButton
        }}
      >
        {() => (
          <>
            <TextInputField
              multiline
              name={nameof("noChargeComment")}
              label="Reason for no charge"
              resizable={false}
              rows={4}
              required
            />
            {errorMessage && <ErrorAlert error={errorMessage} />}
          </>
        )}
      </SubmissionFormDialog>
    );
  }
);
