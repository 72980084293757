// eslint-disable-next-line import/extensions
import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";
import { useState } from "react";

import {
  CenteredLargeSpinner,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Image,
  MessageBar,
  MessageBarType,
  Overlay,
  PrimaryButton,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { ErrorType } from "@libs/api/error-type.enum.ts";
import { PdfErrors } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { getPDFStyles } from "@ui-components/document-viewer/pdf.styles.ts";
import {
  DEFAULT_SCALE,
  PRINT_SCALE
} from "@ui-components/document-viewer/PDFViewer.types.ts";
import { Print } from "@ui-components/printing/Print.tsx";
import { PrintContentWrapper } from "@ui-components/printing/PrintContentWrapper.tsx";

import {
  getCanvasKey,
  getDocumentPdfPages,
  mapPdfPages,
  pdfUrlToString
} from "./pdf-utils.tsx";
import { PdfCanvas } from "./PdfCanvas.tsx";
import { PDFViewerOptionsToolbar } from "./PdfViewerOptionsToolbar.tsx";

export interface RenderButtonsOptions {
  handleDismiss?: () => void;
  handlePrint?: () => void;
  handleDownload?: () => void;
}

export type PDFViewerProps = {
  pageStyle?: string;
  pdfUrl: string | Uint8Array;
  handleDismiss?: () => void;
  onDownloadClick?: () => void;
  showTitle?: boolean;
  showPrint?: boolean;
  documentTitle: string;
  isImage?: boolean;
  onRenderButtons?: (options: RenderButtonsOptions) => JSX.Element | null;
  renderDownloadButton?: (handleDownload: () => void) => React.ReactNode;
};

const PDFViewer: React.FC<PDFViewerProps> = ({
  pdfUrl,
  handleDismiss,
  onDownloadClick,
  showTitle,
  showPrint,
  documentTitle,
  isImage,
  onRenderButtons,
  renderDownloadButton,
  pageStyle = `@page {
      size: A4;
    }`
}) => {
  const [scale, setScale] = useState<string>(DEFAULT_SCALE);
  const [isPrintPreparing, setIsPrintPreparing] = useState<boolean>(false);

  const theme = useTheme();
  const { pdfWrapper, pdfDialogWrapper } = getPDFStyles(theme);

  const printProps = {
    pageStyle,
    documentTitle
  };

  const wrapper = showTitle ? pdfDialogWrapper : pdfWrapper;

  const getErrorMessage = (error: Error) => {
    if (error?.name === ErrorType.PdfTimeout) {
      return "Unable to display file. The action timed out. Please try again.";
    } else if (error?.name === ErrorType.InvalidPDFException) {
      return "This file type cannot be previewed.";
    } else {
      return error?.message;
    }
  };

  return (
    <DataFetcher<PDFDocumentProxy | undefined>
      fetch={async () => {
        if (isImage) {
          return undefined;
        }
        return await getDocumentPdfPages(pdfUrl);
      }}
      renderError={e => {
        let error: Error | undefined;
        if (pdfUrl === PdfErrors.CANT_GENERATE_PDF) {
          error = new Error(ErrorType.InvalidPDFException);
        }

        if (pdfUrl === PdfErrors.PDF_TIMEOUT) {
          error = new Error(ErrorType.PdfTimeout);
        }

        if (pdfUrl === PdfErrors.ERROR_WHILE_GENERATING) {
          error = new Error(ErrorType.ErrorPDFGenerating);
        }

        return (
          <MessageBar
            {...dataAttribute(DataAttributes.Element, "pdf-viewer-error-bar")}
            {...dataAttribute(
              DataAttributes.Data,
              error?.message ?? e?.message
            )}
            messageBarType={MessageBarType.error}
          >
            {getErrorMessage(error ?? e)}
          </MessageBar>
        );
      }}
    >
      {pdf => (
        <Print {...printProps}>
          {print => (
            <>
              <PDFViewerOptionsToolbar
                showTitle={showTitle}
                handleDismiss={handleDismiss}
                setScale={setScale}
                maxPageNumber={pdf?.numPages ?? 1}
                documentTitle={documentTitle}
              />
              <Stack styles={wrapper}>
                {isPrintPreparing && (
                  <Overlay styles={{ root: { zIndex: 100 } }}>
                    <CenteredLargeSpinner
                      label="Preparing to print"
                      labelPosition="bottom"
                    />
                  </Overlay>
                )}
                <Stack
                  styles={{
                    root: {
                      visibility: isPrintPreparing ? "hidden" : "visible",
                      width: "100%",
                      height: "100%",
                      overflowY: "auto",
                      overflowX: "auto"
                    }
                  }}
                >
                  <PrintContentWrapper hidePrintContent={false}>
                    {isImage && (
                      <Image
                        id="pdf-canvas-1"
                        alt="pdf document preview"
                        src={pdfUrlToString(pdfUrl)}
                        styles={{
                          root: {
                            overflowY: "visible",
                            overflowX: "visible"
                          },
                          image: {
                            display: "block",
                            margin: "16px auto",
                            transform: isImage
                              ? `scale(${parseInt(scale) / 100})`
                              : undefined
                          }
                        }}
                      />
                    )}
                    {!isImage &&
                      !!pdf &&
                      mapPdfPages(pdf).map((_, idx: number) => {
                        return (
                          <PdfCanvas
                            pdf={pdf}
                            key={getCanvasKey(pdf, idx)}
                            pageNumber={idx}
                            scale={Number(
                              isPrintPreparing ? PRINT_SCALE : scale
                            )}
                          />
                        );
                      })}
                  </PrintContentWrapper>
                </Stack>
              </Stack>
              {onRenderButtons ? (
                <>
                  {onRenderButtons({
                    handleDismiss,
                    handlePrint: print.print,
                    handleDownload: onDownloadClick
                  })}
                </>
              ) : (
                <>
                  {(onDownloadClick || showPrint || handleDismiss) && (
                    <Stack
                      horizontal
                      verticalAlign="center"
                      horizontalAlign={
                        onDownloadClick ? "space-between" : "end"
                      }
                      styles={{
                        root: {
                          padding: 24
                        }
                      }}
                    >
                      {onDownloadClick && renderDownloadButton && (
                        <>{renderDownloadButton(onDownloadClick)}</>
                      )}
                      {onDownloadClick && !renderDownloadButton && (
                        <DefaultButton
                          onClick={onDownloadClick}
                          text="Download"
                          iconProps={{ iconName: "Download" }}
                        />
                      )}
                      <Stack horizontal tokens={{ childrenGap: 8 }}>
                        {showPrint && (
                          <PrimaryButton
                            onClick={() => {
                              // ⚠ ️Before a document printing we need to increase scale value for PdfCanvas as 150
                              // to fit printing area. setTimeout's been specified to reach asynchrony. Ilya S.
                              setIsPrintPreparing(true);
                              setTimeout(() => {
                                print.print && print.print();
                                handleDismiss && handleDismiss();
                                if (!handleDismiss) {
                                  setIsPrintPreparing(false);
                                }
                              }, 500);
                            }}
                            text="Print"
                            iconProps={{ iconName: "Print" }}
                          />
                        )}
                        {handleDismiss && (
                          <DefaultButton onClick={handleDismiss} text="Close" />
                        )}
                      </Stack>
                    </Stack>
                  )}
                </>
              )}
            </>
          )}
        </Print>
      )}
    </DataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default PDFViewer;
