/* eslint-disable no-restricted-imports */
import { DatePicker as CoreDatePicker, DatePickerProps } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";

export const DatePicker: React.FunctionComponent<DatePickerProps> = ({
  calendarProps,
  ...props
}) => (
  <CoreDatePicker
    {...props}
    calendarProps={{
      today: DateTime.jsDateNow(),
      ...calendarProps
    }}
  />
);
