import { FunctionComponent } from "react";

import { useFieldContext, useFieldRegister } from "@bps/fluent-ui";
import { ContactPicker } from "@ui-components/pickers/contact-picker/ContactPicker.tsx";
import { ContactPickerProps } from "@ui-components/pickers/contact-picker/ContactPicker.types.ts";

interface ContactPickerFilterItemProps
  extends Omit<
    ContactPickerProps,
    "onChange" | "onBlur" | "onFocus" | "selectedKey"
  > {
  name: string;
}
export const ContactPickerFilterItem: FunctionComponent<
  ContactPickerFilterItemProps
> = ({ name, ...contactPickerProps }) => {
  useFieldRegister(name);
  const { value, setFieldValue, focusField, blurField } = useFieldContext<
    string | undefined
  >(name);

  return (
    <ContactPicker
      {...contactPickerProps}
      inputProps={{
        ...contactPickerProps.inputProps,
        name,
        onFocus: focusField,
        onBlur: blurField
      }}
      selectedKey={value}
      onChange={setFieldValue}
    />
  );
};
