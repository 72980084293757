import { FocusEvent, forwardRef, useRef } from "react";

import { setForwardedRef } from "@libs/utils/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DocumentEditor,
  DocumentEditorContainer,
  DocumentEditorContainerComponent,
  SpellChecker,
  TextExport,
  Toolbar,
  ToolbarItem,
  WordExport
} from "@syncfusion/ej2-react-documenteditor";
import "@ui-components/styles/syncfusion.css";

import { AutofillContext } from "./editor-autofills/editor-autofill.types.ts";
import { SyncfusionEditorAutofill } from "./editor-autofills/syncfusion-editor-autofill.ts";

const LANGUAGE_ID = 1033; // https://wiki.freepascal.org/Language_Codes
interface SyncFusionWordProcessorProps
  extends Partial<DocumentEditorContainer> {
  /** Returns the data as JSON string **/
  onContentChanged: (value: string) => void;
  onFocus?: (evt: FocusEvent) => void;
  onBlur?: (evt: FocusEvent) => void;
  withClinicalAutofill?: boolean;
  autofillContext: AutofillContext;
}

const defaultToolbarOptions: ToolbarItem[] = [
  "Undo",
  "Redo",
  "Separator",
  "Image",
  "Table",
  "Hyperlink",
  "Separator",
  "Header",
  "Footer",
  "PageSetup",
  "PageNumber",
  "Break",
  "Separator",
  "Find",
  "Separator",
  "Separator",
  "Separator"
];

DocumentEditorContainerComponent.Inject(
  Toolbar,
  WordExport,
  TextExport,
  SpellChecker
);

export const SyncFusionWordProcessor = forwardRef<
  DocumentEditorContainerComponent,
  SyncFusionWordProcessorProps
>(
  (
    {
      onContentChanged,
      onFocus,
      onBlur,
      withClinicalAutofill = true,
      autofillContext,
      ...rest
    },
    ref
  ) => {
    const isEditorInitialized = useRef(false);
    const isSpellcheckInitialized = useRef(false);
    const root = useStores();
    const autofill = new SyncfusionEditorAutofill(root, autofillContext);

    const initSpellChecker = (documentEditor: DocumentEditor) => {
      const spellChecker = documentEditor.spellChecker;
      if (spellChecker.enableSpellCheck && !isSpellcheckInitialized.current) {
        // Set language id to map dictionary in server side
        spellChecker.languageID = LANGUAGE_ID;
        spellChecker.removeUnderline = false;
        // Allow suggestion for misspelled word
        spellChecker.allowSpellCheckAndSuggestion = true;
        isSpellcheckInitialized.current = true;
      }
    };
    return (
      <DocumentEditorContainerComponent
        ref={instance => {
          if (instance) {
            initSpellChecker(instance.documentEditor);
            if (withClinicalAutofill) {
              autofill
                .setDocumentEditor(instance.documentEditor)
                .jumpLinksHandler();

              autofill.replace();
            }

            setForwardedRef<DocumentEditorContainerComponent | null>(
              ref,
              instance
            );
          }
        }}
        contentChange={({ source }: { source: DocumentEditorContainer }) => {
          isEditorInitialized.current &&
            onContentChanged(source.documentEditor.serialize());
        }}
        created={() => {
          isEditorInitialized.current = true;
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
        toolbarItems={defaultToolbarOptions}
        enableToolbar
        height="auto"
        enableSpellCheck
        {...rest}
      />
    );
  }
);
