import { Field, FieldRenderProps } from "react-final-form";

import { IconSelect, OptionsSelectProps } from "@bps/fluent-ui";

import { ExposedFieldProps } from "./FinalForm.tsx";
import { FormItemField, FormItemFieldProps } from "./FormItemField.tsx";

type IconsSelectFieldProps = Omit<
  OptionsSelectProps,
  "selectedKeys" | "onChangeSelectedKeys" | "onBlur" | "onFocus"
> &
  ExposedFieldProps<OptionsSelectProps["selectedKeys"], HTMLElement> & {
    fieldItemStyles?: FormItemFieldProps["styles"];
  };

const IconsSelectFieldAdapter = (
  props: FieldRenderProps<OptionsSelectProps["selectedKeys"]> &
    IconsSelectFieldProps
) => {
  return (
    <FormItemField name={props.input.name} styles={props.fieldItemStyles}>
      <IconSelect
        disabled={props.disabled}
        styles={props.styles}
        label={props.label}
        required={props.required}
        placeholder={props.placeholder}
        options={props.options}
        onFocus={props.input.onFocus}
        onBlur={props.input.onBlur}
        onChangeSelectedKeys={props.input.onChange}
        selectedKeys={props.input.value as string}
        onRenderField={props.onRenderField}
        onRenderFieldContent={props.onRenderFieldContent}
        hideSearchOption={props.hideSearchOption}
        hideClearButton={props.hideClearButton}
      />
    </FormItemField>
  );
};

export const IconSelectField: React.FunctionComponent<
  IconsSelectFieldProps
> = props => <Field component={IconsSelectFieldAdapter} {...props} />;
