// eslint-disable-next-line no-restricted-imports
import { Selection as FluentSelection, IObjectWithKey } from "@bps/fluent-ui";

export class Selection<TItem = IObjectWithKey> extends FluentSelection<TItem> {
  // ShimmeredDetailsList renders three empty "shimmering" rows at the end.
  // Unfortunately the fluent selection attempts to select these rows, returning an array
  //  with unexpected undefined values
  // So we need to filter them out

  getSelection = () => {
    return super.getSelection().filter(item => item);
  };

  // There is also an issue with getSelectedCount which will return 3 extra if the user selects all
  //  on a ShimmeredDetailsList.  However this method appears to be relied upon by Selection itself
  //  so we can't just overwrite it like with getSelection.  Instead we have added an eslint rule
  //  to stop developers using it.
}
