import React, { useRef, useState } from "react";

import {
  ActionButton,
  ButtonsGroupChoice,
  Callout,
  classNamesFunction,
  dataAttribute,
  DataAttributes,
  DataTimeFormats,
  DateRangeCalendar,
  FontIcon,
  FontSizes,
  IconButton,
  SpinNumberInput,
  Stack,
  styled,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";

import { getDateTimeFrameStyles } from "./DateTimeFramePicker.styles.ts";
import {
  DateTimeFramePickerProps,
  DateTimeFramePickerStyles,
  DateTimeFramePickerStylesProps
} from "./DateTimeFramePicker.types.tsx";

const getClassNames = classNamesFunction<
  DateTimeFramePickerStylesProps,
  DateTimeFramePickerStyles
>();

enum DateRange {
  days = "Days",
  weeks = "Weeks",
  months = "Months",
  years = "Years"
}

const DATE_KEY = "date";
const INCREMENT_KEY = "increment";

export const DateTimeFramePickerBase: React.FC<DateTimeFramePickerProps> = ({
  className,
  styles,
  placeholder,
  startDateProps,
  endDateProps,
  incrementProps,
  renderValue
}) => {
  const [calloutVisible, setCalloutVisible] = useState<boolean>(false);
  const [spinnerValue, setSpinnerValue] = useState<string>();
  const [pickerOption, setPickerSelection] = useState<
    string | string[] | undefined
  >(DATE_KEY);

  const [datePickerButton, setDatePickerButtonValue] = useState<
    string | undefined
  >(DateRange.days);

  const [datePickerIncrement, setDatePickerIncrementValue] = useState<
    string | undefined
  >("0");

  const wrapperDiv = useRef<HTMLDivElement | null>(null);

  const theme = useTheme();
  const classNames = getClassNames(styles, {
    theme
  });

  const _renderValue = () => {
    if (renderValue) {
      const inputValue = renderValue();
      if (inputValue) {
        return <b>{inputValue}</b>;
      }
    }

    return onRenderValue();
  };

  const onRenderValue = () => {
    if (
      (!startDateProps.value || !endDateProps.value) &&
      (!spinnerValue || !incrementProps.value)
    ) {
      return placeholder;
    }

    if (spinnerValue) {
      return <b>{`< ${spinnerValue} consults away`}</b>;
    }

    if (startDateProps.value && endDateProps.value) {
      const start = startDateProps.value;
      const end = endDateProps.value;

      const _startDate = DateTime.fromJSDate(start).toFormat(
        DataTimeFormats.DAY_DEFAULT_FORMAT
      );

      const _endDate = DateTime.fromJSDate(end).toFormat(
        DataTimeFormats.DAY_DEFAULT_FORMAT
      );

      const value =
        _startDate !== _endDate ? `${_startDate} - ${_endDate}` : _startDate;

      return <b>{value}</b>;
    }

    return placeholder;
  };

  const onIncrementButtonChanged = (value?: string | string[]) => {
    let resultAsString = "";

    if (value) {
      if (value instanceof Array) {
        resultAsString = value[0];
      } else {
        resultAsString = value.toString();
      }
    }

    setDatePickerButtonValue(resultAsString);
    updateDateFromControl(resultAsString, datePickerIncrement);
  };

  const onIncrementChanged = (value?: string) => {
    setDatePickerIncrementValue(value);
    updateDateFromControl(datePickerButton, value);
  };

  const updateDateFromControl = (
    dateIncrement?: string,
    dateNumberValue?: string
  ) => {
    if (dateIncrement && dateNumberValue !== undefined) {
      const nowDateTimeStartDay = startDateProps.value
        ? DateTime.fromJSDate(startDateProps.value).startOf("day")
        : DateTime.now().startOf("day");

      const nowDateTimeEndDay = nowDateTimeStartDay.endOf("day");

      // Parse the values
      const datePickerIncrementValue = Number.parseInt(dateNumberValue);

      const startDate = nowDateTimeStartDay.toJSDate();
      let endDate = nowDateTimeEndDay.toJSDate();

      switch (dateIncrement) {
        case DateRange.days:
          endDate = nowDateTimeEndDay
            .plus({ days: datePickerIncrementValue })
            .toJSDate();
          break;
        case DateRange.weeks:
          endDate = nowDateTimeEndDay
            .plus({ weeks: datePickerIncrementValue })
            .toJSDate();
          break;
        case DateRange.months:
          endDate = nowDateTimeEndDay
            .plus({ months: datePickerIncrementValue })
            .toJSDate();
          break;
        case DateRange.years:
          endDate = nowDateTimeEndDay
            .plus({ years: datePickerIncrementValue })
            .toJSDate();
          break;
      }

      // Here we update the values.
      startDateProps.onChange(startDate);
      endDateProps.onChange(endDate);
      resetIncrementSection();
    }
  };

  const resetDateRangeSection = () => {
    startDateProps.onChange(undefined);
    endDateProps.onChange(undefined);
    setDatePickerIncrementValue("0");
    setDatePickerButtonValue(DateRange.days);
  };

  const resetIncrementSection = () => {
    setSpinnerValue(undefined);
    incrementProps.onIncrementChange(undefined);
  };

  const onClear = () => {
    resetDateRangeSection();
    resetIncrementSection();
    setCalloutVisible(false);
  };

  const updateSpinnerOnDateClicked = (startDate?: Date, endDate?: Date) => {
    if (startDate && endDate) {
      const difference = DateTime.fromJSDate(endDate).diff(
        DateTime.fromJSDate(startDate),
        "days"
      );

      const differenceInDays = Math.round(difference.days);

      if (differenceInDays >= 0) {
        setDatePickerIncrementValue(differenceInDays.toString());
        setDatePickerButtonValue(DateRange.days);
        return;
      }
    }

    setDatePickerIncrementValue(undefined);
    setDatePickerButtonValue(DateRange.days);
  };

  const getDateRangeText = () => {
    if (startDateProps.value && endDateProps.value) {
      const startDateDifference = DateTime.fromJSDate(
        startDateProps.value
      ).diff(DateTime.today(), "days");

      const endDateDifference = DateTime.fromJSDate(endDateProps.value).diff(
        DateTime.today(),
        "days"
      );

      const startDateDiffDays = Math.floor(startDateDifference.days);
      const endDateDiffDays = Math.floor(endDateDifference.days);
      if (startDateDiffDays === 0 && endDateDiffDays === 0) {
        return "Today";
      }
      if (startDateDiffDays === 0 && endDateDiffDays > 0) {
        return `Today - in ${endDateDiffDays} days`;
      }
      if (startDateDiffDays < 0 && endDateDiffDays === 0) {
        return `${Math.abs(startDateDiffDays)} days overdue - Today`;
      }
      if (startDateDiffDays > 0 && endDateDiffDays > 0) {
        return `in ${startDateDiffDays}-${endDateDiffDays} days from today`;
      }
      if (startDateDiffDays < 0 && endDateDiffDays > 0) {
        return `${Math.abs(
          startDateDiffDays
        )} days overdue - in ${endDateDiffDays} days from today`;
      }
      if (startDateDiffDays < 0 && endDateDiffDays < 0) {
        return `${Math.abs(startDateDiffDays)}-${Math.abs(
          endDateDiffDays
        )} days overdue`;
      }
    }

    return "Nothing selected";
  };

  return (
    <div ref={wrapperDiv} className={`bp-DateTimeFramePicker ${className}`}>
      <div
        onClick={() => setCalloutVisible(!calloutVisible)}
        className={classNames.field}
        {...dataAttribute(
          DataAttributes.Element,
          "date-time-frame-calendar-btn"
        )}
      >
        <Stack verticalAlign="center" horizontal>
          {_renderValue()}
          <FontIcon
            iconName="ChevronDownMed"
            styles={{
              root: {
                marginLeft: 6,
                color: theme.palette.neutralTertiary,
                fontSize: 12
              }
            }}
          />
        </Stack>
      </div>

      {calloutVisible && (
        <Callout
          isBeakVisible={false}
          gapSpace={1}
          target={wrapperDiv}
          styles={{ root: { minWidth: 525 } }}
          onDismiss={() => setCalloutVisible(false)}
        >
          <Stack
            styles={{
              root: {
                margin: 16
              }
            }}
            tokens={{ childrenGap: 8 }}
          >
            <Stack tokens={{ childrenGap: 4 }}>
              <Text bold>Due</Text>
              <ButtonsGroupChoice
                options={[
                  {
                    key: DATE_KEY,
                    text:
                      startDateProps.value?.toDateString() ===
                      endDateProps.value?.toDateString()
                        ? "Date"
                        : "Date(s)"
                  },
                  {
                    key: INCREMENT_KEY,
                    text: incrementProps.incrementTitle ?? "Iteration"
                  }
                ]}
                onChange={value => setPickerSelection(value)}
                notUnselectable
                value={pickerOption}
              />
            </Stack>

            {pickerOption === DATE_KEY && (
              <Stack>
                <Stack
                  horizontal
                  styles={{ root: { justifyContent: "space-between" } }}
                >
                  <DateRangeCalendar
                    label="Start date"
                    resetDatesRange={() => {
                      incrementProps.onIncrementChange(undefined);
                    }}
                    onChange={val => {
                      startDateProps.onChange(val);
                      incrementProps.onIncrementChange(undefined);
                      setSpinnerValue(undefined);
                      updateSpinnerOnDateClicked(val, endDateProps.value);
                    }}
                    value={startDateProps.value}
                  />
                  <TooltipHost
                    content="End date same as start date"
                    calloutProps={{ gapSpace: -29 }}
                  >
                    <IconButton
                      iconProps={{ iconName: "ForwardEvent" }}
                      disabled={!startDateProps.value}
                      onClick={() => {
                        if (startDateProps.value) {
                          endDateProps.onChange(
                            startDateProps.value,
                            undefined,
                            true
                          );
                        }
                      }}
                      styles={{ root: { marginTop: 29 } }}
                    />
                  </TooltipHost>

                  <DateRangeCalendar
                    label="End date"
                    resetDatesRange={() => {
                      incrementProps.onIncrementChange(undefined);
                    }}
                    onChange={val => {
                      endDateProps.onChange(val);
                      resetIncrementSection();
                      updateSpinnerOnDateClicked(startDateProps.value, val);
                    }}
                    value={endDateProps.value}
                    minDate={startDateProps.value}
                  />
                </Stack>
                <Stack
                  horizontal
                  tokens={{ childrenGap: 4 }}
                  styles={{ root: { marginBottom: 5, marginTop: 8 } }}
                >
                  <Text bold>{incrementProps.label}</Text>
                  <TooltipHost content={incrementProps.tooltipText}>
                    <FontIcon
                      iconName="Info"
                      styles={{
                        root: {
                          fontSize: FontSizes.size12,
                          color: theme.palette.neutralSecondary,
                          marginTop: 4,
                          cursor: "pointer"
                        }
                      }}
                    />
                  </TooltipHost>
                </Stack>
                <Stack
                  horizontal
                  verticalAlign="end"
                  tokens={{ childrenGap: 8 }}
                >
                  <SpinNumberInput
                    defaultValue="0"
                    min={1}
                    max={99}
                    styles={{
                      root: {
                        maxWidth: 90
                      }
                    }}
                    onChange={(value?: string) => {
                      onIncrementChanged(value);
                    }}
                    value={datePickerIncrement}
                  />
                  <ButtonsGroupChoice
                    onChange={(value: string | string[] | undefined) => {
                      onIncrementButtonChanged(value);
                    }}
                    options={[
                      { key: DateRange.days, text: DateRange.days },
                      { key: DateRange.weeks, text: DateRange.weeks },
                      {
                        key: DateRange.months,
                        text: DateRange.months
                      },
                      { key: DateRange.years, text: DateRange.years }
                    ]}
                    value={datePickerButton}
                  />
                </Stack>
                <Text
                  variant="small"
                  styles={{
                    root: {
                      color: theme.palette.neutralSecondaryAlt,
                      marginTop: 5
                    }
                  }}
                >
                  {getDateRangeText()}
                </Text>
              </Stack>
            )}

            {pickerOption === INCREMENT_KEY && (
              <Stack tokens={{ childrenGap: 4 }}>
                <Text bold>After less than</Text>
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 8 }}
                >
                  <SpinNumberInput
                    value={spinnerValue}
                    onChange={val => {
                      incrementProps.onIncrementChange(val);
                      setSpinnerValue(val);
                      resetDateRangeSection();
                    }}
                    styles={{ root: { width: 60 } }}
                  />
                  <Text>
                    <b>{incrementProps.suffix}</b>
                  </Text>
                </Stack>
              </Stack>
            )}

            <Stack horizontalAlign="end">
              <ActionButton
                onClick={onClear}
                disabled={
                  !startDateProps.value && !endDateProps.value && !spinnerValue
                }
                tabIndex={0}
                data-is-focusable="true"
                iconProps={{ iconName: "Clear" }}
                styles={{
                  labelHovered: { color: "initial" },
                  icon: { fontSize: FontSizes.size12 }
                }}
                className={className}
              >
                Clear
              </ActionButton>
            </Stack>
          </Stack>
        </Callout>
      )}
    </div>
  );
};

export const DateTimeFramePicker = styled<
  DateTimeFramePickerProps,
  DateTimeFramePickerStylesProps,
  DateTimeFramePickerStyles
>(DateTimeFramePickerBase, getDateTimeFrameStyles);
