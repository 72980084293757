import { extendToSinglePersonaPicker } from "@ui-components/pickers/extendToSinglePersonaPicker.tsx";

import { ContactPickerProps } from "./ContactPicker.types.ts";
import { MultiContactPicker } from "./MultiContactPicker.tsx";

/**
 * Contact Picker is a component used to pick a single contact from a list.
 * Prefer the specialized pickers such as PersonPicker or PatientPicker when Organisation Location shouldn't be pickable.
 */
export const ContactPicker = extendToSinglePersonaPicker(
  MultiContactPicker
) as React.ComponentType<ContactPickerProps>;
