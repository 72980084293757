import { IStyle, ITheme, mergeStyles } from "@bps/fluent-ui";

export const getContactPickerItemStyles = (theme: ITheme) => {
  const inactive: IStyle = {
    paddingRight: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    selectors: {
      "&:after": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: 2,
        right: 0,
        content: "'Inactive'",
        color: theme.palette.white,
        fontSize: 10,
        transform: "rotate(180deg)",
        writingMode: "vertical-lr",
        width: 16,
        background: theme.palette.neutralTertiary,
        borderRadius: 2,
        paddingLeft: 2
      }
    }
  };

  const deceased: IStyle = mergeStyles(inactive, {
    selectors: {
      "&:after": {
        content: "'Deceased'",
        color: theme.palette.white
      }
    }
  });

  const pending: IStyle = mergeStyles(inactive, {
    selectors: {
      "&:after": {
        content: "'Pending'",
        backgroundColor: theme.semanticColors.warningBackground,
        color: theme.palette.yellowDark
      }
    }
  });

  return { inactive, deceased, pending };
};
