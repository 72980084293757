import { IComboBoxOption, SelectableOptionMenuItemType } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  ComboBoxField,
  ComboBoxFieldProps
} from "@ui-components/form/ComboBoxField.tsx";

interface CountryPickerProps extends Omit<ComboBoxFieldProps, "options"> {
  name: string;
}

export const CountryPickerField: React.FC<CountryPickerProps> = ({
  name,
  ...props
}) => {
  const { core } = useStores();

  const countries = Array.from(core.ref.countries.keyTextValues);

  const commonCountries = countries.splice(0, 2);

  const combinedCountries: IComboBoxOption[] = [
    ...commonCountries,
    {
      key: "divider",
      text: "-",
      itemType: SelectableOptionMenuItemType.Divider
    },
    ...countries
  ];

  return (
    <ComboBoxField
      name={name}
      options={combinedCountries}
      useComboBoxAsMenuWidth
      {...props}
    />
  );
};
