import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  ButtonsGroupChoice,
  ButtonsGroupChoiceProps,
  FieldItemProps,
  IStyle,
  mergeFuncStyles,
  useTheme
} from "@bps/fluent-ui";
import { identity } from "@libs/utils/utils.ts";
import { ReadOnlyTextField } from "@ui-components/form/read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

import { ExposedFieldProps, getFieldErrorMessage } from "./FinalForm.tsx";
import { FormItemField, FormItemFieldProps } from "./FormItemField.tsx";

type HtmlElementType = HTMLElement | HTMLInputElement;

export type ButtonsGroupSingleChoiceAdapterProps<T> = Omit<
  ButtonsGroupChoiceProps<T>,
  "multiselect"
> &
  Pick<
    FormItemFieldProps,
    | "required"
    | "label"
    | "name"
    | "disabled"
    | "onRenderLabel"
    | "validateOnInitialize"
  > & {
    fieldItemStyles?: FieldItemProps["styles"];
  };

export type ButtonsGroupSingleChoiceFieldProps<T = string> = ExposedFieldProps<
  ButtonsGroupChoiceProps<any>["value"],
  HtmlElementType
> &
  Omit<ButtonsGroupSingleChoiceAdapterProps<T>, "value" | "onChange">;

type InternalButtonsGroupSingleChoiceAdapterProps<T> = FieldRenderProps<
  ButtonsGroupChoiceProps<any>["value"],
  HtmlElementType
> &
  ButtonsGroupSingleChoiceFieldProps<T>;

const ButtonsGroupSingleChoiceGroupAdapter = <T extends any>(
  props: InternalButtonsGroupSingleChoiceAdapterProps<T>
) => {
  const theme = useTheme();
  const {
    input,
    meta,
    required,
    disabled,
    label,
    onChange,
    styles,
    fieldItemStyles,
    readOnly,
    onRenderLabel,
    ...buttonsChoiceGroupProps
  } = props;

  const { meta: formMeta } = useField(input.name);

  const onChangeInternal = (selectedKey: T) => {
    input.onChange(selectedKey);

    if (onChange) {
      onChange(selectedKey);
    }
  };

  const getErrorButtonStyles = (): IStyle => {
    if (!!getFieldErrorMessage(meta)) {
      return {
        color: theme.semanticColors.errorText,
        borderColor: theme.semanticColors.errorText
      };
    }
    return {};
  };

  if (readOnly || formMeta?.data?.readOnly) {
    const text = props.options.find(x => x.key === input.value)?.text;
    return <ReadOnlyTextField value={text || ""} label={props.label} />;
  }

  return (
    <FormItemField
      name={input.name}
      required={required}
      label={label}
      disabled={disabled || formMeta?.data?.disabled}
      onRenderLabel={onRenderLabel}
      styles={props.fieldItemStyles}
    >
      <ButtonsGroupChoice<T>
        {...buttonsChoiceGroupProps}
        options={buttonsChoiceGroupProps.options.map(o => ({
          ...o,
          disabled: o.disabled || formMeta?.data?.disabled || disabled
        }))}
        onChange={onChangeInternal}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        value={input.value}
        styles={mergeFuncStyles({ text: getErrorButtonStyles() }, styles)}
      />
    </FormItemField>
  );
};

export const ButtonsGroupSingleChoiceField = <T extends any = string>(
  props: ButtonsGroupSingleChoiceFieldProps<T>
) => (
  <Field
    {...props}
    parse={identity}
    format={identity}
    component={ButtonsGroupSingleChoiceGroupAdapter}
  />
);
