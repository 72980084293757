import { FunctionComponent } from "react";

import { useFieldContext, useFieldRegister } from "@bps/fluent-ui";
import { UserPicker } from "@ui-components/pickers/user-picker/UserPicker.tsx";
import { UserPickerProps } from "@ui-components/pickers/user-picker/UserPicker.types.tsx";

interface UserPickerFilterItemProps
  extends Omit<UserPickerProps, "selectedKey" | "onChange"> {
  name: string;
}

export const UserPickerFilterItem: FunctionComponent<
  UserPickerFilterItemProps
> = ({ name, ...userPickerProp }) => {
  useFieldRegister(name);

  const { value, setFieldValue, focusField, blurField } = useFieldContext<
    string | undefined
  >(name);

  return (
    <UserPicker
      {...userPickerProp}
      inputProps={{
        name,
        ...userPickerProp.inputProps,
        onFocus: focusField,
        onBlur: blurField
      }}
      selectedKey={value}
      onChange={setFieldValue}
    />
  );
};
