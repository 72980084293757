import { FunctionComponent, memo, useRef } from "react";

import {
  concatStyleSetsWithProps,
  dataAttribute,
  DataAttributes,
  ExtendedPickerProps,
  IBasePickerStyleProps,
  IPersonaProps,
  useTheme
} from "@bps/fluent-ui";
import { ContactStatus } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { compactPeoplePickerOf } from "@ui-components/pickers/contact-picker/compactPeoplePickerOf.tsx";
import {
  convertSearchValueToBirthdayString,
  hasValidBirthdayData,
  hasValidNameData,
  hasValidPhoneNumberData
} from "@ui-components/pickers/contact-picker/contact-picker.utils.ts";
import { PickerIcon } from "@ui-components/pickers/contact-picker/PickerIcon.tsx";

import { MultiContactPickerProps } from "./ContactPicker.types.ts";
import { getContactSuggestionItem } from "./getContactSuggestionItem.tsx";

const ContactCompactPeoplePicker = compactPeoplePickerOf<Contact>();

/**
 * Contact Picker is a component used to pick a one to many contacts from a list.
 * It is the base component of ContactPicker.
 * If there is a need to offer multiple selection of contacts, then
 * it is recommended to introduce specialized versions such as MultiPersonPicker or MultiPatientPicker.
 *
 * The default filter is any contact other than Organisation , i.e : Person, Patient, Location.
 *
 * The underlying component is the NormalPeoplePicker.
 * If the need arises, this component could offer a compact mode in its props and then conditionally use NormalPeoplePicker or CompactPeoplePicker.
 */

export const MultiContactPicker: FunctionComponent<MultiContactPickerProps> =
  memo(
    ({
      onSearchItems,
      iconName,
      selectedKeys,
      reversedName,
      styles: basicStyles,
      includeInactive,
      includeDeceased,
      suggestionShowMoreLimit,
      suggestionDefaultLimit,
      onResolveSuggestionItem,
      filter,
      resultFilterPredicate,
      ...basePickerProps
    }) => {
      const { practice } = useStores();
      const theme = useTheme();
      const searchRef = useRef<"phoneNumber" | "name">();
      const searchValueRef = useRef<string>();

      const isItemSelected = !!selectedKeys?.length;

      const styles = iconName
        ? (props: IBasePickerStyleProps) =>
            concatStyleSetsWithProps(
              props,
              {
                text: !props.isFocused &&
                  !isItemSelected && {
                    minWidth: "auto",
                    paddingLeft: "32px"
                  },
                root: (props.isFocused || isItemSelected) && {
                  selectors: {
                    "&+.picker-icon": {
                      display: "none"
                    }
                  }
                }
              },
              basicStyles
            )
        : basicStyles;

      const _onSearchItems = (
        search: string,
        previousResult?: QueryResult<Contact>
      ) => {
        const statuses: ContactStatus[] = [
          ContactStatus.Active,
          ContactStatus.Inactive,
          ContactStatus.Deceased
        ]
          .filter(s => !(!includeInactive && s === ContactStatus.Inactive))
          .filter(s => !(!includeDeceased && s === ContactStatus.Deceased));

        const isValidBirthdayDate = hasValidBirthdayData(search);
        const isValidPhoneNumber = hasValidPhoneNumberData(search);
        const isValidName = hasValidNameData(search);
        const birthdayString = convertSearchValueToBirthdayString(search);
        searchRef.current = isValidPhoneNumber ? "phoneNumber" : undefined;
        searchValueRef.current = isValidPhoneNumber ? search : undefined;
        return practice.fetchContacts({
          filter: {
            statuses,
            ...filter,
            search: isValidName && !isValidPhoneNumber ? search : undefined,
            birthday: isValidBirthdayDate ? birthdayString : undefined,
            phoneNumber: isValidPhoneNumber ? search : undefined,
            take: previousResult
              ? suggestionShowMoreLimit
              : suggestionDefaultLimit,
            skip: previousResult?.results.length,
            total: true
          },
          resultFilterPredicate
        });
      };

      const _onResolveSuggestionItem: ExtendedPickerProps<
        IPersonaProps,
        Contact
      >["onResolveSuggestionItem"] = (item, context) => {
        return getContactSuggestionItem(item, theme, {
          isSelected: context.role === "selection",
          reversedName,
          overrides:
            onResolveSuggestionItem &&
            onResolveSuggestionItem(item, {
              ...context,
              searchBy: searchRef.current,
              searchValue: searchValueRef.current
            })
        });
      };

      return (
        <div
          style={{ position: "relative" }}
          {...dataAttribute(DataAttributes.Element, "contact-picker")}
        >
          <ContactCompactPeoplePicker
            {...basePickerProps}
            selectedKeys={selectedKeys}
            keyAccessor={x => x?.id}
            onSearchItems={onSearchItems ?? _onSearchItems}
            onResolveSuggestionItem={_onResolveSuggestionItem}
            onFetchItems={ids => practice.getContactsById(ids)}
            styles={styles}
          />
          {!!iconName && <PickerIcon iconName={iconName} />}
        </div>
      );
    }
  );
