import { FieldSubscription, getIn } from "final-form";
import { useEffect, useRef } from "react";
import {
  FieldMetaState,
  useField,
  UseFieldConfig,
  useForm
} from "react-final-form";

type FieldSpyOnChangeProps<
  FieldValue = any,
  FormValues extends object = object
> = {
  name: keyof FormValues;
  onChange: (
    value: FieldValue,
    values?: FormValues,
    previousValue?: FieldValue
  ) => void;
  subscription?: never;
  children?: never;
} & Pick<UseFieldConfig<FieldValue, FormValues>, "isEqual">;

type FieldSpyChildrenProps<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  FieldValue = any,
  FormValues extends object = object
> = {
  name: keyof FormValues;
  /**
   * When specifying children, it is somewhat equivalent to using <Field> component.
   *
   * One potential issue when using multiple Field component with the same name
   * is that one Field instance may override props (such as isEqual) from another instance.
   *
   * Using FieldSpy will workaround this issue because it subscribes to the Form changes instead.
   * The drawback is that it wil re-render more often (every time the form changes instead of when the field changes).
   */
  children: (
    fieldState: FieldMetaState<FieldValue>,
    value: FieldValue
  ) => React.ReactNode;
  subscription?: FieldSubscription;
} & Pick<UseFieldConfig<FieldValue, FormValues>, "isEqual">;

type FieldSpyPropsWithInitialValue<
  FieldValue = any,
  FormValues extends object = object
> =
  | FieldSpyOnChangeProps<FieldValue, FormValues>
  | FieldSpyChildrenProps<FieldValue, FormValues>;

export const FieldSpy = <
  FieldValue extends any = any,
  FormValues extends object = object
>(
  props: FieldSpyPropsWithInitialValue<FieldValue, FormValues>
) => {
  const isMounted = useRef<boolean>(false);
  const { getState } = useForm<FormValues>();

  const initialValue = getIn(getState().values, props.name as string);

  const previousValue = useRef(initialValue);

  const {
    input: { value: fieldValue },
    meta
  } = useField(props.name as string, {
    isEqual: props.isEqual,
    subscription: "onChange" in props ? { value: true } : props.subscription
  });

  useEffect(() => {
    if ("onChange" in props && isMounted.current) {
      onFormChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const onFormChange = () => {
    if (fieldValue !== previousValue.current) {
      if ("onChange" in props) {
        props.onChange(fieldValue, getState().values, previousValue.current);
        previousValue.current = fieldValue;
      }
    }
  };

  return (
    <>
      {"children" in props &&
        props.children &&
        props.children(meta, fieldValue)}
    </>
  );
};
