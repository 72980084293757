import { FieldItem, FieldItemProps } from "@bps/fluent-ui";

import { FieldSpy } from "./FieldSpy.tsx";
import { getFieldErrorMessage } from "./FinalForm.tsx";

export type FormItemFieldProps = Omit<
  FieldItemProps,
  "errorMessage" | "theme" | "name"
> & {
  name: string;
  validateOnInitialize?: boolean;
};
/**
 * Integrates final form Field with the FormItem component.
 */
export const FormItemField: React.FunctionComponent<FormItemFieldProps> = ({
  validateOnInitialize,
  ...props
}) => (
  <FieldSpy
    name={props.name}
    subscription={{
      touched: true,
      error: true,
      submitError: true,
      modified: true,
      submitFailed: true,
      active: true
    }}
  >
    {meta => {
      return (
        <FieldItem
          active={meta.active}
          errorMessage={getFieldErrorMessage(meta, validateOnInitialize)}
          {...props}
        />
      );
    }}
  </FieldSpy>
);
