import { FunctionComponent, useRef } from "react";
import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  getId,
  ISpinButtonStyles,
  mergeStyleSets,
  SpinNumberInput,
  SpinNumberInputProps,
  useTheme
} from "@bps/fluent-ui";

import { ExposedFieldProps, getFieldErrorMessage } from "./FinalForm.tsx";
import { FormItemField, FormItemFieldProps } from "./FormItemField.tsx";
import { ReadOnlyTextField } from "./read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

type HtmlElementType = HTMLDivElement;

export type SpinNumberInputAdapterProps = Omit<
  SpinNumberInputProps,
  | "label"
  | "onChange"
  | "value"
  | "defaultValue"
  | "onIncrement"
  | "onDecrement"
  | "defaultChecked"
> &
  Pick<
    FormItemFieldProps,
    | "required"
    | "label"
    | "suffix"
    | "validateOnInitialize"
    | "horizontalLabel"
    | "onRenderLabel"
  > & {
    fieldItemStyles?: FormItemFieldProps["styles"];
    spinOnScrolling?: boolean;
    prefix?: string;
    readOnly?: boolean;
    valueSuffix?: string;
    fieldClassName?: string;
  };

export type SpinNumberInputFieldProps = ExposedFieldProps<
  string | number | undefined,
  HtmlElementType
> &
  SpinNumberInputAdapterProps;

const SpinNumberInputAdapter = (
  props: FieldRenderProps<SpinNumberInputProps["value"], HtmlElementType> &
    SpinNumberInputAdapterProps
) => {
  const {
    input,
    meta,
    styles,
    required,
    fieldItemStyles,
    disabled,
    validateOnInitialize,
    suffix,
    onRenderLabel,
    readOnly,
    valueSuffix,
    horizontalLabel,
    fieldClassName,
    label,
    autoFocus,
    ...restSpinNumberProps
  } = props;

  const _id = useRef(getId(props.input.name));

  const { semanticColors } = useTheme();
  const { meta: formMeta } = useField(input.name);

  if (readOnly || formMeta?.data?.readOnly) {
    return <ReadOnlyTextField value={props.input.value} label={props.label} />;
  }

  const hasErrorMessage = !!getFieldErrorMessage(meta, validateOnInitialize);

  const newStyles = mergeStyleSets(
    {
      spinButtonWrapper: [
        hasErrorMessage && {
          borderColor: semanticColors.errorText,

          "&&::after": {
            borderColor: semanticColors.errorText
          },
          "&:hover::after": {
            borderColor: semanticColors.errorText
          }
        }
      ]
    } as Partial<ISpinButtonStyles>,
    styles
  );

  return (
    <FormItemField
      htmlFor={_id.current}
      horizontalLabel={horizontalLabel}
      label={label}
      name={input.name}
      required={required}
      suffix={suffix}
      styles={fieldItemStyles}
      disabled={disabled || formMeta?.data?.disabled}
      validateOnInitialize={validateOnInitialize}
      onRenderLabel={onRenderLabel}
      className={fieldClassName}
    >
      <SpinNumberInput
        {...restSpinNumberProps}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        value={input.value}
        onChange={input.onChange}
        inputProps={{
          autoFocus,
          ...restSpinNumberProps.inputProps,
          id: _id.current,
          name: input.name
        }}
        styles={newStyles}
        disabled={disabled}
        valueSuffix={valueSuffix}
      />
    </FormItemField>
  );
};

const formatNumber = (value: string | undefined | number) => {
  if (typeof value === "number") {
    return String(value);
  }
  if (value === undefined) {
    return "";
  }
  return value;
};

/**
 * Use SpinButtonField to use SpinNumberInput with final-form.
 * It also wraps the picker in a FormItemField to support label and error message.
 *
 * The SpinButtonField will try to parse the value into a number in final-form if it is a valid number;
 * otherwise, the original string is stored.
 * format will turn any number into a string before passing it to the SpinNumberInput component.
 *
 * @param props
 */
export const SpinNumberInputField: FunctionComponent<
  SpinNumberInputFieldProps
> = props => {
  return (
    <Field<string | number | undefined>
      format={formatNumber}
      {...props}
      component={SpinNumberInputAdapter}
    />
  );
};
