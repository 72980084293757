import { FunctionComponent, memo } from "react";

import {
  FontIcon,
  mergeStyles,
  PersonaSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";

type PersonTypeIconProps = {
  contactType?: ContactType;
  size: PersonaSize | undefined;
};

/**
 * Adds an circle with or without icon in the top left corner of the Persona coin with
 * the background color according to conntact type.
 * Only coin size 100 displays the icon, smaller sizes display a circle in the background color.
 * Let's leave it in the same componet to be able to compare sizes
 */
export const PersonTypeIcon: FunctionComponent<PersonTypeIconProps> = memo(
  ({ contactType, size }) => {
    const theme = useTheme();
    let iconBackgroundColor = theme.palette.themeTertiary;
    let iconName;
    //
    // 100 size coin
    if (size === PersonaSize.size100) {
      let iconFontSize = "22px";

      let iconPadding = "4px 0 0 0";
      let iconlineHeight = "1.5";
      let toolTipText = "";
      if (contactType) {
        switch (contactType) {
          case ContactType.Patient: // patient
            iconName = "Contact";
            iconFontSize = "18px";
            toolTipText = "Patient";
            break;
          case ContactType.Organisation: // organisation
            iconName = "EMI";
            iconPadding = "3px 0 0 1px";
            iconBackgroundColor = theme.palette.neutralSecondaryAlt;
            toolTipText = "Organisation";
            iconlineHeight = "1.4";
            break;
          case ContactType.Individual: // individual, doctor
            iconName = "Work";
            iconFontSize = "19px";
            iconBackgroundColor = "#F8E1BC";
            toolTipText = "Individual";
            break;
        }
      }

      const iconClass = mergeStyles(
        {
          position: "absolute",
          width: "36px",
          height: "36px",
          top: "-6px",
          left: "-6px",
          fontSize: iconFontSize,
          textAlign: "center",
          padding: iconPadding,
          lineHeight: iconlineHeight,
          backgroundColor: iconBackgroundColor,
          borderRadius: "36px",
          color: "white",
          cursor: "default"
        },
        "person-type-icon"
      );

      return (
        <>
          {iconName && (
            <div className={iconClass}>
              <TooltipHost content={toolTipText}>
                <FontIcon iconName={iconName} />
              </TooltipHost>
            </div>
          )}
        </>
      );
    }

    // coin size 48 (only supported size) .. later all other sizes
    switch (contactType) {
      case ContactType.Patient: // patient
        break;
      case ContactType.Organisation: // Organisation
        iconBackgroundColor = theme.palette.neutralSecondaryAlt;
        break;
      case ContactType.Individual: // individual/staff/user, doctor
        iconBackgroundColor = "#F8E1BC";
        break;
      default:
    }

    const iconClass = mergeStyles(
      {
        position: "absolute",
        width: "15px",
        height: "15px",
        top: "-1px",
        left: "-1px",
        backgroundColor: iconBackgroundColor,
        borderRadius: "16px",
        cursor: "default",
        zIndex: 99
      },
      "person-type-circle"
    );

    return (
      <>{size === PersonaSize.size48 && <span className={iconClass}> </span>}</>
    );
  }
);
