import ReactQuill from "react-quill";

import { IKeyCodes } from "@bps/utils";
import { AutofillDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

import { EditorAutofillBase } from "./editor-autofill-base.ts";
import { AutofillContext, IEditorAutofill } from "./editor-autofill.types.ts";

const TEXT_CHANGE_EVENT = "text-change";
const USER_SOURCE = "user";
const SILENT_SOURCE = "silent";
export class QuillEditorAutofill
  extends EditorAutofillBase<ReactQuill["editor"]>
  implements IEditorAutofill
{
  constructor(
    root: IRootStore,
    private context?: AutofillContext
  ) {
    super(root);
  }
  private jumpLinksIndexes: number[] = [];
  private currentJumpLink: number;
  private getAllJumpLinksIndexes = (arr: string): number[] => {
    const indexes: number[] = [];

    let i = -1;
    while ((i = arr.indexOf(this.jumpLinkCharacter, i + 1)) !== -1) {
      indexes.push(i);
    }
    return indexes;
  };

  private getNextJumpLinkIndex = () => {
    const index = this.jumpLinksIndexes.indexOf(this.currentJumpLink);
    return this.jumpLinksIndexes[index + 1];
  };

  private setJumpLinkSelection = (index: number) => {
    if (this.documentEditor) {
      this.documentEditor.setSelection(index, 1);
      this.currentJumpLink = index;
    }
  };

  public jumpLinksHandler = () => {
    if (this.documentEditor) {
      const text = this.documentEditor.getText();
      this.jumpLinksIndexes = this.getAllJumpLinksIndexes(text);

      if (!this.jumpLinksIndexes.length) return;

      const index = this.getNextJumpLinkIndex();
      const isFirstJumpLink =
        typeof this.currentJumpLink === "undefined" ||
        typeof index === "undefined";

      this.setJumpLinkSelection(
        isFirstJumpLink ? this.jumpLinksIndexes[0] : index
      );
    }
  };

  /** Extends Quill shortcuts bindings
   * https://quilljs.com/docs/modules/keyboard
   */
  public getBindings = () => {
    return {
      custom: {
        key: this.f6KeyCode,
        shiftKey: true,
        ctrlKey: true,
        handler: this.jumpLinksHandler
      }
    };
  };

  public getWordsBeforeIndexFromString = (string: string, index: number) => {
    const stringBeforeIndex = string.substring(0, index);

    const stringWithNoLineBreak = stringBeforeIndex.replace(
      /(\r\n|\n|\r|\t)/gm,
      " "
    );

    const words = stringWithNoLineBreak.split(" ");

    return words;
  };

  private countEmptyStringFromArrayAfterText = (
    stringArray: string[],
    text: string
  ) => {
    let emptyStringsAfterInsertingShortcut = 0;

    for (let i = stringArray.length - 1; i > 0; i--) {
      if (stringArray[i] !== text && stringArray[i] === "") {
        emptyStringsAfterInsertingShortcut++;
      } else {
        break;
      }
    }

    return emptyStringsAfterInsertingShortcut;
  };

  private keyDownCallback = (evt: KeyboardEvent) => {
    if (evt.code === IKeyCodes.Space) {
      this.documentEditor?.once(
        TEXT_CHANGE_EVENT,
        (delta, oldDelta, source) => {
          if (source === USER_SOURCE) {
            let newRetainIndex = delta.ops?.find(op => op.retain)?.retain ?? 0;

            const activeAutofills = this.root.document.activeAutofills;

            const insertedString =
              oldDelta.ops
                ?.filter(op => op.insert)
                .map(x => x.insert)
                .join("") ?? "";

            const next = delta.ops?.find(op => op.insert);

            const autofill = activeAutofills.find(autofill => {
              const prevWords = this.getWordsBeforeIndexFromString(
                insertedString,
                newRetainIndex
              );

              // ----- Readjusting retainIndex -----
              // If there are empty strings existed before current cursor's index
              const emptyStringsAfterInsertingShortcut =
                this.countEmptyStringFromArrayAfterText(
                  prevWords,
                  autofill.shortcut
                );

              newRetainIndex =
                newRetainIndex - emptyStringsAfterInsertingShortcut;

              const prevWordsWithNoEmptyString = prevWords.filter(
                x => x !== ""
              );

              const firstWordFound =
                prevWordsWithNoEmptyString[
                  prevWordsWithNoEmptyString.length - 1
                ];

              return (
                autofill.shortcut ===
                firstWordFound.trim() + next?.insert.toString().trim()
              );
            });

            if (autofill) {
              const shortcutLength = autofill.shortcut.length;

              const targetIndex = newRetainIndex - shortcutLength;
              this.documentEditor?.deleteText(
                targetIndex,
                shortcutLength,
                SILENT_SOURCE
              );

              this.root.document
                .renderTemplate(autofill.id, {
                  contentType: "html",
                  context: this.context
                })
                .then(({ content }) => {
                  this.documentEditor?.clipboard.dangerouslyPasteHTML(
                    targetIndex,
                    content
                  );

                  const currentCursorIndex = targetIndex + content.length;
                  setTimeout(
                    () =>
                      this.documentEditor?.setSelection(currentCursorIndex, 0),
                    0
                  );
                });
            }
          }
        }
      );
    }
  };

  public unSubscribeKeydownListener() {
    this.documentEditor?.root.removeEventListener(
      "keypress",
      this.keyDownCallback,
      true
    );
  }

  public replace() {
    if (this.documentEditor?.root) {
      this.documentEditor?.root.addEventListener(
        "keypress",
        this.keyDownCallback
      );
    }
  }

  public insertShortcutContent = async (autofill: AutofillDto) => {
    const text = await this.root.document.renderTemplate(autofill.id, {
      contentType: "html",
      context: this.context
    });

    const currentIndex = this.documentEditor?.getSelection()?.index ?? 0;
    this.documentEditor?.clipboard.dangerouslyPasteHTML(
      currentIndex,
      text.content
    );
  };
}
