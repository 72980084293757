import { Field, FieldRenderProps, useField } from "react-final-form";

import { Slider, SliderProps } from "@bps/fluent-ui";

import { ExposedFieldProps } from "./FinalForm.tsx";
import { ReadOnlyTextField } from "./read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

type SliderFieldProps = Omit<
  SliderProps,
  "value" | "onChange" | "defaultChecked" | "defaultLowerValue" | "defaultValue"
> &
  ExposedFieldProps<SliderProps["value"] | string, HTMLElement>;

const SliderFieldAdapter = (
  props: FieldRenderProps<SliderProps["value"]> &
    FieldRenderProps<SliderProps["onChange"]> &
    SliderFieldProps
) => {
  const { meta: formMeta } = useField(props.input.name);

  if (formMeta?.data?.readOnly) {
    return <ReadOnlyTextField label={props.label} value={props.input.value} />;
  }

  return (
    <Slider
      {...props}
      onChange={value => {
        props.input.onChange(value);
        if (props.onChange) props.onChange(value);
      }}
      value={props.input.value}
    />
  );
};

export const SliderField: React.FunctionComponent<SliderFieldProps> = props => (
  <Field component={SliderFieldAdapter} {...props} />
);
