import { isEqual } from "lodash";
import { Field } from "react-final-form";

import {
  Checkbox,
  IDropdownOption,
  SelectableOptionMenuItemType
} from "@bps/fluent-ui";
import {
  ALL_DAYS_OF_WEEK,
  dayOfWeekName,
  DaysOfWeek,
  isWeekDay
} from "@bps/utils";
import {
  DropdownField,
  DropdownFieldProps
} from "@ui-components/form/DropdownField.tsx";

interface IWeekdaysDropdownFieldProps
  extends Omit<DropdownFieldProps, "options"> {
  daysOfWeekOnly?: boolean;
}

export const WeekdaysDropdownField = (props: IWeekdaysDropdownFieldProps) => {
  const { name, label, placeholder, styles } = props;

  const renderCustomDayOption = (params: {
    option: IDropdownOption;
    isInderminate: (value: string[]) => boolean;
    isSelected: (value: string[]) => boolean;
    valueOnChecked: number[];
  }) => {
    const { option, isInderminate, isSelected, valueOnChecked } = params;
    return (
      <Field
        key={option.key}
        name={name}
        format={x => x}
        subscribe={{ value: true }}
      >
        {({ input: { value, onChange } }) => {
          const selectedDays = format(value) || [];
          const indeterminate = isInderminate(selectedDays);

          return (
            <Checkbox
              automationAttribute="render-custom-day-option-checkbox"
              onChange={(ev, checked) =>
                onChange(checked || indeterminate ? valueOnChecked : [])
              }
              indeterminate={indeterminate}
              checked={isSelected(selectedDays)}
              label={option.text}
              styles={{
                root: {
                  display: "flex",
                  paddingLeft: 8,
                  paddingRight: 8,
                  minHeight: 36,
                  alignItems: "center"
                }
              }}
            />
          );
        }}
      </Field>
    );
  };

  return (
    <DropdownField
      {...props}
      styles={
        styles || {
          root: {
            width: 500
          }
        }
      }
      name={name}
      multiSelect
      placeholder={placeholder || "Select days"}
      label={label || "Day(s)"}
      isEqual={isEqual}
      format={format}
      parse={parse}
      options={
        props.daysOfWeekOnly === true ? daysOfWeekOptions : allDaysOfWeekOptions
      }
      onRenderItem={(option, defaultRenderer) => {
        if (option?.key === "week_days") {
          return renderCustomDayOption({
            option,
            isInderminate: isPartialWeekDays,
            isSelected: isWeekDays,
            valueOnChecked: [
              DaysOfWeek.Monday,
              DaysOfWeek.Tuesday,
              DaysOfWeek.Wednesday,
              DaysOfWeek.Thursday,
              DaysOfWeek.Friday
            ]
          });
        } else if (option?.key === "every_day") {
          return renderCustomDayOption({
            option,
            isInderminate: value => !!value.length && value.length < 7,
            isSelected: isEveryDay,
            valueOnChecked: ALL_DAYS_OF_WEEK
          });
        }
        return defaultRenderer ? defaultRenderer(option) : null;
      }}
      onRenderTitle={(options, defaultRenderer) => {
        if (options) {
          const selectedDays = options.map(x => x.key as string);
          if (isWeekDays(selectedDays)) {
            return <span>Weekdays</span>;
          } else if (isEveryDay(selectedDays)) {
            return <span>Every day</span>;
          }
        }
        return defaultRenderer ? defaultRenderer(options) : null;
      }}
    />
  );
};

const daysOfWeekOptions: IDropdownOption[] = ALL_DAYS_OF_WEEK.map(weekDay => {
  return { key: `${weekDay}`, text: dayOfWeekName(weekDay) ?? "--" };
});

const allDaysOfWeekOptions: IDropdownOption[] = [
  { key: "week_days", text: "Week days" },
  { key: "every_day", text: "Every day" },
  { key: "sep", itemType: SelectableOptionMenuItemType.Divider, text: "-" },
  ...daysOfWeekOptions
];

const everyDayKey = daysOfWeekOptions.map(dow => dow.key as string);

const isWeekDayStr = (dayOfWeek: string) => isWeekDay(parseInt(dayOfWeek));

const weekDays = everyDayKey.filter(isWeekDayStr);

const isWeekDays = (days: string[]) =>
  days.length === 5 && weekDays.every(x => days.includes(x));

const isPartialWeekDays = (days: string[]) =>
  !!days.length && days.length < 5 && days.every(isWeekDayStr);

const isEveryDay = (days: string[]) =>
  days.length === 7 && everyDayKey.every(x => days.includes(x));

/**
 * Convert from option key to numeric day and
 * sort values by their numeric value
 */
const parse = (value?: string[]): number[] | undefined =>
  value
    ?.map(x => parseInt(x))
    .sort((a, b) => ALL_DAYS_OF_WEEK.indexOf(a) - ALL_DAYS_OF_WEEK.indexOf(b));

/**
 * Convert from numeric Day to option key and
 * sort selected options of the drop down in the same order than its options
 */
const format = (value?: number[]): string[] | undefined =>
  value
    ?.sort((a, b) => ALL_DAYS_OF_WEEK.indexOf(a) - ALL_DAYS_OF_WEEK.indexOf(b))
    .map(x => x.toString());
