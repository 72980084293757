import { FontWeights } from "@bps/fluent-ui";

import {
  ReadOnlyChoiceGroupStyles,
  ReadOnlyChoiceGroupStylesProps
} from "./ReadOnlyChoiceGroup.types.ts";

export const getReadOnlyChoiceGroupStyles = (
  props: ReadOnlyChoiceGroupStylesProps
): ReadOnlyChoiceGroupStyles => {
  const { theme } = props;
  return {
    label: {
      ...theme.fonts.medium,
      fontWeight: FontWeights.semibold,
      padding: "5px 0"
    },
    flexContainer: {
      minHeight: 20,
      marginTop: 8
    }
  };
};
