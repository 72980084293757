import { FunctionComponent, useEffect, useRef } from "react";

import { hasMore } from "@libs/api/utils/paging.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";

export type LegacyScrollListenerProps = {
  /**
   * How long to wait before triggering onScroll related events. Calls are delayed for performance.
   * Default is 200ms.
   */
  delay?: number;
  /**
   * This is called when the scrollable element has been scrolled to the end (after the configured delay)
   */
  onScrolledToBottom?: () => void;
  /**
   * The search and next query results are used to trigger getting additional data until the scrollable area is full.
   */
  searchQueryResult?: QueryResult<any>;
};

const OFFSET_MARGIN = 100;

/**
 * This ScrollListener has been superceded by another version which (when combined with useInfiniteScroll) handles
 *  much of the pagination internally to avoid duplication and inconsistencies. Where possible avoid using this version
 *  as it will not be as up-to-date as the other version and may eventually be removed.
 */
export const LegacyScrollListener: FunctionComponent<
  LegacyScrollListenerProps
> = ({ searchQueryResult, onScrolledToBottom, delay }) => {
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const ticking = useRef<boolean>(false);

  useEffect(() => {
    const scrollableElement = triggerRef.current?.closest(
      "*[data-is-scrollable='true']"
    );

    if (scrollableElement) {
      const handleOnScroll = () => {
        if (!ticking.current) {
          window.setTimeout(() => {
            if (
              !searchQueryResult ||
              (searchQueryResult && hasMore(searchQueryResult))
            ) {
              const offset =
                scrollableElement.scrollHeight -
                scrollableElement.scrollTop -
                scrollableElement.clientHeight;

              const isAtBottom = offset <= OFFSET_MARGIN;

              if (isAtBottom && onScrolledToBottom) {
                onScrolledToBottom();
              }
            }

            ticking.current = false;
          }, delay || 200);
        }
        ticking.current = true;
      };

      scrollableElement.addEventListener("scroll", handleOnScroll);

      return () => {
        scrollableElement.removeEventListener("scroll", handleOnScroll);
      };
    }

    return () => {};
  }, [delay, searchQueryResult, onScrolledToBottom]);

  return <div ref={triggerRef} style={{ display: "none" }} />;
};
