import { Observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useState } from "react";

import {
  IImageStyles,
  Image,
  IPersonaProps as IPersonaPropsFluent,
  mergeFuncStyles,
  mergeStyles,
  mergeStyleSets,
  Persona as PersonaBase,
  PersonaSize,
  useTheme
} from "@bps/fluent-ui";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { horizontalItem } from "@ui-components/pickers/contact-picker/compactPeoplePickerOf.tsx";
import { getContactPickerItemStyles } from "@ui-components/pickers/contact-picker/ContactPicker.styles.ts";

import { PersonTypeIcon } from "./PersonTypeIcon.tsx";

interface IPersonaProps extends IPersonaPropsFluent {
  /**
   * id used for unique coin colour generation
   */
  id?: string;
  contactType?: ContactType;
  imageStyles?: Partial<IImageStyles>;
  /**
   * display a badge next to them name based on certain statuses
   */
  status?: ContactStatus | UserStatus;
  onRenderNoImageInitials?: IPersonaPropsFluent["onRenderInitials"];
}

const getCoinSize = (size: PersonaSize | undefined) => {
  switch (size) {
    case PersonaSize.size100:
      return "100px";
    case PersonaSize.size72:
      return "72px";
    case PersonaSize.size56:
      return "56px";
    case PersonaSize.size48:
      return "48px";
    case PersonaSize.size40:
      return "40px";
    case PersonaSize.size24:
      return "24px";
    default:
      return size;
  }
};

/**
 * Wraps the Fluent UI Persona and if the contact type is given
 * adds the type button in the top left corner.
 */

export const Persona: FunctionComponent<IPersonaProps> = ({
  size,
  styles,
  imageInitials,
  imageUrl,
  onRenderOptionalText,
  onRenderPrimaryText,
  onRenderSecondaryText,
  onRenderTertiaryText,
  status,
  imageStyles,
  onRenderNoImageInitials,
  contactType,
  ...personaProps
}) => {
  const theme = useTheme();

  // maintains whether the imageUrl prop contains a valid image url
  // if it does not, component will display fallback options
  const [imageIsInvalid, setImageIsInvalid] = useState<boolean>(false);

  useEffect(() => {
    setImageIsInvalid(false);
  }, [imageUrl]);

  const _imageUrl = (!imageIsInvalid && imageUrl) || undefined;

  const onError = () => {
    setImageIsInvalid(true);
  };

  const onRenderCoin = (props: IPersonaProps): JSX.Element => {
    const imageSize = getCoinSize(props.size);
    return (
      <Observer>
        {() => (
          <>
            {imageUrl && (
              <Image
                src={props.imageUrl}
                alt={props.imageAlt}
                width={imageSize}
                height={imageSize}
                styles={mergeStyleSets(
                  {
                    root: {
                      width: imageSize,
                      height: imageSize,
                      borderRadius: "50%"
                    },
                    image: {
                      objectFit: "cover"
                    }
                  } as IImageStyles,
                  imageStyles
                )}
                className="person-type-icon"
              />
            )}
            {!!contactType && (
              <div>
                <PersonTypeIcon contactType={contactType} size={size} />
              </div>
            )}
          </>
        )}
      </Observer>
    );
  };

  //  As the Fluent UI Persona is not an observer, it will not rerender when
  //  observables used by the onRender callbacks change.  Observer wrapper is
  //  added to ensure they are not forgotten

  const renderCallbacks: Partial<IPersonaProps> = {};
  if (onRenderOptionalText) {
    renderCallbacks.onRenderOptionalText = props => (
      <Observer>{() => onRenderOptionalText(props)}</Observer>
    );
  }
  if (onRenderPrimaryText) {
    renderCallbacks.onRenderPrimaryText = props => (
      <Observer>{() => onRenderPrimaryText(props)}</Observer>
    );
  }
  if (onRenderSecondaryText) {
    renderCallbacks.onRenderSecondaryText = props => (
      <Observer>{() => onRenderSecondaryText(props)}</Observer>
    );
  }
  if (onRenderTertiaryText) {
    renderCallbacks.onRenderTertiaryText = props => (
      <Observer>{() => onRenderTertiaryText(props)}</Observer>
    );
  }

  if (!imageInitials && !imageUrl) {
    // Persona with NO initials and NO image
    // is new patient/contact: we don't want to see the badge with type icon because type icon
    // is shown in coin and so ignore contact type
    return (
      <PersonaBase
        {...personaProps}
        {...renderCallbacks}
        onRenderInitials={onRenderNoImageInitials}
        size={size}
        styles={styles}
      />
    );
  } else {
    const mergedStyles: IPersonaProps["styles"] = mergeFuncStyles(
      status === ContactStatus.Deceased ||
        status === ContactStatus.Inactive ||
        status === UserStatus.Inactive
        ? {
            primaryText: mergeStyles(
              status === ContactStatus.Deceased
                ? getContactPickerItemStyles(theme).deceased
                : getContactPickerItemStyles(theme).inactive,
              horizontalItem,
              {
                selectors: {
                  "&:after": {
                    position: "initial",
                    display: "inline-flex",
                    width: "auto",
                    paddingLeft: 4,
                    paddingRight: 4,
                    marginLeft: 8
                  }
                }
              }
            )
          }
        : undefined,
      styles
    );

    // Persona with image and contact type badge (with initials)
    // or without badge like the appointment modal persona coin
    return (
      <PersonaBase
        imageInitials={imageInitials}
        size={size}
        styles={mergedStyles}
        {...personaProps}
        {...renderCallbacks}
        imageUrl={_imageUrl}
        imageAlt={imageInitials}
        onRenderCoin={onRenderCoin}
        onError={onError}
      />
    );
  }
};
