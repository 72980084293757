import { PropsWithChildren } from "react";

import { Country } from "@libs/enums/country.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface WhenCountryProps {
  is: Country;
  else?: React.ReactNode | null;
}

export const WhenCountry: React.FC<PropsWithChildren<WhenCountryProps>> = ({
  is,
  children,
  else: useInstead = null
}) => {
  const { core } = useStores();
  const country = core.tenantDetails && core.tenantDetails.country;
  const isCountry = country === is;
  return <>{isCountry ? children : useInstead}</>;
};
