import { classNamesFunction, FontSizes, IStyle } from "@bps/fluent-ui";

import {
  SubmissionFormStyleProps,
  SubmissionFormStyles
} from "./SubmissionForm.types.ts";

export const getSubmissionFormClassNames = classNamesFunction<
  SubmissionFormStyleProps<any>,
  SubmissionFormStyles
>();

export const getStyles = (
  props: SubmissionFormStyleProps<any>
): SubmissionFormStyles => {
  const { className, theme } = props;
  const rootStyles: IStyle = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    flex: 1,
    zIndex: 100
  };
  return {
    root: [className, rootStyles],
    main: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      height: "100%",
      backgroundColor: theme.palette.white,
      overflowY: "hidden",
      zIndex: 0
    },
    sidePanel: {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: theme.palette.neutralLighterAlt,
      zIndex: -1
    },
    fields: { flexGrow: 1, overflowY: "auto" },
    subComponentStyles: {
      submitErrorMessage: () => ({
        root: {
          overflowY: "auto",
          marginTop: 16,
          flexShrink: 0,
          "& .alert-text": {
            fontSize: FontSizes.small
          }
        }
      }),
      buttonSeparator: () => ({
        root: {
          flexShrink: 0
        }
      })
    }
  };
};
