import { ExtendedPickerProps, extendToSinglePicker } from "@bps/fluent-ui";

/**
 * HOC that turns a persona based picker component into a single picker.
 * See extendToSingleTagPicker
 * @param Component
 */
export const extendToSinglePersonaPicker = <
  P extends Pick<
    ExtendedPickerProps<TSuggestionItem, TItem>,
    "onChange" | "componentRef" | "styles" | "onInputChange"
  >,
  TSuggestionItem,
  TItem = TSuggestionItem
>(
  Component: React.FunctionComponent<P>
) =>
  extendToSinglePicker<P, TSuggestionItem, TItem>(Component, props => ({
    // when the picker takes focus, we want to show the selected item as a ghost value behind the input
    // Whenever the user starts typing (state.isInputEmpty === false), the ghost text needs to be invisible.
    itemsWrapper: [
      {
        "& > .ms-PickerPersona-container": {
          position: "initial"
        },
        "& > .ms-PickerPersona-container .ms-Persona-details ": {
          paddingRight: 24
        },
        "& > .ms-PickerPersona-container .ms-PickerItem-content": {
          position: "absolute"
        },
        "& > .ms-PickerPersona-container, & > .ms-PickerPersona-container:hover":
          {
            background: "none"
          },
        "& > .ms-PickerPersona-container .ms-PickerItem-removeButton": {
          position: "absolute",
          right: 4
        }
      },
      props.isFocused && {
        "& > .ms-PickerPersona-container .ms-PickerItem-content": {
          position: "absolute",
          opacity: props.isInputEmpty ? 1 : 0
        },
        "& > .ms-PickerPersona-container .ms-PickerItem-content .ms-Persona-coin":
          {
            opacity: props.isInputEmpty ? 0.5 : 0
          }
      }
    ]
  }));
