import { Field, FieldRenderProps, useField } from "react-final-form";

import { ILinkProps, Link, Stack, Text } from "@bps/fluent-ui";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";

import { ExposedFieldProps } from "./FinalForm.tsx";

type HtmlElementType = HTMLElement;

export type LinkFieldProps = ExposedFieldProps<string, HtmlElementType> &
  Omit<ILinkProps, "onClick" | "defaultValue" | "automationAttribute"> & {
    description: string;
    options: KeyTextValue[];
    defaultText?: string;
  };

const LinkAdapter = (
  props: FieldRenderProps<string, HtmlElementType> & LinkFieldProps
) => {
  const {
    input: { value, onChange, name },
    meta,
    description,
    options,
    defaultText,
    ...linkProps
  } = props;

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const index = options.findIndex(opt => opt.key === value);
    onChange(index === 0 ? options[1].key : options[0].key);
  };

  const { meta: formMeta } = useField(name);

  const text =
    options.find(opt => opt.key === value)?.text || defaultText || "";

  return (
    <Stack tokens={{ childrenGap: 4 }}>
      <Text>{description}</Text>
      <Link
        onClick={handleClick}
        {...linkProps}
        name={name}
        disabled={linkProps.disabled || formMeta?.data?.disabled}
      >
        {text}
      </Link>
    </Stack>
  );
};

export const LinkField = (props: LinkFieldProps) => (
  <Field {...props} component={LinkAdapter} />
);
