import { ValidationErrors } from "final-form";
import { useMemo } from "react";
import { FormSpy } from "react-final-form";

import {
  Config,
  dataAttribute,
  DataAttributes,
  FontSizes,
  FontWeights,
  getAsteriskStyles,
  getConfig,
  ILinkProps,
  IStackProps,
  Link,
  mergeStyleSets,
  metaToLabelColor,
  Stack,
  Text,
  useTheme,
  Variant
} from "@bps/fluent-ui";
import { isDefined } from "@bps/utils";

/**
 * Wraps the Stack component to define a common gap between form fields
 * unless a gap is explicitly provided.
 * Also has an optional legend and actionButton props to specify a legend for the whole group
 * and extra button to do something with the field
 *
 * @param props - IStackProps & { legend?: string, actionButton?: ActionButtonProps, frame?: boolean }
 */

type Children = React.ReactElement[] | React.ReactElement;
interface FieldsetActionButtonProps {
  text: string | React.ReactNode;
  props?: ILinkProps;
  onRender?: (
    props?: Pick<FieldsetActionButtonProps, "props" | "text">,
    defaultRender?: (
      text: React.ReactNode,
      linkProps: ILinkProps | undefined
    ) => JSX.Element | undefined
  ) => React.ReactNode;
}

export const hasChildErrors = (
  childNames: string[],
  errors: ValidationErrors
): boolean => {
  return (
    !!errors &&
    Object.keys(errors).some(err => {
      //Handle errors for array fields with mutliple children
      if (Array.isArray(errors[err])) {
        return errors[err].find((error: string, index: any) => {
          return childNames.some(name => name.includes(`${err}[${index}]`));
        });
      } else {
        return childNames.some(name => name.includes(err));
      }
    })
  );
};

const getNames = (children: Children): string[] => {
  const array = Array.isArray(children) ? children : [children];

  return array
    .reduce((acc: string[], child: React.ReactElement) => {
      if (child && child.props.name) {
        return [...acc, child.props.name];
      } else if (child && child.props.children) {
        return [...acc, ...getNames(child.props.children)];
      }

      return acc;
    }, [])
    .filter(isDefined);
};

type FieldsetProps = IStackProps & {
  legend?: string;
  legendLevel?: Variant;
  actionButton?: FieldsetActionButtonProps;
  frame?: boolean;
  hasAsterisk?: boolean;
  disable?: boolean;
};

export const Fieldset: React.FunctionComponent<FieldsetProps> = (
  props: FieldsetProps
) => {
  const {
    legend,
    children,
    actionButton,
    hasAsterisk,
    legendLevel,
    styles,
    disable,
    ...rest
  } = props;

  const theme = useTheme();

  // get all name props from children, and prevent the recalling by using useMemo
  const names = useMemo(
    () => (legend && children ? getNames(children as Children) : []),
    [children, legend]
  );

  const tokens: IStackProps["tokens"] =
    typeof props.tokens === "function"
      ? props.tokens
      : {
          childrenGap: 8,
          ...props.tokens
        };

  const linkButton = (
    text: React.ReactNode,
    props: ILinkProps | undefined
  ): JSX.Element => {
    return (
      <Link
        {...props}
        {...dataAttribute(DataAttributes.Element, "fieldset-action-link")}
        styles={mergeStyleSets(props?.styles, {
          root: {
            marginLeft: "auto",
            fontSize: FontSizes.small,
            fontWeight: FontWeights.regular
          }
        })}
      >
        {text}
      </Link>
    );
  };

  const onRenderActionButton = (): JSX.Element | null => {
    if (actionButton) {
      const { onRender, text, props } = actionButton;
      return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          {onRender
            ? onRender({ props, text }, linkButton)
            : linkButton(text, props)}
        </Stack>
      );
    }
    return null;
  };

  const onRenderLegend = (): JSX.Element | null => {
    if (legend) {
      const { variant, fontWeight }: Config = getConfig(legendLevel);

      return (
        <FormSpy
          subscription={{ active: true, errors: true, submitFailed: true }}
        >
          {({ active: currentActive, errors, submitFailed }) => {
            // check if the current active fields is a part of the group
            const active: boolean =
              !!currentActive && names.includes(currentActive.toString());

            // check if there are validation errors among the children fields

            const hasErrorMessage: boolean =
              submitFailed && hasChildErrors(names, errors);

            return (
              <Text
                as="legend"
                {...dataAttribute(DataAttributes.Element, "fieldset-legend")}
                data-active={active}
                variant={variant}
                block
                styles={mergeStyleSets(props.styles, {
                  root: {
                    fontWeight,
                    color: metaToLabelColor({ active, hasErrorMessage }, theme),
                    padding: 0,
                    position: "relative",
                    selectors: hasAsterisk
                      ? getAsteriskStyles(theme)
                      : undefined
                  }
                })}
              >
                {legend}
              </Text>
            );
          }}
        </FormSpy>
      );
    }

    return null;
  };

  return (
    <Stack
      as="fieldset"
      styles={(_prop, theme) =>
        mergeStyleSets(
          {
            root: {
              minWidth: "auto"
            }
          },
          {
            root: props.frame && {
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: theme.palette.neutralLight,
              borderRadius: "2px",
              padding: 8
            }
          },
          props && styles
        )
      }
    >
      {children && (legend || actionButton) && (
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { padding: "5px 0" } }}
        >
          {onRenderLegend()}
          {!disable && onRenderActionButton()}
        </Stack>
      )}

      <Stack tokens={tokens} {...rest}>
        {children}
      </Stack>
    </Stack>
  );
};
