import {
  CompactPeoplePicker,
  ExtendedPickerProps,
  extendPicker,
  IHasKey,
  IPersonaProps,
  IStyle,
  Stack
} from "@bps/fluent-ui";
import { Model } from "@libs/models/Model.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactPickerSuggestionItem } from "@ui-components/pickers/contact-picker/ContactPickerSuggestionItem.tsx";

export const horizontalItem: IStyle = {
  paddingRight: 45,
  selectors: {
    "&:after": {
      position: "relative",
      height: 16,
      width: "auto",
      transform: "rotate(0)",
      writingMode: "initial",
      top: 0,
      padding: "0 4px",
      fontSize: 10
    }
  }
};

export const compactPeoplePickerOf = <TItem extends any>() => {
  const ExtendedComponent = extendPicker(CompactPeoplePicker);

  return (props: ExtendedPickerProps<IPersonaProps, TItem>) => {
    const checkPersonaSelected = (key: string) =>
      !!props.selectedKeys?.includes(key);

    const checkPersonaTagged = (key: string) =>
      !!props.tagInUseKeys?.includes(key);

    const onRenderSuggestionsItem: ExtendedPickerProps<
      IPersonaProps,
      TItem
    >["onRenderSuggestionsItem"] =
      // change the style of the persona suggestion when it is already selected
      props.onRenderSuggestionsItem ??
      ((personaProps, itemProp, item) => (
        <ContactPickerSuggestionItem
          personaProps={personaProps}
          contact={item as Contact}
          isSelected={checkPersonaSelected((personaProps as IHasKey)?.key)}
          isTagged={checkPersonaTagged((personaProps as IHasKey)?.key)}
        />
      ));

    const onResolveSuggestionItemTagged: ExtendedPickerProps<
      IPersonaProps,
      TItem
    >["onResolveSuggestionItem"] = (item, context) => {
      const returnProps =
        props.onResolveSuggestionItem &&
        props.onResolveSuggestionItem(item, context);

      const inUse =
        context.role === "suggestion" &&
        checkPersonaTagged((item as Model<any>)?.id)
          ? "(in use)"
          : undefined;

      return {
        ...returnProps,
        text: item["name"],
        onRenderPrimaryText: props => (
          <Stack
            horizontal
            tokens={{ childrenGap: 5 }}
            horizontalAlign="space-between"
          >
            <Stack.Item styles={{ root: { overflow: "hidden" } }}>
              {props!.text}
            </Stack.Item>
            <Stack.Item>{inUse}</Stack.Item>
          </Stack>
        )
      };
    };

    const onItemSelectedTagged = (item: IHasKey) =>
      checkPersonaTagged(item?.key) ? null : item;

    return (
      <ExtendedComponent
        {...(props as any)}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
        onItemSelected={
          props.tagInUseKeys?.length
            ? onItemSelectedTagged
            : props.onItemSelected
        }
        onResolveSuggestionItem={
          props.tagInUseKeys?.length
            ? onResolveSuggestionItemTagged
            : props.onResolveSuggestionItem
        }
      />
    );
  };
};
