import { FunctionComponent } from "react";
import { Field, FieldRenderProps } from "react-final-form";

import {
  ColourSelect,
  ColourSelectProps,
  mergeFuncStyles,
  mergeStyleSets,
  useTheme
} from "@bps/fluent-ui";
import { ExposedFieldProps } from "@ui-components/form/FinalForm.tsx";
import {
  FormItemField,
  FormItemFieldProps
} from "@ui-components/form/FormItemField.tsx";
import { getFieldBorderColors } from "@ui-components/form/selects/utils.ts";

type HtmlElementType = HTMLElement;

type ColourSelectFieldProps = Omit<
  ColourSelectProps,
  "label" | "value" | "onChange" | "theme"
> & {
  label?: string;
  horizontalLabel?: boolean;
  fieldItemStyles?: FormItemFieldProps["styles"];
  validateOnInitialize?: boolean;
} & ExposedFieldProps<ColourSelectProps["value"], HtmlElementType>;

const ColourSelectFieldAdapter = (
  props: FieldRenderProps<ColourSelectProps["value"], HtmlElementType> &
    Omit<ColourSelectProps, "label" | "value" | "onChange" | "theme"> & {
      label?: string;
      horizontalLabel?: boolean;
      fieldItemStyles?: FormItemFieldProps["styles"];
      validateOnInitialize?: boolean;
    }
) => {
  const {
    disabled,
    styles,
    label,
    description,
    horizontalLabel,
    required,
    placeholder,
    meta,
    input: { name, value, onChange, onBlur, onFocus },
    fieldItemStyles,
    validateOnInitialize,
    ...rest
  } = props;

  const theme = useTheme();

  return (
    <FormItemField
      name={name}
      label={label}
      styles={mergeStyleSets(fieldItemStyles, {
        headerWrapper: { paddingBottom: 0 }
      })}
      disabled={disabled}
      horizontalLabel={horizontalLabel}
      required={required}
      validateOnInitialize={validateOnInitialize}
    >
      <ColourSelect
        {...rest}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        styles={mergeFuncStyles(getFieldBorderColors(meta, theme), styles)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        description={description}
      />
    </FormItemField>
  );
};

export const ColourSelectField: FunctionComponent<
  ColourSelectFieldProps
> = props => <Field {...props} component={ColourSelectFieldAdapter} />;
