import { FunctionComponent, memo } from "react";

import { Dialog } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { maxLength, minLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { SubmissionForm } from "./form/submission-form/SubmissionForm.tsx";

export interface CompleteDialogProps {
  formName: string;
  hidden: boolean;
  title: string;
  subText?: string;
  reasonLabel?: string;
  okButtonText?: string;
  cancelButtonText?: string;
  maxWidth?: number;
  minWidth?: number;
  textInputLabel?: string;
  hideButtonsSeparator?: boolean;
  onConfirm: (notes?: string) => Promise<void>;
  onCancel?: () => void;
}

export interface CompleteDialogFormValues {
  notes?: string;
}

export class CompleteDialogValidator extends Validator<CompleteDialogFormValues> {
  constructor() {
    super();
    this.forField("notes", [maxLength(250), minLength(0)]);
  }
}

const nameof = nameOfFactory<CompleteDialogFormValues>();
const validator = new CompleteDialogValidator();

export const CompleteDialog: FunctionComponent<CompleteDialogProps> = memo(
  props => {
    const getDialogContent = (): React.ReactNode => {
      return (
        <Fieldset>
          <TextInputField
            multiline={true}
            name={nameof("notes")}
            label={textInputLabel ? textInputLabel : "Notes"}
            placeholder="250 characters max"
            resizable={false}
            maxLength={250}
            rows={3}
          />
        </Fieldset>
      );
    };

    const {
      formName,
      title,
      subText,
      maxWidth,
      minWidth,
      hidden,
      textInputLabel,
      hideButtonsSeparator
    } = props;
    return (
      <Dialog
        hidden={hidden}
        maxWidth={maxWidth ? maxWidth : 340}
        minWidth={minWidth ? minWidth : undefined}
        onDismiss={props.onCancel}
        dialogContentProps={{
          title,
          subText,
          showCloseButton: true,
          styles: {
            inner: { padding: 0 },
            subText: { padding: "0 24px", margin: 0 }
          }
        }}
      >
        <SubmissionForm<CompleteDialogFormValues>
          formName={formName}
          onSubmit={values => {
            return props.onConfirm(values.notes);
          }}
          buttonsProps={{
            disableSubmitOnPristine: false,
            hideButtonsSeparator: hideButtonsSeparator ?? true,
            submitButtonProps: {
              text: props.okButtonText ? props.okButtonText : "OK",
              iconProps: {}
            },
            cancelButtonProps: {
              text: props.cancelButtonText ? props.okButtonText : "Cancel",
              iconProps: {}
            },
            onCancel: props.onCancel,
            styles: () => ({
              root: {
                marginTop: 0,
                padding: "16px 24px 24px"
              }
            })
          }}
          render={getDialogContent}
          styles={{ fields: { padding: "8px 24px 24px" } }}
          validate={values => validator.validate(values)}
        />
      </Dialog>
    );
  }
);
