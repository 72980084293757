import { FunctionComponent } from "react";

import {
  // eslint-disable-next-line no-restricted-imports
  ShimmeredDetailsList as ShimmeredDetailsListCustom,
  ShimmeredDetailsListProps
} from "@bps/fluent-ui";
import { ErrorAlert } from "@ui-components/Alert.tsx";

export const ShimmeredDetailsList: FunctionComponent<
  ShimmeredDetailsListProps
> = props => {
  return (
    <ShimmeredDetailsListCustom
      {...props}
      onRenderError={
        props.onRenderError
          ? props.onRenderError
          : errorMessage => <ErrorAlert error={errorMessage} />
      }
    />
  );
};
