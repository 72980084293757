import {
  Decorator,
  FORM_ERROR,
  FormApi,
  FormState,
  SubmissionErrors
} from "final-form";
import createDecorator from "final-form-focus";
import { Fragment, useCallback, useEffect, useMemo, useRef } from "react";
import { FormRenderProps, FormSpy } from "react-final-form";

import {
  CenteredLargeSpinner,
  dataAttribute,
  DataAttributes,
  focusOnNextElement,
  Overlay,
  SpinnerSize,
  styled,
  useTheme
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";

import { FinalForm, FinalFormProps, renderComponent } from "../FinalForm.tsx";
import { FormErrorMessage } from "./FormErrorMessage.tsx";
import { FormSubmitButtons } from "./FormSubmitButtons.tsx";
import { FormSubmitWarningMessage } from "./FormSubmitWarningMessage.tsx";
import {
  getStyles,
  getSubmissionFormClassNames
} from "./SubmissionForm.styles.ts";
import {
  SubmissionFormProps,
  SubmissionFormStyleProps,
  SubmissionFormStyles
} from "./SubmissionForm.types.ts";
import { getAllInputs } from "./utils.ts";

/**
 * SubmissionForm binds together Fluent Design Form, React Final Form and the FormSubmitButtons components.
 * By default, it renders right-aligned Save/Cancel form buttons hooked to react final form.
 */
const SubmissionFormBase = <FormValues extends object = object>(
  props: SubmissionFormProps<FormValues>
) => {
  const {
    onRenderButtons = props => <FormSubmitButtons {...props} />,
    hideButtons,
    render,
    children,
    component,
    className,
    extraPromptCondition,
    styles,
    onSubmitSucceeded,
    onSubmit,
    onSubmitFailed,
    buttonsProps = {},
    autoFocus = true,
    readOnly = false,
    disabled = false,
    willUnmount,
    sidePanel,
    formId,
    initialValues,
    warning,
    warningMessageBarProps,
    LayoutWrapper = Fragment,
    onMount,
    validate,
    delayForSettingFieldsData,
    ...otherProps
  } = props;

  const theme = useTheme();

  const isMounted = useRef<boolean>(false);
  const formKey = useRef<string>(newGuid());

  // compile SubmissionForm meta data
  const meta = useMemo(() => ({ readOnly, disabled }), [readOnly, disabled]);

  const setFileFieldData = useCallback(
    (fileFieldName: string, data: object, form: FormApi<FormValues>) => {
      form.mutators.setFieldData(fileFieldName, data);
    },
    []
  );

  const formRef = useRef<HTMLFormElement | null>(null);
  const formApi = useRef<FormApi<FormValues> | undefined>(undefined);

  const focusOnErrors = useRef(
    createDecorator(getAllInputs(formKey.current)) as Decorator<object, object>
  );

  useEffect(() => {
    // focus the first input element
    setTimeout(() => {
      if (!readOnly && !disabled && autoFocus && formRef.current) {
        focusOnNextElement(formRef.current);
      }
    }, 1);
  }, [autoFocus, disabled, readOnly]);

  useEffect(() => {
    if (disabled !== undefined || readOnly !== undefined) {
      // set metadata as each field data meta
      setTimeout(() => {
        const fields = formApi.current?.getRegisteredFields() || [];

        if (formApi.current) {
          fields.forEach(fieldName => {
            setFileFieldData(
              fieldName,
              { disabled, readOnly },
              formApi.current!
            );
          });
        }
      }, delayForSettingFieldsData || 1);
    }
  }, [disabled, readOnly, setFileFieldData, delayForSettingFieldsData]);

  // unmount
  useEffect(() => {
    return () => {
      // call willUnmount callback on unmount
      if (willUnmount && formApi.current) {
        willUnmount(formApi.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _validate: FinalFormProps<FormValues>["validate"] = async values => {
    const formState = formApi.current?.getState();

    if (validate && !formState?.submitting) {
      try {
        return await validate(values);
      } catch (error) {
        return { [FORM_ERROR]: error };
      }
    }
    return undefined;
  };

  const _onSubmit: FinalFormProps<any>["onSubmit"] = async (
    values,
    api,
    complete //can go
  ) => {
    let result: void | undefined | SubmissionErrors;
    let hasThrown = false;

    const afterSubmission = () => {
      const formState = api.getState();
      if (formState.submitFailed && props.onSubmitFailed) {
        props.onSubmitFailed(values, api, formState.submitErrors);
      } else if (formState.submitSucceeded) {
        if (props.onSubmitSucceeded) {
          props.onSubmitSucceeded(values, api);
        }
      }
    };

    try {
      const onComplete = (errors?: SubmissionErrors) => {
        complete && complete(errors);

        afterSubmission();
      };

      result = await props.onSubmit(values, api, onComplete);
    } catch (error) {
      result = { [FORM_ERROR]: error };
      hasThrown = true;
    }
    // call complete explicitly if props.onSubmit function accepts 2 or less arguments.
    // otherwise, if the function has 3 or more arguments, it is its responsibility to call the complete function
    // unless it throws in which case complete is called with the error.
    if (props.onSubmit.length < 3 || hasThrown) {
      // complete callback expects to be called SubmissionError only (when result is FORM_ERROR)
      const completeArgs = hasThrown && result ? result : undefined;
      complete && complete(completeArgs);
      afterSubmission();
    }
    return;
  };

  return (
    <FinalForm
      {...otherProps}
      extraPromptCondition={extraPromptCondition}
      decorators={[focusOnErrors.current]}
      onSubmit={_onSubmit}
      initialValues={initialValues}
      validate={_validate}
    >
      {(formRenderProps: FormRenderProps<FormValues>) => {
        // persist form apt to pass on component unmount
        if (!formApi.current) {
          formApi.current = formRenderProps.form;
        }

        if (!readOnly) {
          // set the current form api to MultiFormPromptsController
          if (!isMounted.current && onMount) {
            onMount(formRenderProps.form);
          }
        }

        const classNames = getSubmissionFormClassNames(styles, {
          theme,
          className,
          formState: formRenderProps.form.getState()
        });

        const { children, render, component } = props;
        const formState: FormState<FormValues> =
          formRenderProps.form.getState();
        return (
          <LayoutWrapper>
            <form
              {...dataAttribute(
                DataAttributes.Element,
                `submission-form-${otherProps.formName}`
              )}
              ref={r => {
                formRef.current = r;
              }}
              name={formKey.current}
              noValidate
              className={classNames.root}
              onSubmit={formRenderProps.handleSubmit as any}
              id={formId}
            >
              <div className={classNames.main}>
                <FormSpy subscription={{ submitting: true }}>
                  {({ submitting }) =>
                    submitting && (
                      <Overlay styles={{ root: { zIndex: 100 } }}>
                        <CenteredLargeSpinner
                          {...dataAttribute(
                            DataAttributes.Element,
                            "submission-form-spinner"
                          )}
                          size={SpinnerSize.small}
                        />
                      </Overlay>
                    )
                  }
                </FormSpy>

                <div className={classNames.fields}>
                  {renderComponent(
                    { children, render, component, ...formRenderProps },
                    {
                      form: {
                        ...formRenderProps.form
                      },
                      handleSubmit: formRenderProps.handleSubmit
                    }
                  )}
                </div>
                <FormSubmitWarningMessage<FormValues>
                  warning={warning}
                  warningMessageBarProps={warningMessageBarProps}
                />
                <FormErrorMessage
                  styles={classNames.subComponentStyles.submitErrorMessage}
                />
                {!hideButtons &&
                  !meta?.readOnly &&
                  onRenderButtons(
                    typeof buttonsProps === "function"
                      ? buttonsProps(formRenderProps.form)
                      : buttonsProps,
                    formState
                  )}
              </div>

              {sidePanel && (
                <div className={classNames.sidePanel}>{sidePanel}</div>
              )}
            </form>
          </LayoutWrapper>
        );
      }}
    </FinalForm>
  );
};

const SubmissionFormUnTyped = styled<
  SubmissionFormProps,
  SubmissionFormStyleProps<any>,
  SubmissionFormStyles
>(SubmissionFormBase, getStyles);

export const SubmissionForm = <FormValues extends object = object>(
  props: SubmissionFormProps<FormValues>
) => <SubmissionFormUnTyped {...(props as SubmissionFormProps<any>)} />;
