import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  Checkbox,
  concatStyleSets,
  getAsteriskStyles,
  ICheckboxProps,
  ICheckboxStyleProps,
  metaToLabelColor
} from "@bps/fluent-ui";
import { ReadOnlyTextField } from "@ui-components/form/read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

import { ExposedFieldProps, getFieldErrorMessage } from "./FinalForm.tsx";

type HtmlElementType = HTMLElement;

export type CheckboxFieldProps = ExposedFieldProps<
  ICheckboxProps["checked"],
  HtmlElementType
> &
  Omit<ICheckboxProps, "value" | "defaultChecked" | "automationAttribute"> & {
    readOnly?: boolean;
    automationAttribute?: string;
  };

const CheckboxAdapter = (
  props: FieldRenderProps<ICheckboxProps["checked"], HtmlElementType> &
    ICheckboxProps
) => {
  const {
    input: { value, onChange, onBlur, onFocus, name },
    meta,
    styles,
    readOnly,
    required,
    ...checkboxProps
  } = props;

  const { meta: formMeta } = useField(name);

  if (readOnly || formMeta?.data?.readOnly) {
    if (value) {
      return <ReadOnlyTextField value={checkboxProps.label} />;
    } else {
      return null;
    }
  }

  const newStyles = (props: ICheckboxStyleProps) =>
    concatStyleSets(
      {
        label: metaToLabelColor(
          {
            active: meta.active,
            hasErrorMessage: !!getFieldErrorMessage(meta)
          },
          props.theme
        ),
        text: required ? getAsteriskStyles(props.theme) : undefined
      },
      styles
    );

  return (
    <Checkbox
      checked={value}
      onChange={(ev, checked) => onChange(checked)}
      name={name}
      {...checkboxProps}
      inputProps={{ ...checkboxProps.inputProps, onFocus, onBlur }}
      disabled={checkboxProps.disabled || formMeta?.data?.disabled}
      styles={newStyles}
    />
  );
};

/**
 * Use CheckboxField with final-form.
 * It can be used to map a boolean field to a Checkbox
 * @param props
 */
export const CheckboxField: React.FunctionComponent<
  CheckboxFieldProps
> = props => <Field {...props} component={CheckboxAdapter} />;
