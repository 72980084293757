import { FC, memo } from "react";

import {
  IStackStyles,
  ITextStyles,
  mergeStyleSets,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { ChangeLogDto } from "@libs/api/dtos/index.ts";

import { LastUpdatedDataFetcher } from "./LastUpdatedDataFetcher.tsx";

interface LastUpdatedRequiredChangeLog {
  changeLog: ChangeLogDto;
  lastUpdatedUserId?: string;
}

interface LastUpdatedRequiredUserId {
  lastUpdatedUserId?: string;
  changeLog?: ChangeLogDto;
}

export type LastUpdatedRequiredProps =
  | LastUpdatedRequiredChangeLog
  | LastUpdatedRequiredUserId;

type LastUpdatedTextProps = LastUpdatedRequiredProps & {
  styles?: IStackStyles;
  shortFormat?: boolean;
  textStyles?: ITextStyles;
  lastUpdatedDate?: DateTime;
  asLastRecorded?: boolean;
};
export const LastUpdatedText: FC<LastUpdatedTextProps> = memo(
  ({
    styles,
    changeLog,
    textStyles,
    shortFormat,
    asLastRecorded,
    lastUpdatedDate,
    lastUpdatedUserId
  }) => {
    return (
      <Stack horizontal styles={styles}>
        <LastUpdatedDataFetcher
          changeLog={changeLog}
          lastUpdatedUserId={lastUpdatedUserId}
        >
          {({ userName, date }) => {
            const getText = () => {
              if (asLastRecorded) {
                if (!lastUpdatedDate) return null;
                return (
                  <Stack horizontal wrap>
                    <Text
                      nowrap
                      styles={{
                        root: {
                          flexGrow: 1,
                          fontSize: 14
                        }
                      }}
                    >
                      (Last recorded{" "}
                      <strong>
                        {lastUpdatedDate.toFormat(
                          DATE_FORMATS.LONG_DATE_TIME_FORMAT
                        )}
                      </strong>{" "}
                      by{" "}
                    </Text>
                    <Text
                      nowrap
                      styles={{
                        root: {
                          flexGrow: 1,
                          fontSize: 14,
                          marginLeft: 4
                        }
                      }}
                    >
                      <strong>{userName}</strong>)
                    </Text>
                  </Stack>
                );
              } else {
                return (
                  <Text
                    styles={(_props, theme) =>
                      mergeStyleSets(
                        {
                          root: {
                            color: theme.palette.neutralSecondary,
                            fontStyle: "italic",
                            fontSize: 12
                          }
                        },
                        textStyles
                      )
                    }
                  >
                    {shortFormat
                      ? `${lastUpdatedDate ?? date} by ${userName}`
                      : `Last updated by ${userName} on ${
                          lastUpdatedDate ?? date
                        }`}
                  </Text>
                );
              }
            };
            return getText();
          }}
        </LastUpdatedDataFetcher>
      </Stack>
    );
  }
);
