import { DateTime } from "@bps/utils";
import { FileFieldValue } from "@ui-components/form/FilePickerField/FilePickerField.types.ts";

export function formatBytesToMB(bytes: number) {
  let mb = (bytes / (1024 * 1024)).toFixed(2);
  if (mb === "0.00") {
    mb = "< 0.01";
  }
  return `${mb} MB`;
}

export function getDefaultFileFields(files: File[]): FileFieldValue[] {
  const mappedFiles: FileFieldValue[] = files.map(file => {
    const splitName = file.name.split(".");
    const extension = splitName.length > 1 ? splitName.pop() : undefined;

    const name = extension
      ? file.name.slice(0, -(extension.length + 1))
      : file.name;
    return {
      extension,
      name,
      file,
      date: DateTime.jsDateNow(),
      subject: name
    };
  });

  return mappedFiles;
}
