import { action, computed, observable } from "mobx";

/**
 * This controller disables not active form Prompt(s) if there are more than one SubForm on a screen.
 * Should be used with multiply forms screen.
 */
export class MultiFormPromptsController {
  @observable
  private activePrompts: string[] = [];

  @computed
  get activePrompt() {
    return this.activePrompts.length > 0 ? this.activePrompts[0] : undefined;
  }

  @action
  public onMountPrompt = (key: string) => {
    this.activePrompts = [...this.activePrompts, key];
  };

  @action
  public onUnmountPrompt = (key: string) => {
    this.activePrompts = this.activePrompts.filter(k => k !== key);
  };
}
