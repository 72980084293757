/* eslint-disable no-restricted-imports */
import {
  DatePickerArrows as CoreDatePickerArrows,
  DatePickerArrowProps
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";

export const DatePickerArrows: React.FunctionComponent<
  DatePickerArrowProps
> = ({ calendarProps, ...props }) => (
  <CoreDatePickerArrows
    {...props}
    calendarProps={{
      today: DateTime.jsDateNow(),
      ...calendarProps
    }}
  />
);
