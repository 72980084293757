import { FunctionComponent } from "react";
import { Field, FieldRenderProps } from "react-final-form";

import { mergeFuncStyles, OptionsSelectProps, useTheme } from "@bps/fluent-ui";

import { ContactsSelectProps } from "../../selects/ContactsSelect.tsx";
import { PatientsSelect } from "../../selects/PatientsSelect.tsx";
import { ExposedFieldProps } from "../FinalForm.tsx";
import { FormItemField } from "../FormItemField.tsx";
import { formatUndefined } from "../utils.ts";
import { getFieldBorderColors } from "./utils.ts";

type HtmlElementType = HTMLElement;

export type PatientsSelectFieldProps = Omit<
  ContactsSelectProps,
  "label" | "selectedKeys" | "onChangeSelectedKeys"
> & {
  label?: string | JSX.Element;
} & ExposedFieldProps<OptionsSelectProps["selectedKeys"], HtmlElementType>;

const PatientsSelectFieldAdapter = (
  props: FieldRenderProps<OptionsSelectProps["selectedKeys"], HtmlElementType> &
    Omit<
      OptionsSelectProps,
      "label" | "selectedKeys" | "onChangeSelectedKeys"
    > & { label?: string }
) => {
  const theme = useTheme();

  const {
    input: { value, onChange, onBlur, onFocus, name },
    meta,
    styles,
    required,
    label,
    disabled,
    ...optionsSelectProps
  } = props;

  return (
    <FormItemField name={name} required={required}>
      <PatientsSelect
        onBlur={onBlur}
        onFocus={onFocus}
        {...optionsSelectProps}
        selectedKeys={value as any}
        onChangeSelectedKeys={onChange}
        label={label}
        disabled={disabled}
        styles={mergeFuncStyles(getFieldBorderColors(meta, theme), styles)}
      />
    </FormItemField>
  );
};

export const PatientsSelectField: FunctionComponent<
  PatientsSelectFieldProps
> = props => (
  <Field
    {...props}
    format={formatUndefined(!!props.multiSelect)}
    component={PatientsSelectFieldAdapter}
  />
);
