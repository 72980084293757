import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  ChoiceGroup,
  ChoiceGroupProps,
  FieldItemProps,
  IChoiceGroupOption
} from "@bps/fluent-ui";

import { ExposedFieldProps } from "./FinalForm.tsx";
import { FormItemField, FormItemFieldProps } from "./FormItemField.tsx";
import { ReadOnlyChoiceGroup } from "./read-only-fields/ReadOnlyChoiceGroup/ReadOnlyChoiceGroup.tsx";

type HtmlElementType = HTMLElement | HTMLInputElement;

export type ChoiceGroupAdapterProps = Omit<
  ChoiceGroupProps,
  "value" | "defaultValue" | "defaultChecked" | "defaultSelectedKey"
> &
  Pick<FormItemFieldProps, "required" | "label"> & {
    fieldItemStyles?: FieldItemProps["styles"];
    horizontal?: boolean;
  };

export type ChoiceGroupFieldProps = ExposedFieldProps<
  ChoiceGroupProps["selectedKey"],
  HtmlElementType
> &
  ChoiceGroupAdapterProps;

const ChoiceGroupAdapter = (
  props: FieldRenderProps<ChoiceGroupProps["selectedKey"], HtmlElementType> &
    ChoiceGroupAdapterProps
) => {
  const {
    input,
    meta,
    required,
    label,
    fieldItemStyles,
    horizontal,

    readOnly,
    ...choiceGroupProps
  } = props;

  const { meta: formMeta } = useField(input.name);

  if (readOnly || formMeta?.data?.readOnly) {
    return (
      <ReadOnlyChoiceGroup
        {...choiceGroupProps}
        label={label}
        selectedKey={input.value ?? undefined}
      />
    );
  }

  const onChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    input.onChange(option && option.key);
  };

  return (
    <FormItemField
      name={input.name}
      required={required}
      label={label}
      styles={fieldItemStyles}
    >
      <ChoiceGroup
        {...choiceGroupProps}
        horizontal={horizontal}
        onChange={onChange}
        selectedKey={input.value}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        name={input.name}
      />
    </FormItemField>
  );
};

/**
 * Use ChoiceGroupField with final-form.
 * It wraps the choice group in a FormItemField to support error message.
 * @param props
 */
export const ChoiceGroupField: React.FC<ChoiceGroupFieldProps> = props => (
  <Field {...props} component={ChoiceGroupAdapter} />
);
