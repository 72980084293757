// Function for focus-on-error decorator creation:
// Gets all focusable input, select, textArea elements with name
// and all other elements with data-name
// focus any tabbable element or scroll into view
import { isElementTabbable } from "@bps/fluent-ui";

export const getAllInputs = (formKey: string) => () => {
  if (typeof document === "undefined") {
    return [];
  }

  // get a specific form element to avoid fields focusing in nested forms if some
  // of the fields have the same names
  const form = document.querySelector(`form[name='${formKey}']`) ?? document;

  // ⚠️ Hack: Fluent UI elements that have an input but no name set on it
  // get the field name send down from it parent via the data-name-for-input attribute
  const inputElements = Array.from(
    form.querySelectorAll("[data-name-for-input] ")
  );
  inputElements.forEach(ele => {
    const inputName = ele.getAttribute("data-name-for-input");
    // search the input among the children of this element and set its name attribute
    // with the field name
    const inps = ele.querySelectorAll("input");
    if (inps && inps[0] && inputName) {
      inps[0].name = inputName;
    }
  });

  return Array.from(
    form.querySelectorAll("[data-name], input, select, textarea")
  )
    .filter((el: HTMLElement) => el.dataset?.name || el["name"])
    .map((el: HTMLElement) => ({
      name: el["name"] ? el["name"] : el.dataset.name!,
      focus: () => (isElementTabbable(el) ? el.focus() : el.scrollIntoView())
    }));
};
