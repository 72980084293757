import { FunctionComponent, useEffect, useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClinicalAutoFillToolBar } from "./editor-autofills/ClinicalAutoFillToolBar.tsx";
import { QuillEditorAutofill } from "./editor-autofills/quill-editor-autofill.ts";
import { modules, TextEditorProps } from "./TextEditor.types.ts";

/**
 * Wrapper for ReactQuill component.
 * For more options of Quill Api and ReactQuill configuration, see https://www.npmjs.com/package/react-quill
 */
export const TextEditor: FunctionComponent<TextEditorProps> = props => {
  const root = useStores();
  // want to pass the base context into the autofill so we can render templates with basic merge fields
  const {
    className,
    heading,
    withClinicalAutofill,
    autofillContext,
    displayAutoFillBar,
    ...restQuillProps
  } = props;

  const autofillInstance = useMemo(
    () => new QuillEditorAutofill(root, autofillContext),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const quillModules = !withClinicalAutofill
    ? modules
    : {
        ...modules,
        keyboard: {
          bindings: autofillInstance.getBindings()
        }
      };

  useEffect(() => {
    return () => autofillInstance.unSubscribeKeydownListener();
  }, [autofillInstance]);

  return (
    <div className={className}>
      {heading}
      <Stack>
        {withClinicalAutofill && displayAutoFillBar && (
          <ClinicalAutoFillToolBar
            onClick={autofillInstance.insertShortcutContent}
          />
        )}
        <ReactQuill
          ref={ref => {
            if (
              withClinicalAutofill &&
              !autofillInstance.documentEditor &&
              ref?.editor
            ) {
              autofillInstance.setDocumentEditor(ref.editor).replace();
            }
          }}
          modules={quillModules}
          {...restQuillProps}
          theme="snow"
        />
      </Stack>
    </div>
  );
};
