import React, { ComponentType } from "react";

import {
  ButtonsGroupOption,
  DatesRangePickerProps,
  FilterBarProps,
  getErrorMessage,
  useFieldContext
} from "@bps/fluent-ui";

import { DateTimeFramePicker } from "../../pickers/date-time-frame-picker/DateTimeFramePicker.tsx";
import { DateTimeFramePickerProps } from "../../pickers/date-time-frame-picker/DateTimeFramePicker.types.ts";
import { getDateRangeText } from "./utils.ts";

export interface SelectionProps {
  options: ButtonsGroupOption<string>[];
  name: string;
  label?: string;
}

export type DateTimeFramePickerFilterItemProps = Omit<
  DatesRangePickerProps,
  | "errorMessage"
  | "endDateProps"
  | "startDateProps"
  | "onBlur"
  | "onFocus"
  | "renderValue"
> & {
  validateOnInitialize?: boolean;
  incrementName: string;
  startDateName: string;
  endDateName: string;
  startDateProps?: Pick<
    DatesRangePickerProps["startDateProps"],
    "maxDate" | "minDate" | "label" | "id"
  >;
  endDateProps?: Pick<
    DatesRangePickerProps["startDateProps"],
    "maxDate" | "minDate" | "label" | "id"
  >;
  incrementProps: Pick<
    DateTimeFramePickerProps["incrementProps"],
    "label" | "id" | "incrementTitle" | "suffix" | "tooltipText" | "iconProps"
  >;
  readOnly?: boolean;
  selectionProps?: SelectionProps;
  withAnalytics?: (
    Component: ComponentType<DatesRangePickerProps>
  ) => ComponentType<DatesRangePickerProps>;
} & Pick<FilterBarProps, "shrinkVersion">;

export const DateTimeFramePickerFilterItem: React.FC<
  DateTimeFramePickerFilterItemProps
> = props => {
  const {
    label,
    placeholder,
    disabled,
    endDateName,
    startDateName,
    incrementName,
    startDateProps,
    endDateProps,
    styles,
    validateOnInitialize,
    className,
    id,
    shrinkVersion,
    incrementProps
  } = props;

  const {
    meta: metaStartDate,
    value: startDateValue,
    setFieldValue: onChangeStartDate,
    focusField: onFocusStartDate,
    blurField: onBlurStartDate
  } = useFieldContext<Date | undefined>(startDateName);

  const {
    meta: metaEndDate,
    value: endDateValue,
    setFieldValue: onChangeEndDate,
    focusField: onFocusEndDate,
    blurField: onBlurEndDate
  } = useFieldContext<Date | undefined>(endDateName);

  const { value: incrementValue, setFieldValue: onIncrementChanged } =
    useFieldContext<string | undefined>(incrementName);

  const value = getDateRangeText(startDateValue, endDateValue);

  return (
    <DateTimeFramePicker
      id={id}
      label={label}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      styles={styles}
      startDateProps={{
        ...startDateProps,
        value: startDateValue,
        onChange: onChangeStartDate,
        name: startDateName,
        label: startDateProps?.label,
        errorMessage: metaStartDate
          ? getErrorMessage(metaStartDate, validateOnInitialize)
          : undefined
      }}
      endDateProps={{
        ...endDateProps,
        value: endDateValue,
        onChange: onChangeEndDate,
        name: endDateName,
        label: endDateProps?.label,
        errorMessage: metaEndDate
          ? getErrorMessage(metaEndDate, validateOnInitialize)
          : undefined
      }}
      onFocus={() => {
        onFocusStartDate();
        onFocusEndDate();
      }}
      onBlur={() => {
        onBlurStartDate();
        onBlurEndDate();
      }}
      overrideBold={!!value && !shrinkVersion}
      incrementProps={{
        ...incrementProps,
        value: incrementValue,
        onIncrementChange: val => {
          onIncrementChanged(val);
        }
      }}
    />
  );
};
